/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect } from 'react';

import {
  Wrapper
} from 'components';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { PATHS, PAYMENT_TYPE, ROUTES } from 'utils/constants';
import { SubscriptionService } from 'services';

const WAITING_TIME = 3000;

const PageConfirmPaybox = observer(props => {
  const { location } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const displayError = useCallback((err, msg) => {
    enqueueSnackbar(msg, { variant: 'error' });
    if (err) console.log(err);
  }, [enqueueSnackbar]);

  const handleAllowanceApplicationSubmit = useCallback((isError, allowanceId, category) => {
    if (isError) {
      history.push(ROUTES.ALLOWANCE_LEISURE_CATEGORY(category));
    } else {
      enqueueSnackbar(translate('confirms.bookingDone'), { variant: 'success' });
      history.push(ROUTES.ALLOWANCE_LEISURE_DETAIL_CONFIRM(category, allowanceId));
    }
  }, [enqueueSnackbar, history]);

  const handleReSubscriptionSubmit = useCallback(isError => {
    if (!isError) {
      enqueueSnackbar(translate('confirms.resubscriptionDone'), { variant: 'success' });
    }
    history.push({
      pathname: PATHS.USER_PROFILE,
      state: { tab: 1 }
    });
  }, [enqueueSnackbar, history]);

  const handleMembershipSubmit = useCallback(isError => {
    if (!isError) {
      enqueueSnackbar(translate('confirms.reMembershipDone'), { variant: 'success' });
    }
    history.push({
      pathname: PATHS.USER_PROFILE,
      state: { tab: 1 }
    });
  }, [enqueueSnackbar, history]);

  const handleSubscriptionSubmit = useCallback((isError, transactionId) => {
    if (!isError) {
      const subscriberInfosFromStorage = { ...JSON.parse(localStorage.getItem('subscriber')) };
      const subscriberInfos = {
        tranId: transactionId,
        nom: subscriberInfosFromStorage.lastName,
        prenom: subscriberInfosFromStorage.firstName,
        idCivilite: parseInt(subscriberInfosFromStorage.civility),
        contact: {
          courrielPersonnel: subscriberInfosFromStorage.email,
          telephonePortable1: subscriberInfosFromStorage.phone
        },
        dateNaissance: null,
        adresse: {
          ligne1: subscriberInfosFromStorage.address1,
          ligne2: subscriberInfosFromStorage.address2,
          ligne3: subscriberInfosFromStorage.address3,
          codePostal: subscriberInfosFromStorage.postalCode,
          ville: subscriberInfosFromStorage.city,
          pays: subscriberInfosFromStorage.country.label,
          npai: true
        },
        abonnementsModel: [
          ...subscriberInfosFromStorage.subscriptions.map(subscription => (
            {
              idRevue: subscription.idRevue,
              nombreDeCopies: 1,
              nomBeneficiaire: subscription.lastName ? subscription.lastName : subscriberInfosFromStorage.lastName,
              prenomBeneficiaire: subscription.firstName ? subscription.firstName : subscriberInfosFromStorage.firstName,
              idCivilite: parseInt(subscription.civility),
              adresse: {
                ligne1: subscription.address1 || '',
                ligne2: subscription.address2 || '',
                ligne3: subscription.address3 || '',
                codePostal: subscription.postalCode || '',
                ville: subscription.city || '',
                pays: subscription.country.label || '',
                npai: subscription.npai
              }
            }
          ))
        ]
      };

      SubscriptionService.createSubscriber(subscriberInfos).then(() => {
        enqueueSnackbar(translate('confirms.subscriptionDone'), { variant: 'success' });
        const blockedTransactionIds = JSON.parse(localStorage.getItem('blockedTransactionIds'));
        if (!blockedTransactionIds.includes(transactionId)) {
          blockedTransactionIds.push(transactionId);
          localStorage.setItem('blockedTransactionIds', JSON.stringify(blockedTransactionIds));
        }

        history.push({
          pathname: PATHS.CONFIRM_SUBSCRIPTION
        });
      }).catch(err => {
        displayError(err, err);
        history.push({
          pathname: PATHS.SUBSCRIPTION
        });
      });
    }
  }, [enqueueSnackbar, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const urlParams = new URLSearchParams(location.search);
    const typeId = urlParams.get('typeId');
    const category = urlParams.get('category');
    const transactionId = urlParams.get('idtrans');
    const allowanceId = urlParams.get('allowanceId');
    const error = urlParams.get('Erreur');
    let isError = true;

    if (!localStorage.getItem('blockedTransactionIds')) {
      localStorage.setItem('blockedTransactionIds', JSON.stringify([]));
    }
    const blockedTransactionIds = JSON.parse(localStorage.getItem('blockedTransactionIds'));

    if (error === '00000') {
      isError = false;
    } else if (error === null) {
      setTimeout(() => displayError(null, translate('errors.errorPaybox')), WAITING_TIME);
    } else if (error.match(/^001/g)) {
      setTimeout(() => displayError(null, translate('errors.paymentRefused')), WAITING_TIME);
    } else if (error === '00003') {
      setTimeout(() => displayError(null, translate('errors.errorPaybox')), WAITING_TIME);
    } else if (error === '00004') {
      setTimeout(() => displayError(null, translate('errors.errorCryptogram')), WAITING_TIME);
    } else if (error === '00021') {
      setTimeout(() => displayError(null, translate('errors.errorCardNotValid')), WAITING_TIME);
    } else if (error === '99999') {
      setTimeout(() => displayError(null, translate('errors.waitingValidation')), WAITING_TIME);
    } else if (error !== '00000') {
      displayError(null, translate('errors.paymentFail'));
    }

    // eslint-disable-next-line radix
    switch (parseInt(typeId)) {
    case PAYMENT_TYPE.RESUBSCRIPTION.code:
      setTimeout(() => handleReSubscriptionSubmit(isError), WAITING_TIME);
      break;
    case PAYMENT_TYPE.READHESION.code:
      setTimeout(() => handleMembershipSubmit(isError), WAITING_TIME);
      break;
    case PAYMENT_TYPE.SUBSCRIPTION.code:
      if (!blockedTransactionIds.includes(transactionId)) {
        setTimeout(() => handleSubscriptionSubmit(isError, transactionId), WAITING_TIME);
        break;
      }
      history.push({
        pathname: PATHS.CONFIRM_SUBSCRIPTION
      });
      break;
    case PAYMENT_TYPE.TICKETS.code:
    case PAYMENT_TYPE.INTERN.code:
    default:
      setTimeout(() => handleAllowanceApplicationSubmit(allowanceId, category), WAITING_TIME);
      break;
    }
  }, [handleAllowanceApplicationSubmit,
    history, location.search, displayError, handleSubscriptionSubmit, handleMembershipSubmit]);

  return (
    <Wrapper>
      <br />
      <Grid alignItems="center" container direction="column" spacing={4}>
        <Grid item xs>
          <img alt="ConfirmationPaybox" src="/assets/images/confirmation-social.png" />
        </Grid>
        <Grid item xs>
          <Typography align="center" className="bold" component="h2" variant="h5">
            {translate('forms.validateTransaction')}
          </Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
});

export default PageConfirmPaybox;