/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(() => ({
  sectionNumber: {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--white)',
    padding: '10px 15px',
    borderRadius: '50%'
  },
  label: props => ({
    position: 'relative',
    backgroundColor: 'var(--secondary-color-lighter)',
    padding: props.displayButton ? '0 0 0 45px' : '8px 45px',
    fontWeight: 'bold',
    color: 'var(--black)',
    borderRadius: '10px',
    left: '-20px',
    zIndex: '-1'
  })
}));

const SectionHeader = ({ sectionNumber, label, onClick = null }) => {
  const classes = useStyles({ displayButton: onClick !== null });

  return (
    <div style={{ cursor: onClick !== null ? 'pointer' : 'auto' }} onClick={onClick}>
      <Grid alignItems="center" container>
        <Grid className={classes.sectionNumber} item>{sectionNumber}</Grid>
        <Grid className={classes.label} item xs={11}>
          <Grid alignItems="center" container justify="space-between">
            <Grid item>
              {label}
            </Grid>
            {onClick !== null && (
              <Grid item>
                <Button color="secondary" onClick={onClick}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <FontAwesomeIcon icon={faPen} />
                    </Grid>
                    <Grid item>
                      {translate('subscription.edit')}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SectionHeader;