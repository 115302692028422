import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel
} from '@material-ui/core';
import { translate } from 'utils';
import shortid from 'shortid';

const TableSortLabelComponent = ({
  orderBy, order, header, handleSortAction
}) => {
  const handleClick = useCallback(() => handleSortAction(header), [header, handleSortAction]);

  return (
    <TableSortLabel
      active={orderBy === header.name}
      direction={order}
      onClick={handleClick}
    >
      {header.label}
    </TableSortLabel>
  );
};

const TableRowComponent = ({
  hover, row, onRowCLick, headers, cursor
}) => {
  const handleClick = useCallback(() => onRowCLick(row), [onRowCLick, row]);

  return (
    <TableRow
      hover={hover}
      style={{ cursor }}
      onClick={handleClick}
    >
      {headers.map(column => (
        column.template(row)
      ))}
    </TableRow>
  );
};

const GenericTable = ({
  rows, dataCy, dataTour, headers,
  hover, onRowCLick, stickyHeader, size
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headers.orderBy || headers.name);
  const [sortOnProperty, setSortOnProperty] = useState(headers.sortOn || headers.name);

  const handleSort = useCallback(({ name, sortOn }) => {
    const isDesc = orderBy === name && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(name);
    setSortOnProperty(sortOn);
  }, [order, orderBy]);

  const desc = (a, b) => {
    if (b[orderBy] && b[orderBy][sortOnProperty]) {
      if (b[orderBy][sortOnProperty] < a[orderBy][sortOnProperty]) return -1;
      if (b[orderBy][sortOnProperty] > a[orderBy][sortOnProperty]) return 1;
    } else {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
    }

    return 0;
  };

  const getSorting = () => (order === 'desc' ? (a, b) => desc(a, b) : (a, b) => -desc(a, b));

  const handleSortAction = useCallback(header => handleSort({ ...header }), [handleSort]);

  const handleRowClick = useCallback(row => onRowCLick && onRowCLick(row), [onRowCLick]);

  return (
    <div data-cy={dataCy} data-tour={dataTour} style={{ overflowX: 'auto', width: '100%' }}>
      <Table
        size={size}
        stickyHeader={stickyHeader}
      >
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header.name} style={{ width: header.width || 'initial' }}>
                {header.isSortable ? (
                  <TableSortLabelComponent
                    handleSortAction={handleSortAction}
                    header={header}
                    order={order}
                    orderBy={orderBy}
                  />
                ) : translate(header.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.slice()
            .sort(getSorting())
            .map(row => (
              <TableRowComponent
                cursor={onRowCLick ? 'pointer' : 'initial'}
                headers={headers}
                hover={hover}
                key={row.hashId || shortid.generate()}
                row={row}
                onRowCLick={handleRowClick}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

GenericTable.propTypes = {
  dataCy: PropTypes.string,
  dataTour: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    isSortable: PropTypes.bool,
    sortOn: PropTypes.string,
    template: PropTypes.func
  })).isRequired,
  hover: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  size: PropTypes.oneOf([
    'medium',
    'small'
  ]),
  onRowCLick: PropTypes.func,
  stickyHeader: PropTypes.bool
};

GenericTable.defaultProps = {
  dataCy: '',
  dataTour: '',
  hover: true,
  onRowCLick: null,
  size: 'medium',
  stickyHeader: true
};

export default GenericTable;