import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Divider, Grid, TextField, Typography
} from '@material-ui/core';
import {
  InfoLine, PageTitle, PageTitleContainer, Wrapper
} from 'components';
import { useFormState } from 'react-use-form-state';
import { translate } from 'utils';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

const PageContact = () => {
  const [civility, setCivility] = useState('mr');
  const { enqueueSnackbar } = useSnackbar();

  const [formState, {
    text, email, tel, textarea
  }] = useFormState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChangeCivility = useCallback((_, status) => {
    if (!status) return;
    setCivility(status);
  }, [setCivility]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    enqueueSnackbar(translate('confirms.contactSent'), { variant: 'success' });
  }, [formState.values, enqueueSnackbar]);

  return (
    <Wrapper>
      <PageTitleContainer>
        <PageTitle title="userMenu.contactAsso" withMargin={false} />
      </PageTitleContainer>

      <Typography>Vous souhaitez contacter le siège de l&apos;ADOSSPP :</Typography>

      <p className="bold">
        Cellule Cotisation / Abonnement du magazine Allo Dix-Huit :
      </p>
      <div>
        Abonnnement / changement d&apos;adresse :
        <InfoLine
          info={(
            <a href="tel:0147178718" rel="noreferrer noopener" target="_blank">01.47.17.87.18</a>
          )}
          label="common.phone"
        />
        <InfoLine
          info={(
            <a href="mailto:allo18@adosspp.com" rel="noreferrer noopener" target="_blank">allo18@adosspp.com</a>
          )}
          label="common.email"
        />
      </div>

      <p className="bold">
        Cellule Publicitaire Allo 18 : Europress
      </p>
      <div>
        <InfoLine
          info={(
            <a href="tel:33(1)47489595" rel="noreferrer noopener" target="_blank">+33 (1) 47-48-95-95</a>
          )}
          label="common.phone"
        />
        <InfoLine
          info={(
            <a href="http://www.europress.fr" rel="noreferrer noopener" target="_blank">www.europress.fr</a>
          )}
          label="common.website"
        />
        <InfoLine
          info={(
            <a href="mailto:direction@europress.fr" rel="noreferrer noopener" target="_blank">direction@europress.fr</a>
          )}
          label="common.email"
        />
      </div>

      <Divider style={{ margin: '2rem auto' }} />

      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="object"
              label={translate('common.object')}
              name="object"
              required
              {...text('object')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <ToggleButtonGroup
              exclusive
              value={civility}
              onChange={handleChangeCivility}
            >
              <ToggleButton value="mr">
                {translate('common.mr')}
              </ToggleButton>
              <ToggleButton value="mrs">
                {translate('common.mrs')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="lastName"
              label={translate('common.lastName')}
              name="lastName"
              required
              {...text('lastName')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="firstName"
              label={translate('common.firstName')}
              name="firstName"
              required
              {...text('firstName')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="email"
              label={translate('common.email')}
              name="email"
              required
              {...email('email')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="phone"
              label={translate('common.phone')}
              name="phone"
              required
              {...tel('phone')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id="request"
              label={translate('common.request')}
              multiline
              name="request"
              required
              rows={3}
              variant="outlined"
              {...textarea('request')}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button aria-label={translate('button.submit')} color="primary" type="submit">{translate('button.submit')}</Button>
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
};

export default PageContact;