import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { translate } from 'utils';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button, Drawer, FormControl, Grid, TextField
} from '@material-ui/core';
import { useFormState } from 'react-use-form-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { PATHS } from 'utils/constants';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { PageTitle, Password, SkeletonMain } from 'components';
import { useSnackbar } from 'notistack';

const CustomGridContainer = styled(Grid)`
  height: 100vh;

  > div {
    height: 100%;

    &:first-child {
      padding-bottom: 4rem;
      border-bottom: 1rem solid var(--secondary-color);
    }
  }

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const TitleContainer = styled.div`
  position: relative;
  padding: 1rem 2rem;
  background: var(--secondary-color);

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: -124px;
    border-left: 62px solid var(--secondary-color);
    border-right: 62px solid transparent;
    border-bottom: 62px solid transparent;
  }

  h1 {
    color: var(--white);
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    &:after {
      display: none;
    }
  }
`;

const LeftContainer = styled(Grid)`
  min-width: 400px;

  @media (max-width: 768px) {
    min-width: initial;
    width: 100%;
  }
`;

const RightContainer = styled(Grid)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const FormContainer = styled.form`
  width: 75%;
  text-align: center;

  img {
    max-width: 300px;
    margin: 5rem auto;
  }

  .passwordForgotten {
    font-family: 'ArimoBold';
    color: var(--primary-color);
    text-transform: lowercase;
  }

  @media (max-width: 768px) {
    img {
      margin: 1rem auto;
    }
  }
`;

const ConnectImg = styled.div`
  width: 100%;
  height: 100%;
  background: url('/assets/images/bg/connect.jpg') no-repeat center;
  background-size: cover;
`;

const PageConnect = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const { state: { from: { pathname } = {} } = {}, search } = location;
  const { userStore, allowanceStore } = useStores();
  const { isLoading } = userStore;
  const [isReconnecting, setIsReconnecting] = useState(true);
  const [togglePasswordForgotten, setTogglePasswordForgotten] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formState, { text }] = useFormState();

  const redirectUser = useCallback(() => {
    setIsReconnecting(false);
    history.push({
      pathname: pathname || PATHS.HOME,
      search
    });
    allowanceStore.getAllData().catch(() => '');
  }, [history, pathname, allowanceStore, search]);

  useEffect(() => {
    userStore.getProfile()
      .then(() => redirectUser())
      .catch(() => {
        userStore.refreshToken()
          .then(() => {
            userStore.getProfile()
              .then(() => redirectUser())
              .catch(err => {
                setIsReconnecting(false);
                enqueueSnackbar(translate(err.message), { variant: 'error' });
              });
          })
          .catch(() => {
            setIsReconnecting(false);
            enqueueSnackbar(translate('errors.tokenExpired'), { variant: 'warning' });
          });
      });
  }, [userStore, history, redirectUser, enqueueSnackbar]);

  const handlePasswordForgotten = useCallback(() => (
    setTogglePasswordForgotten(!togglePasswordForgotten)
  ), [togglePasswordForgotten]);

  const handleConnect = useCallback(e => {
    e.preventDefault();

    userStore.connectUser(formState.values)
      .then(() => {
        allowanceStore.getAllData().then(() => history.push(PATHS.HOME)).catch(() => '');
      })
      .catch(({ message, variant }) => enqueueSnackbar(translate(message), { variant }));
  }, [formState, userStore, history, enqueueSnackbar, allowanceStore]);

  const handleSubmitPassword = useCallback(e => {
    e.preventDefault();
    userStore.forgotPassword(formState.values.username)
      .then(() => {
        setTogglePasswordForgotten(false);
        enqueueSnackbar(translate('confirms.successForgotPassword'), { variant: 'success' });
      })
      .catch(err => {
        if (err) enqueueSnackbar(err.detail, { variant: 'error' });
      });
  }, [enqueueSnackbar, formState, userStore]);

  if (isReconnecting) {
    return <SkeletonMain />;
  }

  return (
    <CustomGridContainer container>
      <LeftContainer item sm={3} xs={12}>
        <TitleContainer>
          <PageTitle title="pageConnect.title" withMargin={false} />
        </TitleContainer>

        <Grid alignItems="center" container direction="column">
          <FormContainer onSubmit={handleConnect}>
            <FormControl>
              <Grid container direction="column" spacing={4}>
                <Grid item xs>
                  <Grid alignItems="center" container>
                    <img alt={translate('pageConnect.title')} src="/assets/images/logo-2021-large.jpg" />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="username"
                    error={formState.errors.username && formState.errors.username !== null}
                    fullWidth
                    id="username"
                    label={translate('forms.username')}
                    name="username"
                    required
                    {...text('username')}
                  />
                </Grid>

                <Grid item xs>
                  <Password
                    error={formState.errors.password && formState.errors.password !== null}
                    formState={formState}
                    id="password"
                    label={translate('forms.password')}
                    name="current-password"
                    {...text('password')}
                  />
                </Grid>

                <Grid item xs>
                  <Button
                    aria-label={translate('button.connect')}
                    color="secondary"
                    fullWidth
                    type="submit"
                  >
                    {isLoading ? (
                      <FontAwesomeIcon icon={faSpinner} size="lg" spin />
                    ) : translate('button.connect')}
                  </Button>
                </Grid>

                <Grid container item justify="flex-end" xs>
                  <Button
                    aria-label={translate('button.passwordForgotten')}
                    className="passwordForgotten"
                    variant="text"
                    onClick={handlePasswordForgotten}
                  >
                    {translate('button.passwordForgotten')}
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </FormContainer>
        </Grid>

        <Drawer open={togglePasswordForgotten} onClose={handlePasswordForgotten}>
          <Grid
            alignItems="center"
            container
            direction="column"
            justify="center"
            style={{ height: '100%' }}
          >
            <FormContainer onSubmit={handleSubmitPassword}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <TextField
                    autoComplete="username"
                    error={formState.errors.username && formState.errors.username !== null}
                    fullWidth
                    id="username"
                    label={translate('forms.username')}
                    name="username"
                    required
                    {...text('username')}
                  />
                </Grid>
                <Grid item>
                  <Button
                    aria-label={translate('button.forgotPassword')}
                    color="secondary"
                    fullWidth
                    type="submit"
                  >
                    {translate('button.forgotPassword')}
                  </Button>
                </Grid>
              </Grid>
            </FormContainer>
          </Grid>
        </Drawer>
      </LeftContainer>

      <RightContainer item sm>
        <ConnectImg />
      </RightContainer>
    </CustomGridContainer>
  );
});

export default PageConnect;