import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Marker, StaticGoogleMap } from 'react-static-google-map'; // Docs : https://www.npmjs.com/package/react-static-google-map
import { Grid, Typography } from '@material-ui/core';
import {
  faEnvelope, faFax, faLink, faMapMarkerAlt, faPhone
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils';

const MapContactContainer = styled.article`
  margin: 2rem 0;
  background-color: #f9fcff;

  img {
    max-width: 100%;
  }
`;

const ContactInfos = styled.div`
  padding: 2rem;

  h2{
    margin-bottom: 2rem;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ContactLine = ({ children, icon }) => (
  <Typography>
    <FontAwesomeIcon className="mr1" icon={icon} />
    {children}
  </Typography>
);

const getParsedAddress = address => address.replaceAll(';;;', ' ')?.replaceAll(';', ' ');

const MapContact = ({
  address, tel, fax, email, website
}) => {
  const ZOOM = '14';
  const SIZE = '800x400';

  const isCoordinateEmpy = () => address === ';;;;' && fax === '' && email === '' && website === '';

  return (
    <MapContactContainer>
      <Grid container>
        {address !== ';;;;' && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${getParsedAddress(address)}&zoom=${ZOOM}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <StaticGoogleMap apiKey="AIzaSyBjwaMpE1i4Ne5bKY1A8ek541Vvxkm0RTY" className="img-fluid" size={SIZE} zoom={ZOOM}>
              <Marker color="green" label="Client" location={address} />
            </StaticGoogleMap>
          </a>
        )}

        <Grid item xs>
          <ContactInfos>
            {isCoordinateEmpy()
            && (
              <Typography className="bold" component="h2" variant="h5">
                {translate('common.contact')}
              </Typography>
            )}
            {address !== ';;;;' && (
              <ContactLine icon={faMapMarkerAlt}>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${getParsedAddress(address)}&zoom=${ZOOM}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {getParsedAddress(address)}
                </a>
              </ContactLine>
            )}
            {tel && (
              <ContactLine icon={faPhone}>
                <a href={`tel:${tel}`} rel="noreferrer noopener" target="_blank">
                  {tel}
                </a>
              </ContactLine>
            )}
            {fax && (
              <ContactLine icon={faFax}>
                {fax}
              </ContactLine>
            )}
            {email && (
              <ContactLine icon={faEnvelope}>
                <a href={`mailto:${email}`} rel="noreferrer noopener" target="_blank">
                  {email}
                </a>
              </ContactLine>
            )}
            {(website && website !== 'https://') && (
              <ContactLine icon={faLink}>
                <a href={website} rel="noreferrer noopener" target="_blank">
                  {website}
                </a>
              </ContactLine>
            )}
          </ContactInfos>
        </Grid>
      </Grid>
    </MapContactContainer>
  );
};

MapContact.propTypes = {
  address: PropTypes.string.isRequired,
  tel: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string
};

MapContact.defaultProps = {
  email: '',
  fax: '',
  tel: '',
  website: ''
};

export default MapContact;