import styled from 'styled-components';

export const Wrapper = styled.section`
  width: var(--wrapperWidthMax);
  min-width: var(--wrapperWidthMin);
  margin: 0 auto;
  padding: 0 2rem;
  flex: 1;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    padding: 0 1rem;
  }
`;

export const SmallWrapper = styled(Wrapper)`
  width: var(--smallWrapperWidthMax);

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const LargeWrapper = styled(Wrapper)`
  width: var(--largeWrapperWidthMax);

  @media (max-width: 1400px) {
    width: 100%;
  }
`;

export const FullWrapper = styled(Wrapper)`
  width: 100%;
  max-width: none;
`;