/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import { translate } from 'utils';
import { SubscriptionHelper } from 'utils/helpers';
import { SubscriptionService } from 'services';
import Conditions from './Conditions';

const SubscriptionSummary = ({
  formState, checkbox, setTotalPrice, prices
}) => {
  const [numberRevues, setNumberRevues] = useState([]);
  const [allo18AB, setAllo18AB] = useState(false);
  const [allo18ET, setAllo18ET] = useState(false);
  const [isLoadingNumberRevue, setIsLoadingNumberRevue] = useState(true);

  useEffect(() => {
    SubscriptionService.getNumberRevue().then(resp => {
      setNumberRevues(resp.data.filter(
        d => d.codeRevue !== 'ALLO18ADH'
      ).map(revue => ({ nomFichier: revue.nomFichier, nextNumeroRevu: parseInt(revue.numeroRevu) + 1 })));
      setIsLoadingNumberRevue(false);
    });
  }, []);

  const getDataToDisplay = useCallback(subscription => {
    let referencial = subscription.values ? { ...subscription.values } : { ...subscription };
    if (referencial.npai === 'true') referencial = { ...formState.values };
    return {
      beneficiary: `${referencial.firstName} ${referencial.lastName}`,
      // eslint-disable-next-line max-len
      deliveryAddress: `${referencial.address1} ${referencial.address2 || ''} ${referencial.address3 || ''}, ${referencial.postalCode} ${referencial.city}, ${referencial.country.label}`,
      price: `${SubscriptionHelper.getSubscriptionRevue(prices, referencial.country.id === 250).prixAdherent}€`
    };
  }, [formState.values]);

  const calculateTotalPrice = useCallback(() => {
    let price = 0;
    formState.values.subscriptions.forEach(subscription => {
      let referencial = subscription.values ? { ...subscription.values } : { ...subscription };
      if (referencial.npai === 'true') referencial = { ...formState.values };
      price += SubscriptionHelper.getSubscriptionRevue(prices, referencial.country.id === 250).prixAdherent;
    });
    setTotalPrice(price);
    return price;
  }, [formState.values]);

  useEffect(() => {
    formState.values.subscriptions.forEach(subscription => {
      let referencial = subscription.values ? { ...subscription.values } : { ...subscription };
      if (referencial.npai === 'true') referencial = { ...formState.values };
      if (referencial.country.id === 250) {
        setAllo18AB(true);
      } else {
        setAllo18ET(true);
      }
    });
  }, [formState.values.subscriptions]);

  return (
    !isLoadingNumberRevue && (
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textTransform: 'uppercase' }}>{translate('subscription.benefeciary')}</TableCell>
                  <TableCell style={{ textTransform: 'uppercase' }}>{translate('subscription.deliveryAddress')}</TableCell>
                  <TableCell style={{ textTransform: 'uppercase' }}>{translate('subscription.price')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formState.values.subscriptions.map(subscription => {
                  const dataToDisplay = getDataToDisplay(subscription);
                  return (
                    <TableRow
                      key={subscription.values ? subscription.values.id : subscription.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{dataToDisplay.beneficiary}</TableCell>
                      <TableCell>{dataToDisplay.deliveryAddress}</TableCell>
                      <TableCell>{dataToDisplay.price}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item>
          <Grid alignItems="flex-end" container direction="column" spacing={1}>
            <Grid item style={{ fontWeight: 'bold', fontSize: '22px', color: 'var(--black)' }}>
              {`Total : ${calculateTotalPrice()} €`}
            </Grid>
            {numberRevues.length > 0 && (
              <Grid item>
                {`${translate('subscription.subscriptionStart')}${
                  allo18AB
                    ? `${translate('subscription.ALLO18_ABStart')}${numberRevues.find(
                      revue => revue.nomFichier === 'ALLO18_AB'
                    )?.nextNumeroRevu}.`
                    : ''
                }${
                  allo18ET
                    ? `${translate('subscription.ALLO18_ETStart')}${numberRevues.find(
                      revue => revue.nomFichier === 'ALLO18_ET'
                    )?.nextNumeroRevu}.`
                    : ''
                }`}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs>
          <Conditions checkbox={checkbox} formState={formState} />
        </Grid>
      </Grid>
    )
  );
};

export default SubscriptionSummary;