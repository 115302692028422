import i18n from 'i18next';
import {
  DocumentHelper, FormHelper, NavigatorHelper,
  RequestHelper, UserHelper, BookingHelper, CategoryHelper
} from './helpers';
import { ALLOWANCE_APPLICATION } from './constants';

export const pluralize = (string, length, defaultValue) => (
  length > 1 ? `${string}s` : (defaultValue || string)
);

export const isMobile = () => window.innerWidth <= 768;

export const isMisterflyTicket = ({ allowanceName }) => allowanceName && allowanceName.trim() === ALLOWANCE_APPLICATION.allowanceName;

export const translate = (key, options) => {
  if (i18n.exists(key)) {
    return i18n.t(key, options);
  }

  if (key && key.indexOf('.') > -1) {
    return key.split('.')[1];
  }

  return key;
};

export const RandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  return crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
};

export {
  DocumentHelper,
  FormHelper,
  NavigatorHelper,
  RequestHelper,
  UserHelper,
  BookingHelper,
  CategoryHelper
};