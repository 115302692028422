import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonAllowanceDetail = ({ isLeisure }) => (
  isLeisure ? (
    <Grid container direction="column">
      <Grid item xs>
        <Skeleton height={30} width="400px" />
      </Grid>

      <Grid item xs>
        <Grid alignItems="flex-start" container spacing={1}>
          <Grid item sm={3} style={{ marginTop: '1rem' }} xs={12}>
            <Skeleton height={60} />
            <Skeleton height={30} />
            <Skeleton height={30} />
          </Grid>
          <Grid item sm={9} xs={12}>
            <Skeleton height={120} />
            <Skeleton height={80} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ marginTop: '-8rem' }} xs>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Skeleton height={400} variant="rect" />
          </Grid>
          <Grid container direction="column" item sm={6} spacing={2} xs={12}>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Skeleton height={190} variant="rect" />
              </Grid>
              <Grid item xs={6}>
                <Skeleton height={190} variant="rect" />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <Skeleton height={190} variant="rect" />
              </Grid>
              <Grid item xs={6}>
                <Skeleton height={190} variant="rect" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <>
      <Skeleton height={30} width="300px" />

      <Skeleton height={60} style={{ margin: '3rem auto' }} width="400px" />

      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Skeleton height={250} variant="rect" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Skeleton height={60} />

          <Skeleton height={60} />
        </Grid>
      </Grid>

      <Skeleton height={40} width="200px" />
    </>
  )
);

SkeletonAllowanceDetail.propTypes = {
  isLeisure: PropTypes.bool
};

SkeletonAllowanceDetail.defaultProps = {
  isLeisure: false
};

export default SkeletonAllowanceDetail;