import React, {
  useCallback, useEffect, Suspense, useState
} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Wrapper, GenericTable, PageTitleContainer, SkeletonTable, PageTitle, SkeletonNoTableResults
} from 'components';
import { observer } from 'mobx-react-lite';
import { isMisterflyTicket, translate } from 'utils';
import { useModal, usePrivileges, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import {
  Grid, IconButton, TableCell, Tooltip, Typography, Tab, Tabs
} from '@material-ui/core';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATHS, STATUS_DISPLAY } from 'utils/constants';
import {
  faChevronDown,
  faChevronUp,
  faEdit, 
  faEye, 
  faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { AllowanceHelper } from 'utils/helpers';
import { AllowanceApplicationService } from 'services';
import styled from 'styled-components';

const ExpandButton = styled(Grid)`
  margin-top: 25px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: var(--grey-lighter);
  }
`;

const tableListHeaders = (handleDeleteAllowanceApplication, allowanceCategoryMapper) => ([
  {
    name: 'allowanceName',
    label: 'common.allowance',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.allowanceName || '-'}
        {row.category && (
          <>
            <br />
            <small>{row.category.name}</small>
          </>
        )}
      </TableCell>
    )
  }, {
    name: 'creationDate',
    label: 'common.creationDate',
    template: row => (
      <TableCell key={shortid.generate()}>
        {new Date(row.creationDate).toLocaleDateString() || '-'}
      </TableCell>
    )
  }, {
    name: 'lastChangeStatusDate',
    label: 'common.lastChangeStatusDate',
    template: row => (
      <TableCell key={shortid.generate()}>
        {!isMisterflyTicket({ allowanceName: row.allowanceName })
          ? (
            new Date(row.lastChangeStatusDate).toLocaleDateString() || '-'
          ) : (
            null
          )}
      </TableCell>
    )
  }, {
    name: 'status',
    label: 'common.status',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Typography
          component="span"
          style={{ color: (STATUS_DISPLAY[row.status] && STATUS_DISPLAY[row.status].color) || 'var(--grey-darker)' }}
        >
          {!isMisterflyTicket({ allowanceName: row.allowanceName })
            ? (
              <Grid container wrap="nowrap">
                <Grid item>
                  <FontAwesomeIcon className="mr1" icon={STATUS_DISPLAY[row.status].icon} />
                </Grid>
                <Grid item>
                  {translate(STATUS_DISPLAY[row.status].label)}
                </Grid>
              </Grid>
            ) : (
              null
            )}
        </Typography>
      </TableCell>
    )
  },
  {
    name: 'actions',
    label: 'common.actions',
    width: '10px',
    template: row => {
      const url = row.type.id === 1
        ? AllowanceHelper.getAllowanceDetailUrl(row.type, row.category, allowanceCategoryMapper, row.allowanceId)
        : AllowanceHelper.getAllowanceBookingUrl(row.type, row.category, allowanceCategoryMapper, row.allowanceId);
      const status = AllowanceHelper.getAllowanceStatus(row.status);

      return (
        <TableCell key={shortid.generate()}>
          {status === 'ongoing' && (
            <Grid container wrap="nowrap">
              <Grid item>
                <Link to={{
                  pathname: url || '',
                  state: { allowanceApplicationId: row.id }
                }}
                >
                  {row.type.id === 2 ? (
                    <Tooltip aria-label={translate('button.edit')} title={translate('button.edit')}>
                      <IconButton color="secondary">
                        {!isMisterflyTicket({ allowanceName: row.allowanceName })
                          ? (
                            <FontAwesomeIcon icon={faEdit} size="xs" />
                          ) : (
                            <FontAwesomeIcon icon={faEye} size="xs" />
                          )}
                      </IconButton>
                    </Tooltip>
                  )
                    : (
                      <Tooltip aria-label={translate('button.consult')} title={translate('button.consult')}>
                        <IconButton color="secondary">
                          <FontAwesomeIcon icon={faEye} size="xs" />
                        </IconButton>
                      </Tooltip>
                    )}
                </Link>
              </Grid>
              {row.type.id === 2 && !isMisterflyTicket({ allowanceName: row.allowanceName }) && (
                <Grid item>
                  <Tooltip aria-label={translate('button.delete')} title={translate('button.delete')}>
                    <IconButton color="primary" onClick={() => handleDeleteAllowanceApplication(row)}>
                      <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}

          {(status === 'accepted' && row.type.id === 2) && (
            <Link to={{
              pathname: url || '',
              state: { allowanceApplicationId: row.id }
            }}
            >
              <Tooltip aria-label={translate('button.edit')} title={translate('button.edit')}>
                <IconButton color="secondary">
                  <FontAwesomeIcon icon={faEdit} size="xs" style={{ marginLeft: '4px' }} />
                </IconButton>
              </Tooltip>
            </Link>
          )}

          {status === 'done' && (
            <Link to={{
              pathname: url || '',
              state: { allowanceApplicationId: row.id }
            }}
            >
              <Tooltip aria-label={translate('button.consult')} title={translate('button.consult')}>
                <IconButton color="secondary">
                  <FontAwesomeIcon icon={faEye} size="xs" />
                </IconButton>
              </Tooltip>
            </Link>
          )}
        </TableCell>
      );
    }
  }
]);

const UserAllowanceApplication = observer(() => {
  const history = useHistory();
  const { state } = useLocation();
  const { userStore, allowanceStore } = useStores();
  const {
    userAllowanceLeisureApplicationList,
    userAllowanceSocialApplicationList,
    userRecentAllowanceLeisureApplicationList,
    userRecentAllowanceSocialApplicationList,
    isLoadingAllowanceApplication
  } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const { allowanceCategoryMapper } = allowanceStore;
  const showModal = useModal();
  const [filter, setFilter] = useState(true);

  const hasPrivileges = usePrivileges([
    'EspaceAdherentVoirDemandesPrestations'
  ]);
  const [tabSelected, setTabSelected] = useState(0);

  const handleChangeTab = useCallback((_, tab) => { setFilter(true); setTabSelected(tab); }, []);

  const getAllowanceApplicationList = useCallback(() => userStore.getAllowanceApplication()
    .catch(() => enqueueSnackbar(translate('errors.failedAllowanceApplication'), { variant: 'error' })),
  [enqueueSnackbar, userStore]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (state && state.category) {
      setTabSelected(state.category);
    }

    if (!hasPrivileges) {
      history.push(PATHS.HOME);
    }

    getAllowanceApplicationList();
  }, [hasPrivileges, state, history, getAllowanceApplicationList]);

  const deleteAllowanceApplication = useCallback(id => {
    AllowanceApplicationService.deleteAllowanceApplication(id)
      .then(() => {
        enqueueSnackbar(translate('confirms.successDeleteAllowanceApplication'), { variant: 'success' });
        getAllowanceApplicationList();
      })
      .catch(() => enqueueSnackbar(translate('errors.failedDeleteAllowanceApplication'), { variant: 'error' }));
  }, [getAllowanceApplicationList, enqueueSnackbar]);

  const handleDeleteAllowanceApplication = useCallback(row => showModal({
    type: 'DELETE_ALLOWANCE_APPLICATION',
    onConfirm: () => deleteAllowanceApplication(row.id),
    title: 'warnings.deleteAllowanceApplication',
    buttonLabel: 'button.delete',
    details: row
  }), [showModal, deleteAllowanceApplication]);

  return (
    <Wrapper>
      <section>
        <Grid container>
          <Grid item xs={12}>
            <PageTitleContainer>
              <PageTitle title="userMenu.personalAllowances" withMargin={false} />
            </PageTitleContainer>
          </Grid>

          <Grid item xs={12}>
            {isLoadingAllowanceApplication && <SkeletonTable />}
            {!isLoadingAllowanceApplication && (
              <>
                <Tabs
                  centered
                  indicatorColor="secondary"
                  textColor="secondary"
                  value={tabSelected}
                  variant="fullWidth"
                  onChange={handleChangeTab}
                >
                  <Tab
                    label={translate('userMenu.allowancesSocial')}
                  />
                  <Tab
                    label={translate('userMenu.allowancesLeisure')}
                  />
                </Tabs>

                <Suspense fallback={() => (<SkeletonNoTableResults error="errors.noAllowanceApplication" />)}>
                  {[
                    userAllowanceSocialApplicationList.length > 0 ? (
                      <GenericTable
                        headers={tableListHeaders(handleDeleteAllowanceApplication, allowanceCategoryMapper)}
                        rows={filter ? userRecentAllowanceSocialApplicationList : userAllowanceSocialApplicationList}
                      />
                    )
                      : <SkeletonNoTableResults error="errors.noAllowanceSocialApplication" />,
                    userAllowanceLeisureApplicationList.length > 0 ? (
                      <GenericTable
                        headers={tableListHeaders(handleDeleteAllowanceApplication, allowanceCategoryMapper)}
                        rows={filter ? userRecentAllowanceLeisureApplicationList : userAllowanceLeisureApplicationList}
                      />
                    )
                      : <SkeletonNoTableResults error="errors.noAllowanceLeisureApplication" />
                  ][tabSelected]}
                </Suspense>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <ExpandButton alignContent="center" container direction="column" justify="center" onClick={() => setFilter(!filter)}>
              <Grid item>
                <Typography>
                  {filter ? 'Voir toutes les demandes' : 'Ne voir que les demandes de moins de 3 mois'}
                </Typography>
              </Grid>
              <Grid item>
                <FontAwesomeIcon icon={filter ? faChevronDown : faChevronUp} />
              </Grid>
            </ExpandButton>
          </Grid>
        </Grid>
      </section>
    </Wrapper>
  );
});

export default UserAllowanceApplication;