import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { CustomCardTitle } from 'components';
import styled from 'styled-components';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { SubscriptionCard } from 'components/_commons';

const DisplaySubscriptionsCard = styled(Card)`
  cursor: pointer;

  &:hover {
    background-color: green;
  }
`;

const SubscriptionRow = ({
  display, setDisplay, label, subscriptions, prices, disabled = false, id
}) => (
  <>
    <Grid id={id} item>
      <DisplaySubscriptionsCard onClick={() => {
        setDisplay(!display);
      }}
      >
        <CustomCardTitle
          icon={display ? faMinus : faPlus}
          title={display
            ? label.hide
            : label.display}
        />
      </DisplaySubscriptionsCard>
    </Grid>
    <Grid item>
      <Grid container spacing={2}>
        {display
          && (subscriptions.length > 0
            ? subscriptions.map(sub => (
              <SubscriptionCard
                disabled={disabled}
                key={sub.id}
                subscription={sub}
                tariff={prices.find(price => price.idRevue === sub.idRevue)}
              />
            ))
            : (
              <Typography color="error" style={{ width: '100%', textAlign: 'center' }}>
                Il n&apos;y a rien ici
              </Typography>
            ))}
      </Grid>
    </Grid>
  </>
);

export default SubscriptionRow;