import React, { useCallback, useEffect } from 'react';
import {
  Button, Card, CardContent, Grid, IconButton, TableCell, Tooltip, Typography
} from '@material-ui/core';
import { TabContainer } from 'components/UserProfile/UserProfile';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { FormHelper, translate } from 'utils';
import {
  faTrashAlt, faUserEdit, faUserPlus, faUsersClass
} from '@fortawesome/pro-regular-svg-icons';
import { useModal, useStores } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomCardTitle, GenericTable } from 'components';
import { RightHolderService } from 'services';
import shortid from 'shortid';

const tableListHeaders = (handleOpenDetail, handleDeleteRightHolder) => ([
  {
    name: 'rightHolder',
    label: 'common.rightHolder',
    template: row => (
      <TableCell key={shortid.generate()}>
        {`${row.firstName} ${row.lastName}`}
      </TableCell>
    )
  },
  {
    name: 'actions',
    label: 'common.actions',
    width: '100px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container wrap="nowrap">
          <Grid item>
            <Tooltip aria-label={translate('button.edit')} title={translate('button.edit')}>
              <IconButton color="secondary" onClick={() => handleOpenDetail(row)}>
                <FontAwesomeIcon icon={faUserEdit} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip aria-label={translate('button.delete')} title={translate('button.delete')}>
              <IconButton color="primary" onClick={() => handleDeleteRightHolder(row)}>
                <FontAwesomeIcon icon={faTrashAlt} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    )
  },
  {
    name: 'status',
    label: 'common.status',
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.isValidated
          ? translate('pageUserProfile.rightHolder.validated')
          : translate('pageUserProfile.rightHolder.validationInProgress')}
      </TableCell>
    )
  }
]);

const RightHolder = observer(() => {
  const { userStore } = useStores();
  const { allUserRightHolder, userConnected } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();

  const getRightHolderList = useCallback(() => {
    userStore.getRightHolderList()
      .catch(() => enqueueSnackbar(translate('errors.failedRightHolderList'), { variant: 'error' }));
  }, [userStore, enqueueSnackbar]);

  useEffect(() => {
    getRightHolderList();
  }, [getRightHolderList]);

  const addRightHolder = useCallback(addedRightHolder => {
    const rightHolder = {
      ...addedRightHolder,
      birthdate: FormHelper.formatDate(addedRightHolder.birthdate),
      linkedMemberId: userConnected.id
    };

    if (rightHolder.id) {
      RightHolderService.updateRightHolder(rightHolder.id, rightHolder)
        .then(() => {
          enqueueSnackbar(translate('confirms.rightHolderUpdate'), { variant: 'success' });
          getRightHolderList();
        })
        .catch(() => enqueueSnackbar(translate('errors.failedRightHolderUpdate'), { variant: 'error' }));
    } else {
      RightHolderService.createRightHolder(rightHolder)
        .then(() => {
          enqueueSnackbar(translate('confirms.rightHolderCreate'), { variant: 'success' });
          getRightHolderList();
        })
        .catch(() => enqueueSnackbar(translate('errors.failedRightHolderCreate'), { variant: 'error' }));
    }
  }, [userConnected, enqueueSnackbar, getRightHolderList]);

  const deleteRightHolder = useCallback(id => {
    RightHolderService.deleteRightHolder(id)
      .then(() => {
        enqueueSnackbar(translate('confirms.rightHolderDelete'), { variant: 'success' });
        getRightHolderList();
      })
      .catch(() => enqueueSnackbar(translate('errors.failedRightHolderDelete'), { variant: 'error' }));
  }, [enqueueSnackbar, getRightHolderList]);

  const handleDeleteRightHolder = useCallback(user => showModal({
    type: 'WARNING',
    onConfirm: () => deleteRightHolder(user.id),
    title: 'warnings.deleteRightHolder',
    buttonLabel: 'button.delete',
    text: (
      translate('warnings.deleteRightHolderText', { rightHolder: `${user.firstName} ${user.lastName}` })
    )
  }), [showModal, deleteRightHolder]);

  const handleOpenDetail = useCallback(user => showModal({
    type: 'RIGHT_HOLDER',
    onConfirm: addRightHolder,
    defaultValues: user || {},
    title: user ? 'pageUserProfile.rightHolder.editRightHolder' : 'pageUserProfile.rightHolder.addRightHolder',
    buttonLabel: user ? 'button.update' : 'button.add'
  }), [showModal, addRightHolder]);

  return (
    <TabContainer>
      <Grid container>
        <Grid item sm={6} xs={12}>

          <Card>
            <CustomCardTitle
              button={(
                <IconButton color="primary" onClick={() => handleOpenDetail(null)}>
                  <FontAwesomeIcon icon={faUserPlus} />
                </IconButton>
              )}
              icon={faUsersClass}
              title="pageUserProfile.rightHolder.title"
            />
            <CardContent>
              <Grid alignItems="flex-start" container direction="column">
                {allUserRightHolder.length > 0 ? (
                  <GenericTable
                    headers={tableListHeaders(handleOpenDetail, handleDeleteRightHolder)}
                    rows={allUserRightHolder}
                    size="small"
                  />
                ) : (
                  <Grid alignItems="center" container direction="column" spacing={2}>
                    <Grid item>
                      <Typography>{translate('errors.noRightHolder')}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        startIcon={<FontAwesomeIcon icon={faUserPlus} />}
                        onClick={() => handleOpenDetail(null)}
                      >
                        {translate('button.add')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </TabContainer>
  );
});

export default RightHolder;