import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import { useStores } from 'hooks';
import { CategoryHelper } from 'utils';
import { observer } from 'mobx-react-lite';

const PageAllowanceMain = observer(() => {
  const { pathname } = useLocation();
  const { allowanceStore } = useStores();
  const { leisureAllowanceCategories, socialAllowanceCategories } = allowanceStore;
  const [redirect, setRedirect] = useState(null);

  const handleSetRedirect = useCallback(() => setRedirect(pathname.indexOf('sociales') > -1
    ? ROUTES.ALLOWANCE_SOCIAL_CATEGORY(CategoryHelper.getCategoryFormattedName(socialAllowanceCategories[0].name))
    : ROUTES.ALLOWANCE_LEISURE_CATEGORY(CategoryHelper.getCategoryFormattedName(leisureAllowanceCategories[0].name))),
  [pathname, socialAllowanceCategories, leisureAllowanceCategories]);

  useEffect(() => {
    if (!socialAllowanceCategories || !leisureAllowanceCategories) {
      Promise.all([allowanceStore.getLeisureAllowanceCategories(), allowanceStore.getSocialAllowanceCategories()]).then(() => {
        handleSetRedirect();
      });
    } else {
      handleSetRedirect();
    }
  }, [socialAllowanceCategories, leisureAllowanceCategories, handleSetRedirect, allowanceStore]);

  return (
    redirect && <Redirect to={redirect} />
  );
});

export default PageAllowanceMain;