/* ***
** Usage

const Component = () => {
  const hasAccessRights = useAccessRights({ roles: [ADOSSPP_ROLES.ACTIVE] });
};

*/

import { useStores } from 'hooks';
import { UserHelper } from 'utils';
import { ADOSSPP_ROLES } from 'utils/constants';

const useAccessRights = (roles = [ADOSSPP_ROLES.ACTIVE]) => {
  const { userStore } = useStores();
  const { isConnected, userConnected } = userStore;
  if(roles.length > 0){
    return (isConnected && UserHelper.hasAccessRight(userConnected, roles));
  }
  return isConnected;
};

export default useAccessRights;