import React, { useCallback } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'utils';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';

const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

const FormSelect = ({
  name, options, value, label, required, error,
  placeholder, helperText, ...props
}) => {
  const displayNoOptions = useCallback(() => translate('common.noOptions'), []);

  if (!options || options.length === 0) return null;

  return (
    <FormControl error={error} required={required} style={{ width: '100%' }}>
      {label && (
        <FormLabel>
          {translate(label)}
        </FormLabel>
      )}

      <Select
        name={name}
        noOptionsMessage={displayNoOptions}
        options={options}
        placeholder={translate(placeholder)}
        styles={{ menu: base => ({ ...base, zIndex: 2000 }) }}
        value={value}
        {...props}
      />

      {required && (
        <StyledRequiredSelectHack
          autoComplete="off"
          defaultValue={value || ''}
          required
          tabIndex={-1}
        />
      )}

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

FormSelect.defaultProps = {
  isClearable: false,
  isMulti: false,
  options: [],
  placeholder: 'common.selectOption',
  required: false,
  value: null
};

export default FormSelect;