/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Grid, IconButton, Tooltip, Typography
} from '@material-ui/core';
import { FormInputFile, FormSectionTitle } from 'components';
import { translate } from 'utils';
import { faFilePlus, faPaperclip, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Documents = ({
  label, placeholder, documents, disabled, maxFiles,
  handleFileAdded, handleAddFile, handleDeleteFile, documentsEmptyError, isOptional = false
}) => (
  <Grid alignItems="flex-start" container direction="column">
    <FormSectionTitle
      icon={faPaperclip}
      title={translate(label)}
      tooltip="forms.addtionnalFileTooltip"
    />

    {documentsEmptyError && (
      <Typography color="error" gutterBottom>
        {translate('errors.noRequiredDocuments')}
      </Typography>
    )}

    {isOptional && (
      <Typography gutterBottom variant="caption">
        {translate('common.optional')}
      </Typography>
    )}

    {(placeholder && placeholder !== '<p> </p>')
      && (
        <Typography dangerouslySetInnerHTML={{ __html: placeholder.replace(/\n/g, '<br />').replace('<p> </p>', '') }} />
      )}

    {!disabled && (documents.length < maxFiles) && (
      <Button
        aria-label={translate('button.addFile')}
        startIcon={<FontAwesomeIcon icon={faFilePlus} />}
        variant="text"
        onClick={handleAddFile}
      >
        {translate('button.addFile')}
      </Button>
    )}

    {documents.length > 0 && (
      <Grid container>
        <Grid item xs>
          {documents.map((doc, index) => (
            <Grid
              alignItems="center"
              container
              key={index}
              spacing={2}
              wrap="nowrap"
            >
              <Grid item xs={11}>
                <FormInputFile
                  disabled={disabled}
                  document={doc}
                  fileAccepted=".pdf, .jpg, .jpeg, .png"
                  handleFileAdded={docAdded => handleFileAdded(docAdded, index)}
                  id={index}
                />
              </Grid>

              {!disabled && (
                <Grid item xs={1}>
                  <Grid container justify="flex-end">
                    <Tooltip aria-label={translate('button.delete')} title={translate('button.delete')}>
                      <IconButton color="primary" edge="end" onClick={() => handleDeleteFile(index)}>
                        <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    )}
  </Grid>
);

Documents.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  documents: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    name: PropTypes.string.isRequired
  })).isRequired,
  handleAddFile: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  handleFileAdded: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  placeholder: PropTypes.string
};

Documents.defaultProps = {
  disabled: false,
  label: 'forms.documents',
  maxFiles: 10,
  placeholder: ''
};

export default Documents;