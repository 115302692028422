import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBooksMedical } from '@fortawesome/pro-regular-svg-icons';
import {
  DialogActions, DialogContent, Button, Card, CardContent, Grid, TextField
} from '@material-ui/core';
import { FormHelper, translate } from 'utils';
import { CustomCardTitle, FormAddress, InfoLine } from 'components';
import { useFormState } from 'react-use-form-state';
import { PAYMENT_TYPE } from 'utils/constants';
import { ModalHeader } from './_ModalHeader';
import { PayboxPayment } from '..';

export const SubscriptionModal = ({
  onClose, title, subscription
}) => {
  const [formState, { number, text, raw }] = useFormState({
    ...subscription.address,
    unitPrice: subscription.price,
    numberOfCopies: subscription.numberOfCopies,
    country: FormHelper.getDefaultCountry(subscription.address),
    price: 0
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    formState.setField('price', formState.values.unitPrice * formState.values.numberOfCopies);
  }, [formState, formState.values.numberOfCopies]);

  useEffect(() => {
    if (formState.values.address1 !== null && formState.values.city !== null && formState.values.postalCode !== null) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formState.values]);

  const settleSubscription = () => new Promise(resolve => resolve({
    itemId: subscription.id,
    confirmParameter: `subscriptionId=${subscription.id}`,
    price: subscription.price * subscription.numberOfCopies
  }));

  return (
    <form style={{ minWidth: '800px' }}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon className="mr1" icon={faBooksMedical} />
        {translate(title)}
      </ModalHeader>

      <DialogContent style={{ minHeight: '330px' }}>
        <Card>
          <CustomCardTitle
            icon={faBooksMedical}
            title="pageUserProfile.subscriptions.addAllo18Subscription"
          />
          <CardContent>
            <Grid container direction="column">
              <Grid item>
                <FormAddress formState={formState} raw={raw} text={text} />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="numberOfCopies"
                  label={translate('common.numberOfCopiesToReceive')}
                  name="numberOfCopies"
                  {...number('numberOfCopies')}
                />
              </Grid>
              <br />
              <Grid item>
                <InfoLine
                  info={`${formState.values.price}€`}
                  label="common.price"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>

      <DialogActions>
        <Grid container justify="space-between">
          <Button
            aria-label={translate('button.cancel')}
            variant="text"
            onClick={onClose}
          >
            {translate('button.cancel')}
          </Button>
          <PayboxPayment
            buttonLabel="button.settle"
            disabled={disabled}
            getPaymentInformations={settleSubscription}
            typeId={PAYMENT_TYPE.RESUBSCRIPTION.code}
          />
        </Grid>
      </DialogActions>
    </form>
  );
};

SubscriptionModal.propTypes = {
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string
};

SubscriptionModal.defaultProps = {
  buttonLabel: 'button.create',
  title: 'pageUserProfile.rightHolder.addRightHolder'
};