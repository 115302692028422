import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { FormHelper } from 'utils';

const StyledNews = styled.article`
  position: relative;
  padding: 1rem 2rem 1rem 5rem;
  border: 1px solid var(--grey-light);
  background: url('/assets/images/bg/header-triangle.png') no-repeat -25px -45px;
  border-radius: 8px;

  &[data-hasurl="true"]{
    cursor: pointer;
    transition: background-color var(--transitionTime);

    &:hover {
      background-color: var(--secondary-color-lighter);

      svg[data-icon="chevron-right"] {
        opacity: 1;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 25px;
    bottom: 0;
    left: 10px;
    border: 1px solid var(--primary-color);
  }

  svg[data-icon="chevron-right"] {
    opacity: 0;
    transition: opacity var(--transitionTime);
  }

`;

const NewsResume = styled(Typography)`
  width: 100%;
  color: var(--grey-dark);
`;

const News = props => {
  const {
    id, texte, url
  } = props;

  const handleToggleMenu = () => {
    if (url) {
      window.open(FormHelper.getExternalURL(url), '_blank');
    }
  };

  return (
    <>
      <StyledNews data-hasurl={!!url} id={id} onClick={handleToggleMenu}>
        <Grid alignItems="center" container justify="space-between" spacing={2} wrap="nowrap">
          <Grid item zeroMinWidth>
            <NewsResume>
              {texte}
            </NewsResume>
          </Grid>
          {url && (
            <Grid item>
              <FontAwesomeIcon color="var(--secondary-color-dark)" icon={faChevronRight} size="2x" />
            </Grid>
          )}
        </Grid>
      </StyledNews>
    </>
  );
};

News.propTypes = {
  id: PropTypes.number.isRequired,
  texte: PropTypes.string,
  url: PropTypes.string
};

News.defaultProps = {
  texte: '',
  url: ''
};

export default News;