import { action, decorate, observable } from 'mobx';
import { NewsService } from 'services';
import { newsListFixture } from '__fixtures__';

export class NewsStore {
  constructor() {
    this.isLoading = false;
    this.news = null;
    this.newsList = [];
  }

  getNewsList() {
    this.isLoading = true;

    return NewsService.getNewsList().then(action(list => {
      this.newsList = list;
    }))
      .catch(action(error => {
        this.newsList = newsListFixture;
        throw error;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  getNewsDetail(id) {
    this.isLoading = true;

    return NewsService.getNewsDetail(id).then(action(detail => {
      this.news = detail;
    }))
      .catch(action(error => {
        throw error;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }
}

decorate(NewsStore, {
  isLoading: observable,
  news: observable,
  newsList: observable,
  getNewsList: action,
  getNewsDetail: action
});

export const newsStore = new NewsStore();