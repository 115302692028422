import React from 'react';
import styled, { css } from 'styled-components';

const StyledFormCheckboxContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  margin: 2rem 0;
  background-color: #dbeef6;

  ${props => props.fullWidth && css`width: 100%;`}
`;

const FormCheckboxContainer = ({ children, fullWidth }) => (
  <StyledFormCheckboxContainer fullWidth={fullWidth}>
    {children}
  </StyledFormCheckboxContainer>
);

export default FormCheckboxContainer;