/* eslint-disable max-len */
import React from 'react';
import {
  Button, Grid, InputAdornment, TextField, Typography
} from '@material-ui/core';
import { FormSectionTitle, Mobile } from 'components';
import {
  faArrowAltDown, faArrowAltUp, faTicketAlt
} from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ArrowButton = styled(Button)`
  max-width: 10px;
  margin: 0 5px;
`;

const Ticketing = ({
  onChange, disabled, allowance, formState, text
}) => {
  const handleAddTicket = () => {
    let newTicketsNumber = formState.values.ticketsNumber;
    if (newTicketsNumber < allowance.stock) {
      newTicketsNumber += 1;
    }
    formState.setField('ticketsNumber', newTicketsNumber);
  };

  const handleRemoveTicket = () => {
    let newTicketsNumber = formState.values.ticketsNumber;
    if (newTicketsNumber > 1) {
      newTicketsNumber -= 1;
    }
    formState.setField('ticketsNumber', newTicketsNumber);
  };

  return (
    <Grid container direction="column">
      <FormSectionTitle icon={faTicketAlt} title="Billetterie" />

      <Grid container direction="column" spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography>{translate('common.ticketsReservation')}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            autoFocus
            disabled={disabled}
            id="ticketsNumber"
            InputLabelProps={{
              focused: true
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {translate('common.ticketsNumber')}
                </InputAdornment>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              max: allowance.stock,
              min: 1,
              step: 1
            }}
            name="ticketsNumber"
            required
            type="number"
            value={formState.values.ticketsNumber}
            onChange={onChange}
            onKeyDown={e => { if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') e.preventDefault(); }}
          />
          
          <Typography style= {{ marginTop: '20px' }}>
            Commentaires :
          </Typography>
          <TextField
            fullWidth
            id="memberComment"
            multiline
            name="memberComment"
            rows={3}
            variant="outlined"
            {...text('memberComment')}
          />

          {!disabled && (
            <Mobile>
              <ArrowButton color="secondary" style={{ marginLeft: '10px' }} onClick={handleRemoveTicket}>
                <FontAwesomeIcon icon={faArrowAltDown} />
              </ArrowButton>
              <ArrowButton color="secondary" onClick={handleAddTicket}>
                <FontAwesomeIcon icon={faArrowAltUp} />
              </ArrowButton>
            </Mobile>
          )}
        </Grid>
        <Grid container direction="column">
          {(allowance.stock < 10 && !disabled) && (
            <Grid item>
              <Typography color="error" gutterBottom style={{ paddingLeft: '8px' }} variant="caption">
                Attention il ne reste actuellement que
                {` ${allowance.stock} `}
                ticket(s) en stock
              </Typography>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid item>
          <Typography gutterBottom variant="h3">
            Total à payer :
            {` ${formState.values.price}€`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="caption">
            {`Votre réservation sera majorée de ${allowance.markupPrice}€ (frais d'envoi) pour l'envoi de vos billets a votre domicile`}
          </Typography>
        </Grid>
        <Grid item>
          {!disabled && (
            <Typography gutterBottom>
              Vous devrez régler ce montant dans la rubrique &ldquo;Mes demandes de prestations&ldquo;
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Ticketing.defaultProps = {
  disabled: false
};

export default Ticketing;
