import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs as MatBreadcrumbs } from '@material-ui/core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

const BreadcrumbsContainer = styled.aside`
  margin: 2rem 0;
`;

const Breadcrumbs = ({ children }) => (
  <BreadcrumbsContainer>
    <MatBreadcrumbs
      aria-label="breadcrumb"
      separator={<FontAwesomeIcon icon={faChevronRight} size="sm" />}
      variant="body2"
    >
      {children}
    </MatBreadcrumbs>
  </BreadcrumbsContainer>
);

Breadcrumbs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
};

export default Breadcrumbs;