/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { PAYMENT_TYPE, ROUTES } from 'utils/constants';
import { useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { PayboxService, SubscriptionService } from 'services';
import { FormHelper, translate } from 'utils';
import { Alert } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';

export const PayboxPayment = ({
  getPaymentInformations,
  disabled = false,
  typeId,
  buttonLabel = 'button.validate',
  buttonId,
  providedEmail = null
}) => {
  const { userStore } = useStores();
  const { userConnected } = userStore;
  const showModal = useModal();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState('ready');
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (providedEmail) {
      setEmail(providedEmail);
    } else {
      setEmail(userConnected.email);
    }
  }, []);

  const checkPrice = paymentInformation => {
    if (!paymentInformation.price && paymentInformation.price !== 0) {
      setStatus('errorPaybox');
      return false;
    }
    return true;
  };

  const handleSubmitModal = () => {
    setStatus('ready');
    history.push(ROUTES.CONNECT);
  };

  const handleCloseModal = () => {
    setStatus('ready');
  };

  const createShoppingCartXML = () => {
    const xmlDoc = document.implementation.createDocument('', '', null);
    const shoppingCartElement = xmlDoc.createElement('shoppingcart');

    const totalElement = xmlDoc.createElement('total');
    const totalQuantityElement = xmlDoc.createElement('totalQuantity');
    totalQuantityElement.textContent = 1;

    totalElement.appendChild(totalQuantityElement);
    shoppingCartElement.appendChild(totalElement);

    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(shoppingCartElement);

    return `<?xml version='1.0' encoding='utf-8'?>${xmlString}`;
  };

  const createBillingXML = () => {
    let userInfos;
    if (userConnected == null) {
      try {
        userInfos = JSON.parse(localStorage.getItem('subscriber'));
      } catch (error) {
        userInfos = {};
      }
    } else {
      userInfos = userConnected;
    }

    const xmlDoc = document.implementation.createDocument('', '', null);
    const billingElement = xmlDoc.createElement('Billing');
    const addressElement = xmlDoc.createElement('Address');

    const firstNameElement = xmlDoc.createElement('FirstName');
    firstNameElement.textContent = userInfos.firstName;

    const lastNameElement = xmlDoc.createElement('LastName');
    lastNameElement.textContent = userInfos.lastName;

    const address1Element = xmlDoc.createElement('Address1');
    address1Element.textContent = userInfos?.address?.address1 || userInfos?.address1;

    const zipCodeElement = xmlDoc.createElement('ZipCode');
    zipCodeElement.textContent = userInfos?.address?.postalCode || userInfos.postalCode;

    const cityElement = xmlDoc.createElement('City');
    cityElement.textContent = userInfos?.address?.city || userInfos?.city;

    const countryCodeElement = xmlDoc.createElement('CountryCode');
    let countryId;
    if (userInfos?.address) {
      countryId = FormHelper.getDefaultCountry(userInfos?.address).id;
    } else {
      countryId = userInfos?.country?.id || '250';
    }
    countryCodeElement.textContent = countryId;

    addressElement.appendChild(firstNameElement);
    addressElement.appendChild(lastNameElement);
    addressElement.appendChild(address1Element);
    addressElement.appendChild(zipCodeElement);
    addressElement.appendChild(cityElement);
    addressElement.appendChild(countryCodeElement);

    billingElement.appendChild(addressElement);

    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(billingElement);
    return `<?xml version='1.0' encoding='utf-8'?>${xmlString}`;
  };

  const handleSubmit = async event => {
    setStatus('sending');
    getPaymentInformations().then(async paymentInformation => {
      if (paymentInformation === undefined) {
        enqueueSnackbar(translate('errors.errorPaybox'), { variant: 'error' });
        setStatus('errorPaybox');
        event.preventDefault();
      } else if (checkPrice(paymentInformation)) {
        const time = new Date().toISOString().split('.')[0];
        const price = parseFloat(paymentInformation.price) * 100;
        let pbxEffectue = `https://${window.location.host}${ROUTES.PAYBOX_CONFIRM(`typeId=${typeId}${paymentInformation.confirmParameter === undefined ? '' : paymentInformation.confirmParameter}`)}`;

        if (typeId === PAYMENT_TYPE.SUBSCRIPTION.code) {
          const subscriberAlreadyExists = await SubscriptionService.isSubscriberExists({
            nom: paymentInformation.lastName,
            prenom: paymentInformation.firstName,
            codePostal: paymentInformation.postalCode,
            courrielPersonnel: paymentInformation.email
          });
          if (subscriberAlreadyExists.data) {
            showModal({
              type: 'SUBSCRIBER_EXISTS',
              onProceed: handleSubmitModal,
              subscriber: paymentInformation,
              onCancel: handleCloseModal
            });
            return;
          }
          pbxEffectue = `https://${window.location.host}${ROUTES.PAYBOX_CONFIRM_SUBSCRIPTION(`typeId=${typeId}${paymentInformation.confirmParameter === undefined ? '' : paymentInformation.confirmParameter}`)}`;
        }

        const pbxCommand = `Command${typeId}_${paymentInformation.itemId}_${time}`;
        const shoppingCartXML = createShoppingCartXML();
        const billingXML = createBillingXML();
        // Recovery of the HMAC print used to securised the transaction from our php server
        PayboxService.getHmac({
          PBX_TOTAL: price,
          PBX_CMD: pbxCommand,
          PBX_PORTEUR: email,
          PBX_TIME: time,
          PBX_EFFECTUE: pbxEffectue,
          PBX_TYPE: typeId,
          PBX_SHOPPINGCART: shoppingCartXML,
          PBX_BILLING: billingXML
        }).then(hmacResponse => {
          PayboxService.gotoPaymentPage(hmacResponse);
        }).catch(() => {
          enqueueSnackbar(translate('errors.errorPaybox'), { variant: 'error' });
          event.preventDefault();
          setStatus('errorPaybox');
        });
      }
    });
  };

  useEffect(() => {
    if (!email || email === '') {
      setStatus('errorPayboxMail');
    } else {
      setStatus('ready');
    }
  }, [email]);

  const getErrorLabel = () => {
    switch (status) {
    case 'errorPayboxMail':
    case 'errorPaybox':
      return status;
    default:
      return 'errorPaybox';
    }
  };

  const renderSwitchStatus = () => {
    switch (status) {
    case 'sending':
      return (<CircularProgress />);
    case 'ready':
      return (
        <Button color="primary" disabled={disabled} id={buttonId} onClick={handleSubmit}>
          {translate(buttonLabel)}
        </Button>
      );
    default:
      return (<Alert severity="error">{translate(`errors.${getErrorLabel()}`)}</Alert>);
    }
  };

  return (
    <>{renderSwitchStatus()}</>
  );
};

export default PayboxPayment;