import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import {
  DialogActions, DialogContent, Button, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import { StyledStatusDisplay } from 'components/_commons/StatusDisplay/StatusDisplay';
import { ModalHeader } from './_ModalHeader';

export const DeleteAllowanceApplicationModal = ({
  onClose, onConfirm, title, buttonCancel, buttonConfirm, details
}) => {
  const handleCancel = useCallback(() => onClose(), [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    return onClose();
  }, [onConfirm, onClose]);

  return (
    <form onSubmit={handleConfirm}>
      <ModalHeader className="warningModal" onClose={onClose}>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {translate(title)}
      </ModalHeader>

      <DialogContent style={{ maxWidth: '600px', paddingTop: '2rem' }}>
        <Typography gutterBottom>
          {translate('warnings.deleteAllowanceApplicationMessage')}
        </Typography>

        <StyledStatusDisplay>
          <Typography>
            {details.allowanceName || '-'}
            {details.category && (
              <>
                <br />
                <small>{details.category.name}</small>
              </>
            )}
          </Typography>
        </StyledStatusDisplay>

      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={handleCancel}>{translate(buttonCancel)}</Button>
        <Button
          color="primary"
          id="warningModalConfirm"
          type="submit"
          variant="contained"
        >
          {translate(buttonConfirm)}
        </Button>
      </DialogActions>
    </form>
  );
};

DeleteAllowanceApplicationModal.propTypes = {
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string,
  details: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  title: PropTypes.string
};

DeleteAllowanceApplicationModal.defaultProps = {
  buttonCancel: 'button.cancel',
  buttonConfirm: 'button.confirm',
  onConfirm: null,
  title: 'warnings.warning'
};