import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DialogTitle } from '@material-ui/core';
import { CloseButton } from './_CloseButton';

const StyledDialogTitle = styled(DialogTitle)`
  color: var(--white);
  background: var(--secondary-color);

  &.warningModal {
    background: #D14900;
  }

  & > svg {
    margin-right: 1rem;
  }
`;

export const ModalHeader = ({
  onClose, children, className
}) => (
  <StyledDialogTitle className={className} disableTypography style={{ paddingRight: '4rem' }}>
    {children}
    <CloseButton onClose={onClose} />
  </StyledDialogTitle>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string
};

ModalHeader.defaultProps = {
  className: ''
};