import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const PageSubtitle = ({ subtitle }) => (
  <Typography align="center" variant="h2">
    {translate(subtitle)}
  </Typography>
);

PageSubtitle.propTypes = {
  subtitle: PropTypes.string.isRequired
};

export const PageTitleContainer = styled.div`
  max-width: 860px;
  margin: -3rem auto 3rem auto;
  padding: 1rem 6rem;
  border-radius: 8px;
  background: var(--white);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const PageTitle = ({
  title, titleVar, withMargin, noTranslate
}) => (
  <Typography align="center" style={{ margin: withMargin ? '3rem 0' : 0 }} variant="h1">
    {noTranslate ? (
      title
    ) : (
      translate(title, titleVar)
    )}
  </Typography>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleVar: PropTypes.shape({}),
  withMargin: PropTypes.bool
};

PageTitle.defaultProps = {
  titleVar: null,
  withMargin: true
};

export const FancyWord = styled.span`
  color: var(--tertiary-color);
  font-weight: 400;
  font-family: 'Pacifico', cursive;
  font-size: ${props => props.fontSize};
`;

export const FancyTitle = ({
  fancyWord, title, variant, fontSize
}) => (
  <Typography align="center" component="h2" style={{ margin: '2rem 0' }} variant={variant}>
    <FancyWord fontSize={fontSize}>
      {translate(fancyWord)}
    </FancyWord>
    {' '}
    <strong>
      {translate(title)}
    </strong>
  </Typography>
);

FancyTitle.propTypes = {
  fancyWord: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string
};

FancyTitle.defaultProps = {
  fontSize: '4rem',
  variant: 'h1'
};

export const TextLine = styled(Typography)`
  margin: 2rem 0 !important;
`;

export default PageTitle;