/* eslint-disable consistent-return */
import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';

import { AllowanceHelper } from 'utils/helpers';
import { PATHS } from 'utils/constants';

const StyledHeaderTunnel = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background: url('/assets/images/bg/header-wizard.png') no-repeat center center;

  h1 {
    color: var(--white);
    text-transform: uppercase;
  }

  button {
    padding: 0 1rem;
  }

  @media (max-width: 768px) {
    padding: 3rem 0;
    margin: -1rem auto;
    background-size: 100% 100%;

    h1 {
      font-size: 1.8rem;
    }
  }
`;

const HeaderTunnel = observer(({ canGoBack }) => {
  const history = useHistory();
  const { allowanceStore } = useStores();
  const { state } = useLocation();
  const { allowance, allowanceCategoryMapper } = allowanceStore;

  if (!allowance) {
    return null;
  }

  return (
    <StyledHeaderTunnel>
      {canGoBack ? (
        <Button
          variant="text"
          onClick={() => {
            const categoryId = Number(allowance.type.id) - 1;
            if (state && state.allowanceApplicationId) {
              history.push({
                pathname: PATHS.USER_ALLOWANCE_APPLICATION,
                state: { category: categoryId }
              });
            } else {
              history.push(AllowanceHelper.getAllowanceDetailUrl(
                allowance.type,
                allowance.category,
                allowanceCategoryMapper,
                allowance.id
              ));
            }
          }}
        >
          <FontAwesomeIcon className="mr1" color="var(--white)" icon={faChevronLeft} size="lg" />
          <Typography variant="h1">{allowance.name}</Typography>
        </Button>
      )
        : <Typography variant="h1">{allowance.name}</Typography>}
    </StyledHeaderTunnel>
  );
});

export default HeaderTunnel;