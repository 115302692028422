import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, TextareaAutosize, Typography
} from '@material-ui/core';
import { FormSectionTitle } from 'components';
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';

const AdditionalData = ({
  allowance, onChange, value, disabled, isOptional = false
}) => (
  <Grid container direction="column">
    <FormSectionTitle icon={faSearchPlus} title={translate('forms.furtherInformation')} />

    {isOptional && (
      <Typography gutterBottom variant="caption">
        {translate('common.optional')}
      </Typography>
    )}

    {(allowance.furtherInformation && allowance.furtherInformation !== '<p> </p>') && (
      <Typography
        dangerouslySetInnerHTML={{ __html: allowance.furtherInformation.replace(/\n/g, '<br />').replace('<p> </p>', '') }}
        gutterBottom
      />
    )}

    <Grid item sm={6} xs={12}>
      <TextareaAutosize
        aria-label={translate('forms.furtherInformation')}
        disabled={disabled}
        id="furtherInformation"
        label={translate('forms.furtherInformation')}
        name="furtherInformation"
        rowsMax={10}
        rowsMin={4}
        style={{
          width: '100%',
          minWidth: '260px',
          maxWidth: '100%',
          maxHeight: '250px'
        }}
        value={value}
        onChange={onChange}
      />
    </Grid>
  </Grid>
);

AdditionalData.propTypes = {
  allowance: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

AdditionalData.defaultProps = {
  disabled: false
};

export default AdditionalData;