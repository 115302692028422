/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Link, useHistory, useLocation, useParams
} from 'react-router-dom';
import styled from 'styled-components';
import {
  Wrapper, Breadcrumbs, FormCheckboxContainer, InfoIcon, PageTitle,
  SkeletonAllowanceDetail, SkeletonNoAllowance, TextLine, BannerMessage, StatusDisplay, ActivityDisplay
} from 'components';
import { observer } from 'mobx-react-lite';
import {
  PATHS, ROUTES, MODULES, ADOSSPP_ROLES
} from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingHeart, faHome } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import {
  Button, Checkbox, FormControlLabel, Grid, Typography
} from '@material-ui/core';
import { useFormState } from 'react-use-form-state';
import {
  useAccessRights, useArray, useStores, useCanBook
} from 'hooks';
import { useSnackbar } from 'notistack';

import { AllowanceApplicationService } from 'services';
import { AllowanceHelper, DocumentHelper, FormHelper } from 'utils/helpers';
import { toJS } from 'mobx';
import { AllowanceLimitMessage } from 'components/_commons';
import { DateHelper } from 'utils/helpers/DateHelper';
import {
  AdditionalData, Beneficiary, Documents, Hospital, Session
} from './Modules';

const AllowanceSocialDetailContainer = styled.section`
  img {
    max-width: 90%;
    border-radius: 8px;
  }

  form > div {
    margin-bottom: 2rem;
  }

  input[type=number] {
    -moz-appearance: textfield;
    font-size: 2rem;
  }
`;

const AllowanceSocialDetail = observer(() => {
  const history = useHistory();
  const { id } = useParams();
  const { pathname, state } = useLocation();
  const { allowanceStore, userStore } = useStores();
  const { allowance, isLoading, allowanceCategoryMenuMapper } = allowanceStore;
  const { userRightHolder, userConnected } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const category = pathname.split('/')[2];

  const [userAllowanceApplicationDetail, setUserAllowanceApplicationDetail] = useState(null);

  const [isDeclaringNewBeneficiary, setIsDeclaringNewBeneficiary] = useState(false);
  const [sessionNumber, setSessionNumber] = useState(1);
  const [status, setStatus] = useState('ongoing');
  const [documentsEmptyError, setDocumentsEmptyError] = useState(false);
  const [limit, setLimit] = useState(false);
  const [beneficiaryModule, setBeneficiaryModule] = useState(false);
  let canBook = false;

  const hasAccessRights = useAccessRights();

  const {
    add, removeIndex, value: documents, setValue: setDocuments
  } = useArray([]);

  const {
    value: sessions, setValue: setSessions
  } = useArray([{
    number: 1,
    date: new Date()
  }]);

  const [formState, {
    text, radio, number, checkbox, raw
  }] = useFormState({
    dateIn: new Date(),
    dateOut: new Date()
  });

  if (allowance) {
    const roles = [];
    if (allowance.seniorityCondition) {
      roles.push(ADOSSPP_ROLES.FORMER);
    }
    if (allowance.activityCondition) {
      roles.push(ADOSSPP_ROLES.ACTIVE);
    }
    canBook = useCanBook(roles);
  }

  useEffect(() => {
    if (allowance) { setBeneficiaryModule(allowance.modules.find(mod => mod.id === MODULES.BENEFICIARY.code)); }
  }, [allowance]);

  useEffect(() => {
    window.scrollTo(0, 0);

    allowanceStore.getAllowanceDetail(id)
      .catch(() => enqueueSnackbar(translate('errors.failedAllowanceSocialDetail'), { variant: 'error' }));

    // If we come from the allowanceApplication list, get the allowanceApplication detail to prefill the form
    if (state && state.allowanceApplicationId) {
      AllowanceApplicationService.getAllowanceApplicationDetail(state.allowanceApplicationId)
        .then((resp => {
          const allowanceApplicationDetail = { ...resp };
          allowanceApplicationDetail.endDate = DateHelper.removeUTC(resp.endDate);
          allowanceApplicationDetail.startDate = DateHelper.removeUTC(resp.startDate);
          setUserAllowanceApplicationDetail(resp);
        }))
        .catch(() => enqueueSnackbar(translate('errors.failedAllowanceApplicationDetail'), { variant: 'error' }));
    }
  }, [allowanceStore, id, enqueueSnackbar, state, userStore]);

  useEffect(() => {
    !userRightHolder.length && userStore.getRightHolderList()
      .catch(() => enqueueSnackbar(translate('errors.failedRightHolderList'), { variant: 'error' }));
  }, [userStore, userRightHolder.length, enqueueSnackbar]);

  useEffect(() => {
    // If we come from the user allowanceApplication list, we must prefill the form fields
    if (userAllowanceApplicationDetail) {
      // Set the application status
      setStatus(AllowanceHelper.getAllowanceStatus(userAllowanceApplicationDetail.status));
      // Set the module: hospital
      if (userAllowanceApplicationDetail.hospitalization) {
        formState.setField('hospital', userAllowanceApplicationDetail.hospitalization.establishment);
        formState.setField('timeSpent', userAllowanceApplicationDetail.hospitalization.duration);
        formState.setField('dateIn', userAllowanceApplicationDetail.hospitalization.entryDate);
        formState.setField('dateOut', userAllowanceApplicationDetail.hospitalization.dischargeDate);
      }
      // Set the module: beneficiary
      if (userAllowanceApplicationDetail.rightHolders.length > 0) {
        formState.setField('rightHolderId', String(userAllowanceApplicationDetail.rightHolders[0].id));
      }
      // Set the module: sessions
      if (userAllowanceApplicationDetail.sessions && userAllowanceApplicationDetail.sessions.length > 0) {
        setSessions(userAllowanceApplicationDetail.sessions.map(ses => ({
          ...ses,
          date: ses.date
        })));
        setSessionNumber(userAllowanceApplicationDetail.sessions.length);
      }
      // Set the documents
      if (userAllowanceApplicationDetail.attachments && userAllowanceApplicationDetail.attachments.length > 0) {
        setDocuments(userAllowanceApplicationDetail.attachments.map(doc => ({
          ...doc,
          content: doc.url
        })));
      }
      // Set the addionalData
      if (userAllowanceApplicationDetail.memberComment) {
        formState.setField('memberComment', userAllowanceApplicationDetail.memberComment);
      }
    }
    // eslint-disable-next-line
  }, [userAllowanceApplicationDetail, setDocuments, setSessions]);

  const handleToggleAddBeneficiary = useCallback(() => {
    setIsDeclaringNewBeneficiary(!isDeclaringNewBeneficiary);
    formState.setField('rightHolderId', null);
  }, [isDeclaringNewBeneficiary, formState]);

  const handleResetDeclaring = useCallback(() => setIsDeclaringNewBeneficiary(false), []);

  const handleAddFile = useCallback(() => add(null), [add]);

  const handleDeleteFile = useCallback(index => removeIndex(index), [removeIndex]);

  const handleFileAdded = useCallback((doc, index) => {
    const currentDocuments = [...documents];
    currentDocuments[index] = doc;

    setDocuments(currentDocuments);
    setDocumentsEmptyError(false);
  }, [documents, setDocuments]);

  const handleChangeSessionNumber = useCallback(event => {
    const sesNum = Number(event.target.value);
    const currentSessions = [...sessions];

    if (sesNum === 0 || sesNum > 10) {
      return null;
    }

    if (sesNum > sessionNumber) {
      currentSessions.push({
        number: currentSessions.length + 1,
        date: new Date()
      });
    } else if (sesNum < sessionNumber) {
      currentSessions.pop();
    }

    setSessions(currentSessions);
    return setSessionNumber(sesNum);
  }, [sessionNumber, setSessionNumber, sessions, setSessions]);

  const handleSessionDateChange = useCallback((value, index) => {
    const currentSessions = [...sessions];
    currentSessions[index] = {
      ...currentSessions[index],
      date: value
    };

    setSessions(currentSessions);
  }, [setSessions, sessions]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    if ((allowance.attachments && allowance.attachments !== '<p> </p>') && documents.length === 0) {
      setDocumentsEmptyError(true);
      return;
    }

    const allowanceForm = {
      ...toJS(allowance),
      ...formState.values,
      allowanceId: allowance.id,
      attachments: documents.map(doc => DocumentHelper.getDocumentWithoutBase64(doc)),
      hospitalization: null,
      sessions: null
    };

    if (formState.values.rightHolderId) {
      allowanceForm.rightHolders = [{ id: Number(formState.values.rightHolderId) }];
    }

    if (allowance.modules.find(mod => mod.id === MODULES.HOSPITAL.code)) {
      allowanceForm.hospitalization = {
        establishment: formState.values.hospital,
        duration: Number(formState.values.timeSpent),
        entryDate: formState.values.dateIn,
        dischargeDate: formState.values.dateOut
      };
    }

    if (allowance.modules.find(mod => mod.id === MODULES.SESSION.code)) {
      allowanceForm.sessions = sessions.map(ses => ({
        ...ses,
        date: ses.date
      }));
    }

    delete allowanceForm.dateIn;
    delete allowanceForm.dateOut;
    delete allowanceForm.hospital;
    delete allowanceForm.timeSpent;

    // If we are modifing an existing allowance application, call update
    if (userAllowanceApplicationDetail) {
      AllowanceApplicationService.updateAllowanceApplication(userAllowanceApplicationDetail.id, allowanceForm).then(() => {
        history.push(ROUTES.ALLOWANCE_SOCIAL_DETAIL_CONFIRM(category, id));
      }).catch(() => enqueueSnackbar(translate('errors.failedUpdateAllowance'), { variant: 'error' }));
    } else {
      AllowanceApplicationService.createAllowanceApplication(allowanceForm).then(() => {
        history.push(ROUTES.ALLOWANCE_SOCIAL_DETAIL_CONFIRM(category, id));
      }).catch(() => enqueueSnackbar(translate('errors.failedCreateAllowance'), { variant: 'error' }));
    }
  }, [category, id, history, allowance, sessions, formState.values, documents, userAllowanceApplicationDetail, enqueueSnackbar]);

  if (isLoading) {
    return (
      <Wrapper>
        <SkeletonAllowanceDetail />
      </Wrapper>
    );
  }

  if (!allowance) {
    return (
      <Wrapper>
        <SkeletonNoAllowance error="errors.noAllowanceDetail" />
      </Wrapper>
    );
  }

  const isAllowanceReserved = allowance.activityCondition && !hasAccessRights;
  const isFormDisabled = (status === 'done') || isAllowanceReserved || limit || !FormHelper.getCanSubmit(canBook);

  return (
    <Wrapper>
      <AllowanceSocialDetailContainer>
        <Breadcrumbs>
          <Link to={PATHS.HOME}>
            <FontAwesomeIcon icon={faHome} />
          </Link>
          <Link to={PATHS.ALLOWANCE_SOCIAL_MAIN}>
            {translate('menu.allowanceSocial')}
          </Link>
          <Link to={ROUTES.ALLOWANCE_SOCIAL_CATEGORY(category)}>
            {allowanceCategoryMenuMapper[category]}
          </Link>
        </Breadcrumbs>

        {canBook.map(item => {
          if (!item.value) {
            return (
              <BannerMessage>
                {translate(`errors.${item.name}`)}
              </BannerMessage>
            );
          }
        })}

        {!userAllowanceApplicationDetail && (
          <AllowanceLimitMessage
            allowance={allowance}
            amount={allowance.fixedAmount}
            limit={limit}
            setLimit={setLimit}
            user={userConnected}
          />
        )}
        <PageTitle noTranslate title={allowance.name} />

        {isAllowanceReserved && (
          <ActivityDisplay message="warnings.allowanceReserved" />
        )}

        {userAllowanceApplicationDetail && (
          <StatusDisplay
            isDone={status === 'done'}
            userAllowanceApplicationDetail={userAllowanceApplicationDetail}
          />
        )}

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <img alt={allowance.name} src={(allowance.thumbnail && allowance.thumbnail.url) || '/assets/images/cover.png'} />
          </Grid>
          <Grid item sm={6} xs={12}>
            {allowance.gainDescription && (
              <InfoIcon
                icon={faHandHoldingHeart}
                info={allowance.gainDescription}
              />
            )}

            {allowance.description && (
              <>
                <Typography className="bold">{translate('common.description')}</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: allowance.description.replace(/\n/g, '<br />') }} />
              </>
            )}
          </Grid>
        </Grid>

        {!isFormDisabled && (
          <TextLine>{translate('forms.plzFillForm')}</TextLine>
        )}

        <form onSubmit={handleSubmit}>

          {/* OPTIONALS MODULES */}
          {allowance.modules.map(mod => {
            switch (mod.id) {
            case MODULES.HOSPITAL.code:
              return (
                <Hospital
                  allowance={allowance}
                  dateIn={{
                    ...raw({
                      name: 'dateIn',
                      compare: (initVal, val) => initVal !== val
                    })
                  }}
                  dateOut={{
                    ...raw({
                      name: 'dateOut',
                      compare: (initVal, val) => initVal !== val
                    })
                  }}
                  disabled={isFormDisabled}
                  hospital={{ ...text('hospital') }}
                  key={mod.id}
                  module={mod}
                  timeSpent={{ ...number('timeSpent') }}
                />
              );
            case MODULES.BENEFICIARY.code:
              return (
                <Beneficiary
                  disabled={isFormDisabled}
                  firstName={{ ...text('firstName') }}
                  formState={formState}
                  handleResetDeclaring={handleResetDeclaring}
                  handleToggleAddBeneficiary={handleToggleAddBeneficiary}
                  isDeclaringNewBeneficiary={isDeclaringNewBeneficiary}
                  key={mod.id}
                  lastName={{ ...text('lastName') }}
                  module={mod}
                  radio={radio}
                  userRightHolder={userRightHolder}
                />
              );
            case MODULES.SESSION.code:
              return (
                <Session
                  allowance={allowance}
                  disabled={isFormDisabled}
                  handleSessionDateChange={handleSessionDateChange}
                  key={mod.id}
                  module={mod}
                  sessionNumber={sessionNumber}
                  sessions={sessions}
                  onChange={handleChangeSessionNumber}
                />
              );
            default:
              return null;
            }
          })}
          {/* END OPTIONALS MODULES */}

          <Grid container>
            <Grid item sm={6} xs={12}>
              <Documents
                disabled={isFormDisabled}
                documents={documents}
                documentsEmptyError={documentsEmptyError}
                handleAddFile={handleAddFile}
                handleDeleteFile={handleDeleteFile}
                handleFileAdded={handleFileAdded}
                isOptional={allowance.attachments === '<p> </p>'}
                placeholder={allowance.attachments}
              />
            </Grid>
          </Grid>

          <AdditionalData
            {...text('memberComment')}
            allowance={allowance}
            disabled={isFormDisabled}
            isOptional={allowance.furtherInformation === '<p> </p>'}
            text={text}
          />

          {!isFormDisabled && (
            <FormCheckboxContainer>
              <FormControlLabel
                control={<Checkbox required />}
                label={translate('forms.certifyData')}
                {...checkbox('certifyData')}
              />
              <FormControlLabel
                control={<Checkbox required />}
                label={translate('forms.certifyPersonalData')}
                {...checkbox('certifyPersonalData')}
              />
            </FormCheckboxContainer>
          )}

          <Grid container spacing={2}>
            <Grid item>
              <Grid alignItems="center" container direction="column">
                <Grid item>
                  <Button
                    aria-label={translate('button.sendAllowance')}
                    color="primary"
                    disabled={isFormDisabled || (beneficiaryModule && !formState.values.rightHolderId)}
                    type="submit"
                  >
                    {translate('button.sendAllowance')}
                  </Button>
                </Grid>
                <Grid item>
                  {(beneficiaryModule && !formState.values.rightHolderId) && (
                    <Typography color="error" gutterBottom variant="caption">
                      {translate('errors.noBeneficiarySelected')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Link to={ROUTES.ALLOWANCE_SOCIAL_MAIN}>
                <Button
                  aria-label={translate('button.cancel')}
                  variant="text"
                >
                  {translate('button.cancel')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </form>
      </AllowanceSocialDetailContainer>
    </Wrapper>
  );
});

export default AllowanceSocialDetail;