import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-regular-svg-icons';

import { translate } from 'utils';
import { AllowanceHelper } from 'utils/helpers';
import { useStores } from 'hooks';
import TagList from '../TagList/TagList';

const StyledCardAllowance = styled.article`
  h2 {
    text-transform: uppercase;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

`;

const Image = styled.div`
  width: 100%;
  min-height: 180px;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  border: 1px solid var(--grey-lighter);
  background: url(${props => props.background}) no-repeat center;
  background-size: 100%;
  transition: all var(--transitionTime);

  ${StyledLink}:hover & {
    background-size: 108%;
  }

  @media (max-width: 768px) {
    min-height: 140px;
  }
`;

const CardAllowance = ({
  id, name, thumbnail, type, category, subcategory, location, tags
}) => {
  const { allowanceStore } = useStores();
  const { allowanceCategoryMapper } = allowanceStore;
  const url = AllowanceHelper.getAllowanceDetailUrl(type, category, allowanceCategoryMapper, id);

  if (url) {
    return (
      <StyledCardAllowance id={id}>
        <StyledLink to={url}>
          <Image background={(thumbnail && thumbnail.url) || '/assets/images/cover.png'} />
          <Typography align="center" component="h2">
            {name}
          </Typography>
          {subcategory && (
            <Typography align="center" component="legend">
              {translate(`subcategory.${subcategory}`)}
            </Typography>
          )}
          {location && (
            <Typography align="center" component="legend">
              <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '0.8rem' }} />
              {location}
            </Typography>
          )}
          <TagList tags={tags} />
        </StyledLink>
      </StyledCardAllowance>
    );
  }

  return <></>;
};

CardAllowance.propTypes = {
  id: PropTypes.number.isRequired,
  category: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string
  }),
  type: PropTypes.shape({}),
  subcategory: PropTypes.string,
  location: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string)
};

CardAllowance.defaultProps = {
  category: null,
  location: '',
  subcategory: '',
  tags: [],
  thumbnail: null,
  type: null
};

export default CardAllowance;