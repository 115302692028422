import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getRightHolderList = () => RequestHelper.GET(API_ROUTES.RIGHT_HOLDER());

const getRightHolderDetail = id => RequestHelper.GET(API_ROUTES.RIGHT_HOLDER_DETAIL(id));

// POST
const createRightHolder = rightHolder => RequestHelper.POST(API_ROUTES.RIGHT_HOLDER(), rightHolder);

// PUT
const updateRightHolder = (id, rightHolder) => RequestHelper.PUT(API_ROUTES.RIGHT_HOLDER_DETAIL(id), rightHolder);

// DELETE
const deleteRightHolder = id => RequestHelper.DELETE(API_ROUTES.RIGHT_HOLDER_DETAIL(id));

const RightHolderService = {
  getRightHolderList,
  getRightHolderDetail,
  createRightHolder,
  updateRightHolder,
  deleteRightHolder
};

export default RightHolderService;