import React, {
  Suspense, useCallback, useEffect, useState
} from 'react';
import {
  IconProfile, PageTitle, SkeletonUserProfile, SkeletonUserProfileCard, Wrapper
} from 'components';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { usePrivileges, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SUBSCRIPTION_STATUS } from 'utils/constants';
import {
  faBooks, faKey, faUser, faUsersClass, faCircle, faAddressCard
} from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import {
  Person, Membership, RightHolder, Subscriptions, ModifyPassword
} from './UserProfileTabs';

export const TabContainer = styled.section`
  margin-top: 2rem;

  .MuiCardContent-root > .MuiGrid-container > .MuiGrid-item {
    margin-bottom: 0.8rem;
  }
`;

const UserProfileContainer = styled.section`
  margin-top: -4rem;

  h1 {
    margin-top: 1rem !important;
  }
`;

const SubscriptionStatusAlertDot = styled(FontAwesomeIcon)`
  position: relative;
  top: 12px;
  left: 6px;

  &[data-status=expired]{
    color: var(--primary-color);
  }

  &[data-status=closeToExpired]{
    color: var(--tertiary-color);
  }
`;

const UserProfile = observer(() => {
  const { userStore } = useStores();
  const { state } = useLocation();
  const {
    isLoading, userConnected, subscriptionsStatus, isMembership
  } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const [subscriptionStatusAlert, setSubscriptionStatusAlert] = useState(null);

  const [tabSelected, setTabSelected] = useState(0);

  const isRightHolderVisible = usePrivileges(['EspaceAdherentIndividuAyantDroit']);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (state && state.tab) {
      setTabSelected(state.tab);
    }
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);

    !userConnected && userStore.getProfile()
      .catch(() => enqueueSnackbar(translate('errors.failedUserProfile'), { variant: 'error' }));

    if (subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.EXPIRED)) {
      setSubscriptionStatusAlert(SUBSCRIPTION_STATUS.EXPIRED);
    } else if (subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.CLOSE_TO_EXPIRED)) {
      setSubscriptionStatusAlert(SUBSCRIPTION_STATUS.CLOSE_TO_EXPIRED);
    }
  }, [userStore, userConnected, enqueueSnackbar, subscriptionsStatus]);

  const handleChangeTab = useCallback((_, tab) => setTabSelected(tab), []);

  const getTabsContent = useCallback(() => {
    const tabs = [];
    tabs.push(<Person />);
    if (isMembership) {
      tabs.push(<Membership />);
    }

    if (isRightHolderVisible) {
      tabs.push(<RightHolder />);
    }

    tabs.push(<Subscriptions />);
    tabs.push(<ModifyPassword />);
    return tabs;
  }, [isMembership, isRightHolderVisible]);

  if (isLoading) {
    return (
      <Wrapper>
        <SkeletonUserProfile />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <UserProfileContainer>
        <header>
          <Grid alignItems="center" container direction="column" justify="center">
            <IconProfile
              alt={userConnected?.login}
              src={userConnected?.image}
              width="120px"
            />
            <PageTitle noTranslate title={userConnected?.login} />
          </Grid>
        </header>

        <Tabs
          centered
          indicatorColor="secondary"
          textColor="secondary"
          value={tabSelected}
          variant="fullWidth"
          onChange={handleChangeTab}
        >
          <Tab
            icon={<FontAwesomeIcon icon={faUser} size="lg" />}
            label={translate(isMembership ? 'common.withAccount' : 'common.withoutAccount')}
          />
          {isMembership && (
            <Tab
              icon={<FontAwesomeIcon icon={faAddressCard} size="lg" />}
              label={translate('common.membership')}
            />
          )}
          {isRightHolderVisible && (
            <Tab
              icon={<FontAwesomeIcon icon={faUsersClass} size="lg" />}
              label={translate('pageUserProfile.tabRightHolder')}
            />
          )}
          <Tab
            icon={(
              <>
                {(isMembership ? null : subscriptionStatusAlert)
                && <SubscriptionStatusAlertDot data-status={subscriptionStatusAlert} icon={faCircle} size="xs" />}
                <FontAwesomeIcon icon={faBooks} size="lg" />
              </>
            )}
            label={translate('pageUserProfile.tabSubscription')}
          />
          <Tab
            icon={<FontAwesomeIcon icon={faKey} size="lg" />}
            label={translate('pageUserProfile.tabPassword')}
          />
        </Tabs>

        <Suspense fallback={SkeletonUserProfileCard()}>
          {
            getTabsContent()[tabSelected]
          }
        </Suspense>
      </UserProfileContainer>
    </Wrapper>
  );
});

export default UserProfile;