/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card, CardContent, Checkbox, FormControlLabel, FormHelperText, Grid, TextField, Typography
} from '@material-ui/core';
import { TabContainer } from 'components/UserProfile/UserProfile';
import {
  faClipboardUser, faMoneyCheckEditAlt, faUserEdit, faUserTie
} from '@fortawesome/pro-regular-svg-icons';
import {
  CustomCardTitle, FormAddress, FormInputFile, FormSelect, InfoLine
} from 'components';
import { observer } from 'mobx-react-lite';
import { useFormState } from 'react-use-form-state';
import { DocumentHelper, FormHelper, translate } from 'utils';
import { useSnackbar } from 'notistack';
import { useStores } from 'hooks';
import { MILITARY_GRADE } from 'utils/constants';
import { SubscriptionHelper } from 'utils/helpers';

const ibantools = require('ibantools');

const Person = observer(() => {
  const { userStore } = useStores();
  const { userConnected, subscriptionsStatus, isMembership } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const [ibanFile, setIbanFile] = useState(null);
  const [handicapedFile, setHandicapedFile] = useState(null);
  const [taxNoticeFile, setTaxNoticeFile] = useState(null);
  const [ibanError, setIbanError] = useState(
    userConnected.iban && userConnected.iban.length < 27 ? translate('errors.ibanTooSmall') : null
  );
  const [bicError, setBicError] = useState(
    userConnected.bic && userConnected.bic.length < 8 ? translate('errors.bicTooSmall') : null
  );

  const [formState, {
    text, tel, email, checkbox, raw
  }] = useFormState({
    ...userConnected,
    ...userConnected.address,
    militaryGrade: MILITARY_GRADE.find(mg => mg.label === userConnected.militaryGrade),
    country: FormHelper.getDefaultCountry(userConnected.address)
  });

  useEffect(() => {
    userConnected.ribFileName && setIbanFile(FormHelper.getDefaultDocument(userConnected.ribFileName));
    userConnected.mdphFileName && setHandicapedFile(FormHelper.getDefaultDocument(userConnected.mdphFileName));
    userConnected.taxNoticeFileName && setTaxNoticeFile(FormHelper.getDefaultDocument(userConnected.taxNoticeFileName));
  }, [userConnected]);

  const handleUpdateProfile = useCallback(e => {
    e.preventDefault();

    const profileUpdated = {
      ...formState.values,
      militaryGrade: formState.values.militaryGrade.value,
      address: FormHelper.getFormattedAddress(formState.values),
      // taxNoticeFile: documentsTaxes.map(doc => DocumentHelper.getDocumentWithoutBase64(doc)),
      taxNoticeFile: (taxNoticeFile && !!taxNoticeFile.content && DocumentHelper.getDocumentWithoutBase64(taxNoticeFile))
        || null,
      mdphFile: (handicapedFile && !!handicapedFile.content && DocumentHelper.getDocumentWithoutBase64(handicapedFile)) || null,
      ribFile: (ibanFile && !!ibanFile.content && DocumentHelper.getDocumentWithoutBase64(ibanFile)) || null
    };

    userStore.updateProfile(profileUpdated)
      .then(() => {
        enqueueSnackbar(translate('confirms.profileUpdated'), { variant: 'success' });

        const filteredSubscriptions = SubscriptionHelper.filterCurrentSubscriptions(userConnected.allo18Subsciptions, subscriptionsStatus);
        filteredSubscriptions.forEach(subscription => {
          const updatedSubscription = {
            ...subscription,
            address: {
              codePostal: profileUpdated.address.postalCode,
              ligne1: profileUpdated.address.address1,
              ligne2: profileUpdated.address.address2,
              ligne3: profileUpdated.address.address3,
              pays: profileUpdated.address.country,
              ville: profileUpdated.address.city
            }
          };

          userStore.updateSubscriptionAddress(updatedSubscription).then(() => {
            enqueueSnackbar(translate('confirms.subscriptionsUpdated'), { variant: 'success' });
          }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' });
          });
        });
      })
      .catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
  }, [enqueueSnackbar, handicapedFile, ibanFile, taxNoticeFile, formState.values, userStore]);

  const checkBankDetailsError = (value, type) => {
    if (type === 'iban') {
      if (value.length < 27) {
        setIbanError(translate('errors.ibanTooSmall'));
      } else if (!ibantools.isValidIBAN(value)) {
        console.log(ibantools.isValidIBAN(value));
        setIbanError(translate('errors.ibanInvalide'));
      } else {
        setIbanError(null);
      }
    }
    if (type === 'bic') {
      if (value.length < 8) {
        setBicError(translate('errors.bicTooSmall'));
      } else if (!ibantools.isValidBIC(value)) {
        console.log(ibantools.isValidBIC(value));
        setBicError(translate('errors.bicWrongFormat'));
      } else {
        setBicError(null);
      }
    }
  };

  const handleBankDetailsChange = (event, type) => {
    const value = event.target.value.toUpperCase().replace(' ', '');
    checkBankDetailsError(value, type);
    formState.setField(type, value);
  };

  return (
    <TabContainer>
      <form onSubmit={handleUpdateProfile}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Card>
                  <CustomCardTitle
                    icon={faClipboardUser}
                    title="pageUserProfile.person.profile"
                  />
                  <CardContent>
                    <InfoLine
                      info={userConnected.memberCode}
                      label={userConnected.profile === 'withAccount' ? 'common.withAccountCode' : 'common.withoutAccountCode'}
                    />
                    <InfoLine
                      info={userConnected.civility}
                      label="common.civility"
                    />
                    <InfoLine
                      info={userConnected.lastName}
                      label="common.lastName"
                    />
                    <InfoLine
                      info={userConnected.firstName}
                      label="common.firstName"
                    />
                    {isMembership && (
                      <>
                        <InfoLine
                          info={userConnected.birthdate}
                          label="common.birthdate"
                        />
                        <InfoLine
                          info={userConnected.familyStatus}
                          label="common.familyStatus"
                        />
                        <InfoLine
                          info={userConnected.trancheTarifaire}
                          label="common.trancheTarifaire"
                        />
                        <InfoLine
                          info={(
                            userConnected.isContributionUpToDate ? translate('common.yes') : translate('common.no')
                          )}
                          label="common.isContributionUpToDate"
                        />
                      </>
                    )}

                    {(isMembership && !userConnected.taxNoticeFileName) && (
                      <FormControlLabel
                        control={(
                          <Checkbox
                            name="ignoreNoTaxNotice"
                            {...checkbox('ignoreNoTaxNotice')}
                          />
                        )}
                        label={`${translate('forms.ignoreNoTaxNotice')}`}
                      />
                    )}

                    {isMembership && (
                      <Grid alignItems="flex-start" container direction="column" spacing={2}>
                        <Grid item style={{ width: '100%' }}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                name="isHandicaped"
                                {...checkbox('isHandicaped')}
                              />
                            )}
                            label={translate('forms.handicaped')}
                          />

                          {formState.values.isHandicaped && (
                            <FormInputFile
                              document={handicapedFile}
                              handleFileAdded={docAdded => setHandicapedFile(docAdded)}
                              id="handicapedFile"
                              label="forms.handicapedFile"
                            />
                          )}
                        </Grid>
                        <Grid item style={{ width: '100%' }}>
                          <FormInputFile
                            document={taxNoticeFile}
                            handleFileAdded={docAdded => setTaxNoticeFile(docAdded)}
                            id="taxNoticeFile"
                            label="common.fileTaxes"
                          />

                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item>
                <Card>
                  <CustomCardTitle
                    icon={faUserEdit}
                    title="pageUserProfile.person.personalData"
                  />
                  <CardContent>
                    <Grid container direction="column">
                      <Grid item>
                        <FormAddress formState={formState} raw={raw} text={text} />
                      </Grid>
                      <Grid container item spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            fullWidth
                            id="phoneHome"
                            label={translate('common.phoneHome')}
                            name="phoneHome"
                            {...tel('phoneHome')}
                          />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <TextField
                            fullWidth
                            id="phoneMobile"
                            label={translate('common.phoneMobile')}
                            name="phoneMobile"
                            {...tel('phoneMobile')}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          id="email"
                          label={translate('common.emailPerso')}
                          name="email"
                          {...email('email')}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Card>
                  <CustomCardTitle
                    icon={faMoneyCheckEditAlt}
                    title="pageUserProfile.person.bankData"
                  />
                  <CardContent>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography component="legend">
                              {translate('pageUserProfile.person.bankInfoInstructions1')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography component="legend">
                              {translate('pageUserProfile.person.bankInfoInstructions2')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography component="legend">
                              {translate('pageUserProfile.person.bankInfoInstructions3')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          id="iban"
                          inputProps={{
                            maxLength: 34
                          }}
                          label={translate('common.iban')}
                          name="iban"
                          value={formState.values.iban}
                          onChange={event => handleBankDetailsChange(event, 'iban')}
                        />
                        {ibanError && <FormHelperText error>{ibanError}</FormHelperText>}
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          id="bic"
                          inputProps={{
                            maxLength: 11
                          }}
                          label={translate('common.bic')}
                          name="bic"
                          value={formState.values.bic}
                          onChange={event => handleBankDetailsChange(event, 'bic')}
                        />
                        {bicError && <FormHelperText error>{bicError}</FormHelperText>}
                      </Grid>
                      <Grid item>
                        <FormInputFile
                          document={ibanFile}
                          handleFileAdded={docAdded => setIbanFile(docAdded)}
                          id="ibanFile"
                          label="forms.uploadRIB"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                {isMembership && (
                  <Grid item>
                    <Card>
                      <CustomCardTitle
                        icon={faUserTie}
                        title="pageUserProfile.person.professionalData"
                      />
                      <CardContent>
                        <Grid container direction="column">
                          <Grid item>
                            <InfoLine
                              info={userConnected.incorporationNumber}
                              label={translate('common.incorporationNumber')}
                            />
                            <InfoLine
                              info={userConnected.registerDate}
                              label="common.registerDate"
                            />
                            <InfoLine
                              info={userConnected.rdcDate}
                              label="common.rdcDate"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  {...checkbox('bsppActive')}
                                  disabled
                                  name="bsppActive"
                                />
                              )}
                              label={translate('common.bsppActive')}
                            />
                          </Grid>
                          <Grid item>
                            <FormSelect
                              id="militaryGrade"
                              label="common.militaryGrade"
                              name="militaryGrade"
                              options={MILITARY_GRADE}
                              {...raw('militaryGrade')}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              fullWidth
                              id="company"
                              label={translate('common.company')}
                              name="company"
                              {...text('company')}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Button
          aria-label={translate('button.save')}
          color="primary"
          disabled={ibanError || bicError}
          style={{ marginTop: '2rem' }}
          type="submit"
        >
          {translate('button.save')}
        </Button>
      </form>
    </TabContainer>
  );
});

export default Person;