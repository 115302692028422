import styled from 'styled-components';

const AllowanceLeisureContainer = styled.div`
    /* STEPPER CUSTOM STYLES */
  .MuiStepButton-root {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 2rem;
    padding-left: 4rem;
    background-color: var(--info-color);
  }
  .MuiStepLabel-iconContainer {
    position: absolute;
    left: -2.5rem;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 0;
    color: var(--white);
    background-color: var(--primary-color);
    border-radius: 50%;
  }
  .MuiStepLabel-label {
    font-family: 'ArimoBold';
    font-size: 1.6rem !important;
  }
  .MuiStepConnector-line {
    border: none;
  }

  /* SELECT CUSTOM STYLES */
  .MuiOutlinedInput-root {
    margin-top: 0.5rem;
  }
  .MuiOutlinedInput-input {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
  option {
    padding: 0.5rem 0;
  }

  @media (max-width: 768px) {
    .MuiStepper-root {
      padding: 8px;
    }
    .MuiStepContent-root {
      padding: 0;
      margin-left: 0;
      border: none;
    }
    .MuiStepLabel-iconContainer {
      left: -1rem;
    }
  }
`;

export default AllowanceLeisureContainer;