/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import { observer } from 'mobx-react-lite';

import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/sharp-regular-svg-icons';
import { useModal } from 'hooks';
import { ROUTES } from 'utils/constants';

const PageSubscriptionDescription = observer(() => {
  const showModal = useModal();
  const history = useHistory();

  const handleSubmitModal = () => {
    history.push(ROUTES.SUBSCRIPTION);
  };

  const handleProceed = () => {
    showModal({
      type: 'RGPD',
      onProceed: handleSubmitModal
    });
  };

  return (
    <Grid alignItems="center" container justify="center" spacing={2} style={{ color: 'var(--black)' }} wrap="nowrap">
      <Grid item xs={5}>
        <img alt="subscriptionDescription" src="/assets/images/bg/allo18-example.png" />
      </Grid>
      <Grid item xs={5}>
        <Grid alignItems="flex-start" container direction="column" spacing={2}>
          <Grid item>
            <Typography className="bold" component="h2" variant="h2">
              {translate('subscription.descriptionTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {translate('subscription.descriptionText')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="bold">
              {translate('subscription.descriptionQuestion')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {translate('subscription.descriptionBullet0')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ marginLeft: '10px' }}>
              <Grid container justify="space-evenly" spacing={1}>
                <Grid item>
                  &bull;
                </Grid>
                <Grid item>
                  {translate('subscription.descriptionBullet1')}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item>
            <Typography style={{ marginLeft: '10px' }}>
              <Grid container justify="space-evenly" spacing={1}>
                <Grid item>
                  &bull;
                </Grid>
                <Grid item>
                  {translate('subscription.descriptionBullet2')}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="bold">
              {translate('subscription.descriptionFinal')}
            </Typography>
          </Grid>
          <Grid item>
            <Button color="primary" style={{ minWidth: '200px' }} onClick={handleProceed}>
              <Grid container justify="center" spacing={1}>
                <Grid item>
                  <FontAwesomeIcon icon={faCartShopping} />
                </Grid>
                <Grid item>
                  {translate('subscription.proceedSubscription')}
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default PageSubscriptionDescription;