/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { isMisterflyTicket, translate } from 'utils';
import {
  ALLOWANCE_LEISURE_NATURE, PAYMENT_TYPE, STATUS_DISPLAY, STATUS_ID
} from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay, faComment, faCreditCard, faHandsHelping
} from '@fortawesome/pro-regular-svg-icons';
import { PayboxPayment } from 'components/_commons';

export const StyledStatusDisplay = styled.aside`
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: var(--secondary-color-lighter);
  border: 1px solid var(--secondary-color-light);
  border-radius: 8px;

  svg {
    margin-right: 1rem;
  }
`;

const settleApplication = (userAllowanceApplicationDetail, category, price) => {
  return new Promise(resolve => resolve({
    itemId: userAllowanceApplicationDetail.id,
    confirmParameter: `&category=${category}&allowanceId=${userAllowanceApplicationDetail.allowanceId}`,
    price: price,
  }));
}

const StatusDisplay = ({
  category, formState, isDone,
  userAllowanceApplicationDetail, allowanceNature
}) => {
  const isMisterfly = isMisterflyTicket({ allowanceName: userAllowanceApplicationDetail.allowanceName });

  const renderPayboxPayment = () => {
    if (userAllowanceApplicationDetail.status !== 2) {
      return;
    }
    if (!userAllowanceApplicationDetail.invoiceId) {
      return (
        !isMisterfly && (
        <Typography color="error">
          Vous ne pouvez pas régler cette demande de prestation. Veuillez contacter l&apos;ADOSSPP.
        </Typography>)
      );
    }
    return (
      <PayboxPayment
        getPaymentInformations={() => settleApplication(userAllowanceApplicationDetail, category, formState.values.price)}
        buttonLabel="button.pay"
        typeId={userAllowanceApplicationDetail.interEstablishmentAllowanceId
          ? PAYMENT_TYPE.INTERN.code : PAYMENT_TYPE.TICKETS.code}
      />
    );
  };

  return (
    <StyledStatusDisplay>
      <Typography component="p" gutterBottom variant="h4">
        {isMisterfly
          ? (
            translate('pageAllowanceDetail.misterflyStatusTitle')
          ) : (
            translate('pageAllowanceDetail.statusTitle')
          )}
        {!isMisterfly
          ? (
            <span style={{ color: STATUS_DISPLAY[userAllowanceApplicationDetail.status].color }}>
              <FontAwesomeIcon className="mr1" icon={STATUS_DISPLAY[userAllowanceApplicationDetail.status].icon} />
              {translate(STATUS_DISPLAY[userAllowanceApplicationDetail.status].label)}
            </span>
          ) : (
            null
          )}
      </Typography>

      <Grid container direction="column" spacing={1}>
        {!isMisterfly
          ? (
            <Grid item>
              <Typography gutterBottom>
                <strong>
                  {isDone ? (
                    translate('pageAllowanceDetail.statusDone')
                  ) : (
                    translate('pageAllowanceDetail.statusOngoing')
                  )}
                </strong>
              </Typography>
            </Grid>
          ) : (
            null
          )}

        <Grid item>
          <Typography>
            <strong>
              <FontAwesomeIcon icon={faCalendarDay} />
              {`${translate('common.creationDate')} : `}
            </strong>
            {new Date(userAllowanceApplicationDetail.creationDate).toLocaleDateString()}
          </Typography>
          {!isMisterfly
            ? (
              <Typography gutterBottom={Boolean(userAllowanceApplicationDetail.adossppCommentToMember)}>
                <strong>
                  <FontAwesomeIcon flip="horizontal" icon={faCalendarDay} />
                  {`${translate('common.lastChangeStatusDate')} : `}
                </strong>
                {new Date(userAllowanceApplicationDetail.lastChangeStatusDate).toLocaleDateString()}
              </Typography>
            ) : (
              null
            )}
        </Grid>

        {(userAllowanceApplicationDetail.endDate && userAllowanceApplicationDetail.startDate) && (
          <Grid item>
            <>
              <Typography>
                <strong>
                  <FontAwesomeIcon icon={faCalendarDay} />
                  {`${translate('common.beginDate')} : `}
                </strong>
                {new Date(userAllowanceApplicationDetail.startDate).toLocaleDateString()}
              </Typography>
              <Typography gutterBottom={Boolean(userAllowanceApplicationDetail.adossppCommentToMember)}>
                <strong>
                  <FontAwesomeIcon flip="horizontal" icon={faCalendarDay} />
                  {`${translate('common.endDate')} : `}
                </strong>
                {new Date(userAllowanceApplicationDetail.endDate).toLocaleDateString()}
              </Typography>
            </>
          </Grid>
        )}

        {userAllowanceApplicationDetail.adossppCommentToMember && (
          <Grid item>
            <Typography>
              <strong>
                <FontAwesomeIcon icon={faComment} />
                {translate('pageAllowanceDetail.adossppCommentToMember')}
              </strong>
              {userAllowanceApplicationDetail.adossppCommentToMember}
            </Typography>
          </Grid>
        )}

        {allowanceNature && (allowanceNature.id === ALLOWANCE_LEISURE_NATURE.INTERNAL.code
          || allowanceNature.id === ALLOWANCE_LEISURE_NATURE.TICKETS.code) ? (
            userAllowanceApplicationDetail.orderAmount !== null && userAllowanceApplicationDetail.status !== 3 ? (
              <Grid item>
                <Typography>
                  <strong>
                    <FontAwesomeIcon icon={faCreditCard} />
                    {translate('pageAllowanceDetail.orderAmount')}
                  </strong>
                  {`${userAllowanceApplicationDetail.orderAmount}€`}
                </Typography>
              </Grid>
            ) : null
          ) : (
            userAllowanceApplicationDetail.receivedAmount !== null && userAllowanceApplicationDetail.status !== 3 && !isMisterflyTicket({ allowanceName: userAllowanceApplicationDetail.allowanceName }) ? (
              <Grid item>
                <Typography>
                  <strong>
                    <FontAwesomeIcon icon={faHandsHelping} />
                    {translate('pageAllowanceDetail.receivedAmount')}
                  </strong>
                  {`${userAllowanceApplicationDetail.receivedAmount}€`}
                </Typography>
              </Grid>
            ) : null
)}
        {(!isMisterfly && userAllowanceApplicationDetail.allowanceType === 2 && userAllowanceApplicationDetail.status !== STATUS_ID.SEND) && (
          <Grid item>
            <Typography>
              {translate('pageAllowanceDetail.cancelInstructions')}
            </Typography>
          </Grid>
        )}
        <Grid item>
          {renderPayboxPayment()}
        </Grid>
      </Grid>
    </StyledStatusDisplay>
  );
};

StatusDisplay.propTypes = {
  allowanceNature: PropTypes.shape({}),
  isDone: PropTypes.bool.isRequired,
  userAllowanceApplicationDetail: PropTypes.shape({}).isRequired
};

StatusDisplay.defaultProps = {
  allowanceNature: null
};

export const ActivityDisplay = ({ message }) => (
  <StyledStatusDisplay>
    <Typography>
      {translate(message)}
    </Typography>
  </StyledStatusDisplay>
);

export default StatusDisplay;