/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import {
  Grid, makeStyles, Select, TextField
} from '@material-ui/core';

import { translate } from 'utils';
import { UserService } from 'services';

const useStyles = makeStyles(() => ({
  container: {
    color: 'var(--black)'
  }
}));

const PersonalInfos = ({ text, select, formState }) => {
  const classes = useStyles();
  const [civilities, setCivilites] = useState([]);
  const [isLoadingcivilities, setIsLoadingCivilites] = useState(true);

  useEffect(() => {
    UserService.getCivilities().then(resp => {
      setCivilites(resp.data);
    }).finally(() => setIsLoadingCivilites(false));
  }, []);

  return (
    <Grid className={classes.container} container spacing={4} wrap="nowrap">
      <Grid item>
        {!isLoadingcivilities && (
          <Select
            color="secondary"
            error={formState.errors.civility && formState.errors.civility !== null}
            fullWidth
            helperText={formState.errors.civility}
            labelId="civility"
            native
            required
            variant="outlined"
            {...select('civility')}
          >
            {civilities.map(civility => (
              <option key={civility} value={civility.id}>
                {civility.nomLong}
              </option>
            ))}
          </Select>
        )}
      </Grid>
      <Grid item xs>
        <TextField
          error={formState.errors.lastName && formState.errors.lastName !== null}
          fullWidth
          helperText={formState.errors.lastName}
          id="lastName"
          label={translate('common.lastName')}
          name="lastName"
          required
          variant="outlined"
          {...text('lastName')}
        />
      </Grid>
      <Grid item xs>
        <TextField
          error={formState.errors.firstName && formState.errors.firstName !== null}
          fullWidth
          helperText={formState.errors.firstName}
          id="firstName"
          label={translate('common.firstName')}
          name="firstName"
          required
          variant="outlined"
          {...text('firstName')}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfos;