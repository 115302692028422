import React, { useEffect } from 'react';
import {
  Wrapper, PageTitle, ConfirmRedirect
} from 'components';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import {
  Grid
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

const PageConfirmLeisure = observer(() => {
  const { state } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Grid alignItems="center" container direction="column" spacing={2}>
        <Grid item>
          <img
            alt={translate('forms.leisureConfirm')}
            src="/assets/images/confirmation-leisure.png"
            style={{ marginTop: '4rem' }}
          />
        </Grid>

        <PageTitle title={(state && state.ticketing) ? 'forms.leisureConfirmTicketing' : 'forms.leisureConfirm'} />

        {/* <Grid item xs>
          <Typography component="h2" variant="h5">
            {translate('forms.leisureConfirmEmail')}
          </Typography>
        </Grid>

        <FormCheckboxContainer fullWidth>
          <Typography className="bold">Lire attentivement</Typography>
          <br />
          <Typography className="bold">En cas d&apos;annulation, les modalités suivantes s&apos;appliqueront :</Typography>
          <List>
            <ListItem>
              Annulation à plus de 60 jours avant le début du séjour : la totalité de l’acompte de confirmation reste dû.
            </ListItem>
            <ListItem>
              Annulation entre 60 et 31 jours avant le début du séjour : 50 % du coût total du séjour reste dû.
            </ListItem>
            <ListItem>
              Annulation entre 30 et 11 jours avant le début du séjour : 70 % du coût total du séjour reste dû.
            </ListItem>
            <ListItem>
              Annulation à moins de 11 jours du début du séjour : 100 % du coût total du séjour sera facturé.
            </ListItem>
          </List>
       </FormCheckboxContainer> */}

        <ConfirmRedirect category={1} />
      </Grid>
    </Wrapper>
  );
});

export default PageConfirmLeisure;