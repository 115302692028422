/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Checkbox, Grid, makeStyles
} from '@material-ui/core';

import { translate } from 'utils';

import SubscriptionConditionsLink from '../../Files/20230602_CGV_Allo_18.docx'

const useStyles = makeStyles(() => ({
  conditionsContainer: {
    cursor: 'pointer',
    border: '2px solid var(--grey)',
    borderRadius: '5px',
    padding: '10px 15px',
    '& .MuiTypography-root': {
      fontWeight: 'bold'

    },
    '&:hover': {
      borderColor: 'var(--secondary-color)',
      backgroundColor: 'var(--secondary-color-lighter)'
    }
  }
}));

const Conditions = ({ formState, checkbox }) => {
  const classes = useStyles();

  const handleSelectGrid = () => {
    formState.setField('conditionsAccepted', !formState.values.conditionsAccepted);
  };

  return (
    <Grid alignItems="center" className={classes.conditionsContainer} container onClick={handleSelectGrid}>
      <Grid item>
        <Checkbox {...checkbox('conditionsAccepted')} />
      </Grid>
      <Grid item style={{ marginRight: '5px' }}>
        {translate('subscription.conditions')}
      </Grid>
      <Grid item style={{ textDecoration: 'underline', textDecorationColor: 'var(--secondary-color)' }}>
        <a href={SubscriptionConditionsLink} rel="noopener noreferrer" target="_blank">
          {translate('subscription.conditionsLink')}
        </a>
      </Grid>
    </Grid>
  );
};

export default Conditions;