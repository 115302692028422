import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography
} from '@material-ui/core';
import { FormSectionTitle } from 'components';
import { translate } from 'utils';

const Beneficiary = ({
  formState, userRightHolder, disabled,
  isDeclaringNewBeneficiary, handleToggleAddBeneficiary, handleResetDeclaring,
  lastName, firstName, radio, module
}) => (
  <Grid container direction="column">
    <FormSectionTitle title={module.name} />

    <Typography gutterBottom>
      Sélectionnez l&apos;un de vos bénéficiaires déjà déclaré dans la liste ci-dessous.
      <br />
      La demande de déclaration sera envoyée automatiquement.
    </Typography>

    <div className="mb1">
      {userRightHolder.length > 0
        ? (
          <FormControl component="fieldset" error={formState.errors.rightHolderId} onChange={handleResetDeclaring}>
            <RadioGroup aria-label={translate('forms.beneficiaryInfo')} name="rightHolderId">
              {userRightHolder.map(rightHolder => (
                <FormControlLabel
                  control={<Radio />}
                  disabled={disabled}
                  key={rightHolder.id}
                  label={`${rightHolder.firstName || ''} ${rightHolder.lastName || ''}`}
                  {...radio('rightHolderId', rightHolder.id)}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
        : (
          <Typography gutterBottom>
            {translate('common.noRightHolderValidated')}
          </Typography>
        )}
    </div>
  </Grid>
);

Beneficiary.propTypes = {
  disabled: PropTypes.bool,
  formState: PropTypes.shape({}).isRequired,
  isDeclaringNewBeneficiary: PropTypes.bool.isRequired,
  handleToggleAddBeneficiary: PropTypes.func.isRequired,
  handleResetDeclaring: PropTypes.func.isRequired,
  lastName: PropTypes.shape({}).isRequired,
  firstName: PropTypes.shape({}).isRequired,
  radio: PropTypes.func.isRequired,
  userRightHolder: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

Beneficiary.defaultProps = {
  disabled: false
};

export default Beneficiary;