const MAX_FILE_SIZE = 2; // in Mo

const handleFileUpload = event => new Promise((resolve, reject) => {
  const allFiles = event.target.files;
  if (allFiles) {
    const readerOnLoad = (readerLoaded, currentFileLoaded) => {
      const fileUploaded = {
        name: currentFileLoaded.name,
        content: readerLoaded.result
      };

      resolve(fileUploaded);
    };

    const reader = new FileReader();
    const currentFile = allFiles[0];
    const filesize = ((currentFile.size / 1024) / 1024).toFixed(2); // in Mo

    if (filesize <= MAX_FILE_SIZE) {
      reader.readAsDataURL(currentFile);
      reader.onload = () => readerOnLoad(reader, currentFile);
    } else {
      reject(Error('errors.documentTooBig'));
    }
  }
});

const getDocumentWithBase64 = base64Content => (
  `data:image/png;base64,${base64Content}`
);

const getDocumentWithoutBase64 = document => {
  if (document.content) {
    return {
      ...document,
      content: document.content.indexOf('base64') ? document.content.split(',')[1] : (document.content || null)
    };
  }
  return document;
};

const getPDFWithBase64 = base64Content => (
  `data:application/pdf;base64,${base64Content}`
);

const getZipWithBase64 = base64Content => (
  `data:application/zip;base64,${base64Content}`
);

const getFormatedDocument = document => {
  if (document) {
    return {
      name: document.name,
      content: ''
    };
  }

  return null;
};

export const DocumentHelper = {
  handleFileUpload,
  getDocumentWithBase64,
  getDocumentWithoutBase64,
  getPDFWithBase64,
  getZipWithBase64,
  getFormatedDocument
};