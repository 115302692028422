import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getNewsList = () => RequestHelper.GET(API_ROUTES.NEWS_LIST());

const getNewsDetail = id => RequestHelper.GET(API_ROUTES.NEWS_DETAIL(id));

const NewsService = {
  getNewsList,
  getNewsDetail
};

export default NewsService;