import React, { useEffect, useState } from 'react';
import {
  FormCheckboxContainer, FormSectionTitle, TextLine
} from 'components';
import styled from 'styled-components';
import shortid from 'shortid';
import {
  Button, Checkbox, FormControlLabel, Grid, Step, StepButton, StepContent, Stepper, TextField, Typography
} from '@material-ui/core';
import {
  faCalendarAlt, faInfoSquare, faUsers
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHelper, isMisterflyTicket, translate } from 'utils';
import { useStores } from 'hooks';
import { AllowanceLeisureContainer } from 'components/_commons';
import { ALLOWANCE_LEISURE_PAYMENT_TYPE } from 'utils/constants';

const RightHolderContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  border: solid var(--grey) 2px;
  padding: 10px;
  text-align: center;

  &[data-selected="true"]{
    border-color: var(--secondary-color-light);
    box-shadow: 0 0 10px 2px var(--secondary-color-dark);
  }

  &[data-disabled="true"]{
    border-color: var(--grey-dark);
    background-color: var(--grey);
  }
`;

const DoubleBooking = ({
  allowance, add, formState, selectedRightHolders, setSelectedRightHolders, checkbox, text, userAllowanceApplicationDetail, isFormDisabled
}) => {
  const { userStore } = useStores();
  const { userConnected, userRightHolder } = userStore;
  const [tTError, setTTError] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [toggleStep, setToggleStep] = useState(false);
  const [isMisterfly, setIsMisterflyTicket] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsMisterflyTicket(isMisterflyTicket({ allowanceName: allowance.name }));
  }, []);

  useEffect(() => {
    if (allowance.amountCalculatingMeans.id !== ALLOWANCE_LEISURE_PAYMENT_TYPE.UNIQUE.code) {
      allowance.grantAmounts.forEach(grantAmount => {
        if (grantAmount.tariffSectionCode === userConnected.trancheTarifaire) {
          setTTError(false);
        }
      });
    } else {
      setTTError(false);
    }
  }, [allowance, userConnected]);

  const handleSelectRightHolder = rH => {
    if (!isFormDisabled) {
      if (selectedRightHolders.includes(rH.id)) {
        setSelectedRightHolders(selectedRightHolders.filter(item => item !== rH.id));
      } else {
        add(rH.id);
      }
    }
  };

  const handleAllowanceLeisureStepClick = (step) => {
    setToggleStep(!toggleStep);
    if (step === 0) {
      toggleStep ? setActiveStep(0) : setActiveStep(null);
    }
  };

  useEffect(() => {
    if (userAllowanceApplicationDetail) {
      setActiveStep(0);
      formState.setField('certifyData', true);
      formState.setField('certifyPersonalData', true);
    }
  }, [userAllowanceApplicationDetail, formState]);

  return (
    <AllowanceLeisureContainer>
      {!isMisterfly
        ? (
          <TextLine className="bold">Pour procéder à la réservation, réaliser dans l’ordre les étapes ci-dessous : </TextLine>
        ) : (
          null
        )}

      <Stepper activeStep={activeStep} orientation="vertical">
        {!isMisterfly
          ? (
            <Step>
              <StepButton
                icon={<FontAwesomeIcon icon={faCalendarAlt} size="2x" />}
                onClick={() => setActiveStep(0)}
              >
                {allowance.step1Title}
              </StepButton>

              <StepContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography
                      dangerouslySetInnerHTML={
                        { __html: allowance.step1Description.replace(/\n/g, '<br />').replace('<p> </p>', '') }
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Typography>
                          {translate('common.externalRedirectionLink')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <a href={FormHelper.getExternalURL(allowance.coordinate.externalRedirectionLink)} rel="noreferrer noopener" target="_blank">
                          {allowance.coordinate.externalRedirectionLink}
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button color="secondary" disabled={tTError} onClick={() => setActiveStep(1)}>
                      {translate('button.putOption')}
                    </Button>
                  </Grid>
                  <Grid item>
                    {tTError && (
                      <Typography color="error">
                        Pas de prix défini pour votre tranche tarifaire, veuillez contacter l&apos;ADOSSPP
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </StepContent>
            </Step>
          ) : (
            null
          )}
        <Step>
          <StepButton
            icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
            onClick={() => { !isMisterfly ? setActiveStep(1) : handleAllowanceLeisureStepClick(0); }}
          >
            { isMisterfly ? "Demande de subvention à l'ADOSSPP" : allowance.step2Title}
          </StepButton>

          <StepContent>
            <Grid container direction="column" spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormSectionTitle title={translate('common.people')} />
                <FormControlLabel
                  control={(
                    <Checkbox
                      disabled={isFormDisabled}
                      name="isRightHolders"
                      {...checkbox('isRightHolders')}
                    />
                  )}
                  label={translate('forms.isRightHolders')}
                />
              </Grid>
              <Grid container direction="row" spacing={2}>
                {formState.values.isRightHolders && userRightHolder.map(rH => (
                  <Grid item key={shortid.generate()}>
                    <RightHolderContainer
                      data-disabled={isFormDisabled}
                      data-selected={selectedRightHolders.includes(rH.id)}
                      onClick={() => handleSelectRightHolder(rH)}
                    >
                      {`${rH.firstName} ${rH.lastName}`}
                    </RightHolderContainer>
                  </Grid>
                ))}
              </Grid>
              {!isMisterfly
                ? (
                  <Grid item>
                    <FormSectionTitle icon={faInfoSquare} title={translate('common.moreInfos')} />
                    <TextLine>
                      Afin de mieux répondre à votre demande de subvention et besoins,
                      n&apos;hésitez pas à ajouter des informations complémentaires à votre dossier.
                    </TextLine>
                    <Typography>Remarques :</Typography>
                    <TextField
                      fullWidth
                      id="memberComment"
                      label={translate('common.observations')}
                      multiline
                      name="memberComment"
                      rows={3}
                      variant="outlined"
                      {...text('memberComment')}
                    />
                  </Grid>
                ) : (
                  null
                )}
              {!isMisterfly
                ? (
                  <Grid item>
                    <FormCheckboxContainer>
                      <FormControlLabel
                        control={<Checkbox required />}
                        label={translate('forms.acceptSalesConditions')}
                        {...checkbox('certifyData')}
                      />
                      <FormControlLabel
                        control={<Checkbox required />}
                        label={translate('forms.certifyPersonalData')}
                        {...checkbox('certifyPersonalData')}
                      />
                    </FormCheckboxContainer>
                  </Grid>
                ) : (
                  null
                )}
            </Grid>
          </StepContent>
        </Step>
      </Stepper>
    </AllowanceLeisureContainer>
  );
};

export default DoubleBooking;