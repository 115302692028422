import { RequestHelper } from 'utils';
import { API_ROUTES, PAYBOX_URL } from 'utils/constants';
// POST
const getHmac = payboxHmacData => {
  const formData = new FormData();
  formData.append('PBX_TOTAL', payboxHmacData.PBX_TOTAL);
  formData.append('PBX_CMD', payboxHmacData.PBX_CMD);
  formData.append('PBX_PORTEUR', payboxHmacData.PBX_PORTEUR);
  formData.append('PBX_TIME', payboxHmacData.PBX_TIME);
  formData.append('PBX_EFFECTUE', payboxHmacData.PBX_EFFECTUE);
  formData.append('PBX_TYPE', payboxHmacData.PBX_TYPE);
  formData.append('PBX_SHOPPINGCART', payboxHmacData.PBX_SHOPPINGCART);
  formData.append('PBX_BILLING', payboxHmacData.PBX_BILLING);
  return RequestHelper.POST_WITHOUT_HEADER(API_ROUTES.PAYBOX, formData);
};

const gotoPaymentPage = hmacData => {
  const paymentPageUrl = `${PAYBOX_URL}?${hmacData.msg}&PBX_HMAC=${hmacData.hmac}`;
  window.location.href = paymentPageUrl;
};

const PayboxService = {
  getHmac,
  gotoPaymentPage
};

export default PayboxService;