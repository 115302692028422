import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const updateMisterflySecurityApi = () => RequestHelper.POST(API_ROUTES.MISTERFLY_MEMBER_TOKEN());
const getUserMisterflyHasAlreadyBenefited = () => RequestHelper.GET(API_ROUTES.USER_MISTERFLY_HAS_ALREADY_BENEFITED());

const MisterflyService = {
  updateMisterflySecurityApi,
  getUserMisterflyHasAlreadyBenefited,
};

export default MisterflyService;