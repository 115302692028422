import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import shortid from 'shortid';
import {
  BannerMessage,
  CardAllowance, FancyTitle, News, PageTitleContainer,
  SkeletonAllowanceCardList, SkeletonNewsList, SkeletonNoAllowance, Wrapper
} from 'components';
import { translate } from 'utils';
import { Button, Grid, Typography } from '@material-ui/core';
import {
  magasineFixture, messagesListFixture, newsletterFixture, MESSAGE_DISPLAY_DURATION
} from '__fixtures__';
import { usePrivileges, useStores, useAccessRights } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import {
  PATHS, SUBSCRIPTION_STATUS
} from 'utils/constants';

const Magasine = styled.article`
  text-transform: uppercase;
  border-radius: 8px;
  background: #e7ecef;

  a {
    display: block;
    padding: 2rem;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    width: 100%;
    box-shadow: -7px 8px 76px 7px rgba(56,58,54,0.3);
    transition: all var(--transitionTime);
  }

  &:hover {
    img {
      transform: scale(1.02);
    }
  }
`;

const Messages = styled.div`
  cursor: pointer;
`;

const CustomTitle = styled(Typography)`
  text-transform: initial;
  font-family: 'ArimoBold' !important;
`;

const Newsletter = styled.article`
  height: 100%;
  border-radius: 8px;
  background: #e7ecef;

  a {
    display: block;
    padding: 2rem;
    height: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    width: 100%;
    box-shadow: -7px 8px 76px 7px rgba(56,58,54,0.3);
    transition: all var(--transitionTime);
  }

  ${CustomTitle} {
    text-align: center;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }
  }
`;

const PageHome = observer(() => {
  const { newsStore, allowanceStore, userStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { allowanceList, isLoading } = allowanceStore;
  const { newsList, isLoading: isLoadingNews } = newsStore;
  const { userConnected, subscriptionsStatus, isMembership } = userStore;
  const [messages, setMessages] = useState([]);

  const magasine = magasineFixture();
  const newsletter = newsletterFixture();

  useEffect(() => {
    allowanceStore.getAllowanceList()
      .catch(() => enqueueSnackbar(translate('errors.failedAllowanceCategory'), { variant: 'error' }));

    newsStore.getNewsList()
      .catch(() => {
        enqueueSnackbar(translate('errors.failedNewsList'), { variant: 'error' });
      });
  }, [allowanceStore, newsStore, enqueueSnackbar]);

  useEffect(() => {
    setTimeout(() => setMessages(messagesListFixture), 500);
  }, []);

  const shouldDisplayMessage = useCallback(message => {
    const today = new Date();
    const dateBegin = new Date(`${message.dateBegin}/${today.getFullYear()}`);
    const dateEnd = new Date(dateBegin);
    dateEnd.setDate(dateEnd.getDate() + MESSAGE_DISPLAY_DURATION);
    return Boolean(today <= dateEnd && today >= dateBegin);
  }, []);

  return (
    <Wrapper>
      <PageTitleContainer>
        <Typography align="center" component="h1" variant="h4">
          {translate('pageHome.title')}
          {userConnected && (
            <strong>{` ${userConnected.firstName},`}</strong>
          )}
        </Typography>
      </PageTitleContainer>
      {!isMembership && (
        <>
          {subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.CLOSE_TO_EXPIRED) && (
            <BannerMessage type="warning">
              {translate('pageHome.SubscriptionCloseToExpired')}
            </BannerMessage>
          )}
          {subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.EXPIRED) && (
            <BannerMessage>
              {translate('pageHome.SubscriptionExpired')}
            </BannerMessage>
          )}
          {subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.UNPAYED) && (
            <BannerMessage type="warning">
              {translate('pageHome.SubscriptionUnPayed')}
            </BannerMessage>
          )}
        </>
      )}
      {messages?.length > 0
        && (
          <Messages onClick={() => history.push(PATHS.USER_PROFILE)}>
            {messages.map(message => (
              ((message.roles?.length === 0 || useAccessRights(message.roles)) && shouldDisplayMessage(message)) && (
                <BannerMessage key={shortid.generate()} type={message.type}>
                  {message.message}
                </BannerMessage>
              )
            ))}
          </Messages>
        )}

      {usePrivileges(['EspaceAdherentVoirActualites']) && (
        <>
          {!isLoadingNews && newsList.length > 0 && (
            <FancyTitle fancyWord="common.my" title="common.lastNews" />
          )}
          {isLoadingNews ? <SkeletonNewsList /> : (
            <Grid container direction="column" spacing={1}>
              {newsList.length > 0 && (newsList.map(news => (
                <Grid item key={news.idAlerte} xs={12}>
                  <News id={news.idAlerte} {...news} />
                </Grid>
              )))}
            </Grid>
          )}
        </>
      )}

      {usePrivileges(['EspaceAdherentVoirOffresMoment']) && (
        <>
          <FancyTitle fancyWord="common.our" title="common.lastOffers" />
          {isLoading ? <SkeletonAllowanceCardList /> : (
            <Grid container spacing={2}>
              {allowanceList.length > 0 ? (allowanceList.slice().reverse().slice(0, 3).map(allowance => (
                <Grid item key={allowance.id} sm={4} xs={12}>
                  <CardAllowance {...allowance} />
                </Grid>
              ))) : (
                <SkeletonNoAllowance />
              )}
            </Grid>
          )}
        </>
      )}

      <Grid container spacing={2} style={{ marginTop: '4rem' }}>
        {magasine && (
          <Grid item sm={6} xs={12}>
            <Magasine>
              <a
                href={magasine.link}
                rel="noreferrer noopener"
                target="_blank"
              >
                <Grid alignItems="center" container direction="column" justify="space-evenly">
                  <Grid item style={{ paddingBottom: '10px' }}>
                    <CustomTitle component="h2" variant="h3">{magasine.title}</CustomTitle>
                    <Typography component="h3" variant="h2">{magasine.type}</Typography>
                  </Grid>
                  <Grid item style={{ paddingBottom: '10px' }} xs={4}>
                    <img alt={magasine.title} src={magasine.image} />
                  </Grid>
                  <Grid item>
                    <Button aria-label={translate('button.viewMagasine')} color="primary">
                      {translate('button.viewMagasine')}
                    </Button>
                  </Grid>
                </Grid>
              </a>
            </Magasine>
          </Grid>
        )}
        {newsletter && (
          <Grid item sm={6} xs={12}>
            <Newsletter>
              <a
                href={newsletter.link}
                rel="noreferrer noopener"
                target="_blank"
              >
                <Grid
                  alignItems="center"
                  container
                  direction="column"
                  justify="space-between"
                  style={{ height: '100%' }}
                >
                  <Grid item>
                    <CustomTitle variant="h3">{newsletter.title}</CustomTitle>
                  </Grid>
                  <Grid item xs={4}>
                    <img alt={newsletter.title} src={newsletter.image} />
                  </Grid>
                  <Grid item>
                    <Button aria-label={translate('button.viewNewsletter')} color="primary">
                      {translate('button.viewNewsletter')}
                    </Button>
                  </Grid>
                </Grid>
              </a>
            </Newsletter>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
});

export default PageHome;