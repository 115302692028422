/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, TextField, Typography } from '@material-ui/core';
import { FormHelper, translate } from 'utils';
import { COUNTRIES } from 'utils/constants';
import { CitiesService } from 'services';
import { useArray } from 'hooks';
import { SubscriptionHelper } from 'utils/helpers';
import FormSelect from '../FormSelect/FormSelect';

const StyledCustomOption = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background-color: var(--grey-lighter);
  }

  img {
    margin-right: 1rem;
  }
`;

const CustomOptionCountry = ({ data, innerProps }) => (
  <StyledCustomOption {...innerProps}>
    <img
      alt={data.label}
      src={`/assets/images/flags/${data.alpha2}.png`}
      style={{ verticalAlign: 'middle', marginRight: '1rem' }}
    />
    <Typography component="span">{data.label}</Typography>
  </StyledCustomOption>
);

const FormAddress = ({
  formState, text, raw, displayPrice = false, variant = 'standard', required = false, prices = []
}) => {
  const {
    add, value: cities, clear
  } = useArray([]);
  const [selectLock, setSelectLock] = useState(false);

  const isFrance = useCallback(() => {
    const france = COUNTRIES.find(c => c.id === 250);
    if (formState.values.country && formState.values.country !== undefined) {
      return formState.values.country === france;
    }
    formState.setField('country', france);
    return true;
    // eslint-disable-next-line
  }, [formState.values.country]);

  useEffect(() => {
    if (formState.values.postalCode && formState.values.postalCode.length === 5 && isFrance()) {
      CitiesService.getCityByPostalCode(formState.values.postalCode).then(response => {
        clear();
        response.forEach((city, index) => add({ label: city.nom, value: city.nom, id: `city${index}` }));
      }).catch(() => {
        setSelectLock(true);
      });
      setSelectLock(false);
    }
  }, [formState.values.postalCode, add, clear, isFrance]);

  useEffect(() => {
    if (formState.values.city) {
      setSelectLock(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeCity = e => {
    formState.setField('city', e.value);
    setSelectLock(true);
  };

  useEffect(() => {
    formState.setField('idRevue', SubscriptionHelper.getSubscriptionRevue(prices, isFrance())?.idRevue);
  }, [formState.values.country]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs>
        <TextField
          error={formState.errors.address1 && formState.errors.address1 !== null}
          fullWidth
          id="address1"
          label={translate('common.address')}
          name="address1"
          required={
            FormHelper.isFieldRequired({ fieldsToCheck: [formState.values.postalCode, formState.values.city] }) || required
          }
          variant={variant}
          {...text('address1')}
        />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          id="address2"
          label={translate('common.address2')}
          name="address2"
          variant={variant}
          {...text('address2')}
        />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          id="address3"
          label={translate('common.address3')}
          name="address3"
          variant={variant}
          {...text('address3')}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              error={formState.errors.postalCode && formState.errors.postalCode !== null}
              fullWidth
              id="postalCode"
              inputProps={{
                maxLength: isFrance() ? 5 : null
              }}
              label={translate('common.postalCode')}
              name="postalCode"
              required={FormHelper.isFieldRequired({ fieldsToCheck: [formState.values.address1, formState.values.city] }) || required}
              variant={variant}
              {...text('postalCode')}
            />
          </Grid>
          <Grid item xs={8}>
            {(cities.length === 0 || selectLock) ? (
              <TextField
                error={formState.errors.city && formState.errors.city !== null}
                fullWidth
                id="city"
                label={translate('common.city')}
                name="city"
                required={FormHelper.isFieldRequired({ fieldsToCheck: [formState.values.address1, formState.values.postalCode] }) || required}
                variant={variant}
                {...text('city')}
              />
            )
              : (
                <FormSelect
                  id="city"
                  label="common.city"
                  name="city"
                  options={cities}
                  placeholder="common.selectCity"
                  value={formState.values.city}
                  variant={variant}
                  onChange={handleChangeCity}
                />
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid alignItems="center" container spacing={2} wrap="nowrap">
          <Grid item xs={displayPrice ? 10 : true}>
            <FormSelect
              components={{ Option: CustomOptionCountry }}
              id="country"
              label="common.country"
              name="country"
              options={COUNTRIES}
              placeholder="common.selectCountry"
              required={required}
              {...raw({
                name: 'country',
                compare(initialValue, value) {
                  return initialValue.id === value.id;
                }
              })}
            />
          </Grid>
          {displayPrice && (
            <Grid item style={{ paddingTop: '30px' }}>
              {`${translate('pageUserProfile.membership.tariff')} : ${SubscriptionHelper.getSubscriptionRevue(prices, isFrance()).prixAdherent} €`}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormAddress;