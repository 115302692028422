import { SUBSCRIPTION_STATUS } from 'utils/constants';

const getSubscriptionRevue = (prices, isFrance) => {
  const revue = `ALLO18${!isFrance ? 'ETR' : ''}`;
  return prices.find(price => price.codeRevue === revue);
};

const filterCurrentSubscriptions = (subscriptions, subscriptionsStatus) => {
  return subscriptions.filter(sub => {
    const status = subscriptionsStatus.find(subStatus => subStatus.id === sub.id)?.status;
    return status !== SUBSCRIPTION_STATUS.OLD
      && status !== SUBSCRIPTION_STATUS.RENEWED
      && status !== SUBSCRIPTION_STATUS.EXPIRED;
  });
};

export const SubscriptionHelper = {
  getSubscriptionRevue,
  filterCurrentSubscriptions
};