import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { translate } from 'utils';
import { PATHS } from 'utils/constants';

const ConfirmRedirect = props => {
  const { category } = props;
  return (
    <Grid item>
      <Grid alignItems="center" container direction="column" spacing={2}>
        <Grid item>
          <Link to={{
            pathname: PATHS.USER_ALLOWANCE_APPLICATION,
            state: { category }
          }}
          >
            <Button
              aria-label={translate('button.consultAllowances')}
              color="secondary"
            >
              {translate('button.consultAllowances')}
            </Button>
          </Link>
        </Grid>
        <Grid item>
          <Link to={PATHS.HOME}>
            <Button
              aria-label={translate('button.home')}
              variant="text"
            >
              {translate('button.home')}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmRedirect;