/* eslint-disable radix */
import { action, decorate, observable } from 'mobx';
import {
  AllowanceApplicationService, AllowanceService, RightHolderService, SubscriptionService, UserService
} from 'services';
import { ALLOWANCE_TYPES, ALLOWANCE_TYPE_IDS, SUBSCRIPTION_STATUS } from 'utils/constants';
import { translate } from 'utils';
import { CookieHelper, FormHelper } from 'utils/helpers';
import { DateHelper } from 'utils/helpers/DateHelper';

const getErrorMessage = resp => {
  const errorMessage = {
    message: translate('errors.default'),
    variant: 'error'
  };

  if (resp.errors && resp.errors.length > 0) {
    errorMessage.message = resp.errors[0].detail;
  } else if (resp.information && resp.information.length > 0) {
    errorMessage.message = resp.information[0].detail;
    errorMessage.variant = 'info';
  } else if (resp.warnings && resp.warnings.length > 0) {
    errorMessage.message = resp.warnings[0].detail;
    errorMessage.variant = 'warning';
  }

  return errorMessage;
};

export class UserStore {
  constructor() {
    this.isLoading = false;
    this.isLoadingAllowanceApplication = false;
    this.isConnected = false;
    this.isOffline = false;
    this.userConnected = null;
    this.userAllowanceApplicationList = [];
    this.userAllowanceLeisureApplicationList = [];
    this.userAllowanceSocialApplicationList = [];
    this.userRecentAllowanceLeisureApplicationList = [];
    this.userRecentAllowanceSocialApplicationList = [];
    this.userAllowanceApplicationDetail = null;
    this.userAllowanceApplicationTypes = null;
    this.userRightHolder = [];
    this.subscriptionsStatus = [];
    this.allUserRightHolder = [];
  }

  // eslint-disable-next-line class-methods-use-this
  createCookie(cookies) {
    const expiresDate = new Date(cookies.refreshTokenExpiration).toUTCString();

    return new Promise(resolve => {
      CookieHelper.setCookie('token', cookies.token);
      CookieHelper.setCookie('refreshToken', cookies.refreshToken, expiresDate);
      resolve(true);
    });
  }

  checkSubscritionStatus = () => {
    SubscriptionService.getNumberRevue().then(resp => {
      const revues = resp.data;
      this.userConnected.allo18Subsciptions.forEach(sub => {
        const routingFileDateEdition = revues.find(revue => parseInt(revue.revue.idRevue) === parseInt(sub.idRevue))?.dateEdition;
        if (routingFileDateEdition) {
          const routingFileDate = DateHelper.dateToDDMMYYYY(routingFileDateEdition);
          const routingFileDatePlus2Months = new Date(routingFileDate);
          routingFileDatePlus2Months.setMonth(routingFileDatePlus2Months.getMonth() + 2);
          const routingFileDateMinus2Months = new Date(routingFileDate);
          routingFileDateMinus2Months.setMonth(routingFileDateMinus2Months.getMonth() - 2);
          const dateEnd = new Date(sub.endDate);

          if (dateEnd > routingFileDate) {
            if (dateEnd < routingFileDatePlus2Months) {
              if (sub.idAbonnementRenouvellement) {
                this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.RENEWED });
              } else {
                this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.CLOSE_TO_EXPIRED });
              }
            } else if (sub.idAbonnementRenouvellement) {
              this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.RENEWED });
            } else {
              this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.OK });
            }
          } else if (sub.idAbonnementRenouvellement) {
            this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.RENEWED });
          } else if (dateEnd < routingFileDateMinus2Months) {
            this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.OLD });
          } else {
            this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.EXPIRED });
          }
          if (!sub.estRegle) {
            this.subscriptionsStatus.push({ id: sub.id, status: SUBSCRIPTION_STATUS.UNPAYED });
          }
        }
      });
    });
  }

  isPricingRangeUpToDate = () => {
    if (this.userConnected && (!this.userConnected.lastTaxNoticeUpdate || !this.userConnected.lastTariffSectionUpdate)) {
      return true;
    }

    if (this.userConnected && this.userConnected.lastTaxNoticeUpdate && this.userConnected.lastTariffSectionUpdate) {
      return new Date(FormHelper.formatDateFromBackend(this.userConnected.lastTaxNoticeUpdate))
        <= new Date(FormHelper.formatDateFromBackend((this.userConnected.lastTariffSectionUpdate)));
    }

    return false;
  }

  getProfile() {
    this.isLoading = true;

    return UserService.getProfile().then(action(user => {
      if (user.data) {
        this.isConnected = true;
        this.userConnected = user.data;
        this.userConnected.login = `${user.data.firstName} ${user.data.lastName}`;

        this.getSubscriptions();
        this.getTariffSections(user.data.trancheTarifaire);
        this.getRightHolderList();

        this.isMembership = this.userConnected.memberCode.startsWith('DH');
        this.userConnected.image = this.isMembership
          ? '/assets/images/fixtures/icon-profile-member.png'
          : '/assets/images/fixtures/icon-profile-reader.png';
        return true;
      }

      const errorMessage = getErrorMessage(user);
      throw errorMessage;
    }))
      .catch(error => { throw error; })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  getSubscriptions() {
    return UserService.getSubscriptions().then(action(resp => {
      if (resp && resp.data) {
        this.userConnected.allo18Subsciptions = resp.data;
        this.checkSubscritionStatus();
      }
    }))
      .catch(error => { throw error; })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  getTariffSections(tariffSectionId) {
    return AllowanceService.getAllowanceTariffSections().then(action(tariffSections => {
      tariffSections.forEach(ts => {
        if (ts.id === tariffSectionId) {
          this.userConnected.trancheTarifaire = ts.code;
        }
      });
    }))
      .catch(error => { throw error; })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  connectUser(user) {
    this.isLoading = true;

    return UserService.login(user)
      .then(resp => {
        if (resp.data) {
          return this.createCookie(resp.data)
            .then(() => this.getProfile())
            .catch(error => {
              throw error;
            });
        }

        const errorMessage = getErrorMessage(resp);
        throw errorMessage;
      })
      .catch(error => { throw error; })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  forgotPassword(username) {
    this.isLoading = true;
    return UserService.forgotPassword(username)
      .then(resp => {
        if (!resp.data.isValid) {
          throw resp.errors[0];
        }
      })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  refreshToken() {
    const refreshToken = CookieHelper.getCookie('refreshToken');

    if (refreshToken) {
      return UserService.refreshToken(refreshToken).then(action(refresh => {
        if (refresh.data) {
          return this.createCookie(refresh.data);
        }

        const errorMessage = getErrorMessage(refresh);
        throw errorMessage;
      }))
        .catch(error => { throw error; })
        .finally(action(() => {
          this.isLoading = false;
        }));
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return new Promise((_, reject) => reject({ message: 'errors.tokenExpired', variant: 'warning' }));
  }

  updateProfile(profileUpdated) {
    return UserService.updateProfile(profileUpdated)
      .then(() => this.getProfile())
      .catch(error => { throw error; });
  }

  updateSubscriptionAddress(subscriptionsUpdated) {
    return UserService.updateSubscriptionAddress(subscriptionsUpdated)
      .then(() => this.getProfile())
      .catch(error => { throw error; });
  }

  getAllowanceApplication() {
    this.isLoadingAllowanceApplication = true;

    return AllowanceApplicationService.getAllowanceApplicationList().then(action(allowanceApp => {
      this.userAllowanceApplicationList = allowanceApp;
      const userAllowanceSocialApplicationList = [];
      const userAllowanceLeisureApplicationList = [];
      const userRecentAllowanceSocialApplicationList = [];
      const userRecentAllowanceLeisureApplicationList = [];
      const today = new Date();
      const todayMinus3Month = new Date(new Date().setMonth(today.getMonth() - 3));
      allowanceApp.forEach(allowance => {
        const lastChangeStatusDate = new Date(allowance.lastChangeStatusDate);
        if (allowance.type?.name === ALLOWANCE_TYPES.CAS || allowance.type?.id === ALLOWANCE_TYPE_IDS.RESCUE) {
          userAllowanceSocialApplicationList.push(allowance);
          if (DateHelper.checkIfBetweenTwoDatesWithoutHours(todayMinus3Month, today, lastChangeStatusDate)) {
            userRecentAllowanceSocialApplicationList.push(allowance);
          }
        } else {
          userAllowanceLeisureApplicationList.push(allowance);
          if (DateHelper.checkIfBetweenTwoDatesWithoutHours(todayMinus3Month, today, lastChangeStatusDate)) {
            userRecentAllowanceLeisureApplicationList.push(allowance);
          }
        }
      });
      this.userAllowanceSocialApplicationList = userAllowanceSocialApplicationList;
      this.userAllowanceLeisureApplicationList = userAllowanceLeisureApplicationList;
      this.userRecentAllowanceSocialApplicationList = userRecentAllowanceSocialApplicationList;
      this.userRecentAllowanceLeisureApplicationList = userRecentAllowanceLeisureApplicationList;
    }))
      .catch(error => { throw error; })
      .finally(action(() => {
        this.isLoadingAllowanceApplication = false;
      }));
  }

  getAllowanceApplicationDetail(id) {
    return AllowanceApplicationService.getAllowanceApplicationDetail(id).then(action(allowanceAppDetail => {
      this.userAllowanceApplicationDetail = allowanceAppDetail;
    }))
      .catch(error => { throw error; });
  }

  getAllowanceApplicationTypes() {
    return AllowanceApplicationService.getAllowanceApplicationTypes().then(action(allowanceAppTypes => {
      this.userAllowanceApplicationTypes = allowanceAppTypes;
    }))
      .catch(error => { throw error; });
  }

  getRightHolderList() {
    return RightHolderService.getRightHolderList().then(action(rh => {
      this.userRightHolder = rh.filter(rhItem => rhItem.isValidated);
      this.allUserRightHolder = rh;
    }))
      .catch(error => { throw error; });
  }

  disconnectUser() {
    this.isConnected = false;
    this.userConnected = {};
    this.userAllowanceApplicationList = [];
    this.userAllowanceLeisureApplicationList = [];
    this.userAllowanceSocialApplicationList = [];
    this.userRecentAllowanceLeisureApplicationList = [];
    this.userRecentAllowanceSocialApplicationList = [];
    this.userAllowanceApplicationDetail = null;
    this.userAllowanceApplicationTypes = null;
    this.userRightHolder = [];
    this.subscriptionsStatus = [];
    this.allUserRightHolder = [];

    return new Promise(resolve => {
      CookieHelper.deleteCookie('token');
      CookieHelper.deleteCookie('refreshToken');
      resolve(true);
    });
  }

  setOffline(offline = true) {
    this.isOffline = offline;
  }
}

decorate(UserStore, {
  isLoading: observable,
  isLoadingAllowanceApplication: observable,
  isConnected: observable,
  isOffline: observable,
  userConnected: observable,
  userAllowanceApplicationList: observable,
  userAllowanceLeisureApplicationList: observable,
  userAllowanceSocialApplicationList: observable,
  userAllowanceApplicationDetail: observable,
  userAllowanceApplicationTypes: observable,
  userRightHolder: observable,
  allUserRightHolder: observable,
  checkCookie: action,
  deleteCookie: action,
  connectUser: action,
  refreshToken: action,
  getProfile: action,
  getTariffSections: action,
  forgotPassword: action,
  updateProfile: action,
  updateSubscriptionAddress: action,
  getAllowanceApplication: action,
  getAllowanceApplicationDetail: action,
  getAllowanceApplicationTypes: action,
  getRightHolderList: action,
  disconnectUser: action,
  setOffline: action,
  isPricingRangeUpToDate: action,
  getSubscriptions: action
});

export const userStore = new UserStore();