import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { translate } from 'utils';

const InfoLine = ({
  label, info, displayIf
}) => {
  const isDisplayed = displayIf !== undefined ? Boolean(displayIf) : Boolean(info);

  return (
    <Typography>
      {`${translate(label)} : `}
      {isDisplayed ? (
        <strong>{info}</strong>
      ) : '-'}
    </Typography>
  );
};

InfoLine.propTypes = {
  displayIf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number
  ]),
  label: PropTypes.string
};

InfoLine.defaultProps = {
  displayIf: undefined,
  info: '',
  label: ''
};

export default InfoLine;