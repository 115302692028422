/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button, FormLabel, Grid, makeStyles, TextField
} from '@material-ui/core';
import {
  PersonalInfos, SectionHeader, SectionTitle, Subscription, SubscriptionSummary
} from 'components/Subscription';
import {
  faChevronRight, faPhone, faUser
} from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot } from '@fortawesome/sharp-regular-svg-icons';
import {
  faMinusCircle,
  faPlusCircle
} from '@fortawesome/pro-solid-svg-icons';
import { useFormState } from 'react-use-form-state';
import { FormHelper, translate } from 'utils';
import { FormAddress, FormSelect, PayboxPayment } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MAGAZINE, PAYMENT_TYPE } from 'utils/constants';
import { SubscriptionService } from 'services';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: '50px'
  },
  numberOfCopiesSelect: {
    '& input': {
      padding: '12px 4px'
    }
  }
}));

const REQUIRED_FIELDS_GLOBAL = [
  'address1',
  'city',
  'civility',
  'country',
  'firstName',
  'lastName',
  'postalCode'
];

const REQUIRED_FIELDS_WITH_EMAIL = [
  'email',
  ...REQUIRED_FIELDS_GLOBAL
];

const PageSubscription = () => {
  const classes = useStyles();
  const [currentSectionOpen, setCurrentSectionOpen] = useState(1);
  const [sectionsAlreadyOpen, setSectionsAlreadyOpen] = useState([1]);
  const [canGoNext, setCanGoNext] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [prices, setPrices] = useState([]);
  const [isLoadingPrices, setIsLoadingPrices] = useState(true);
  const [formState, {
    text, select, raw, email, tel, number, checkbox
  }] = useFormState({
    country: FormHelper.getDefaultCountry(),
    civility: '1',
    magazine: MAGAZINE[0],
    numberOfCopies: 1,
    subscriptions: [
      {
        id: 1,
        npai: 'true',
        country: FormHelper.getDefaultCountry(),
        civility: 1,
        isValid: true
      }
    ],
    conditionsAccepted: false
  });

  const handleSectionOpenChange = newSection => {
    let newSectionValue = newSection;
    if (!newSection) newSectionValue = currentSectionOpen < 3 ? currentSectionOpen + 1 : currentSectionOpen;
    setCurrentSectionOpen(newSectionValue);
    if (!sectionsAlreadyOpen.includes(newSectionValue)) {
      setSectionsAlreadyOpen([...sectionsAlreadyOpen, newSectionValue]);
    }
  };

  useEffect(() => {
    SubscriptionService.getSubscriptionsPrices().then(resp => {
      setPrices(resp.data.filter(d => d.codeRevue !== 'ALLO18ADH'));
    }).finally(() => setIsLoadingPrices(false));
  }, []);

  useEffect(() => {
    let canGoNextResult = true;
    switch (currentSectionOpen) {
    case 1:
      REQUIRED_FIELDS_WITH_EMAIL.forEach(field => {
        if (!(!!formState.values[field] && formState.values[field] !== '')) {
          canGoNextResult = false;
        }
      });
      break;
    case 2:
      formState.values.subscriptions.forEach(subscription => {
        if (subscription.values?.npai === 'false') {
          REQUIRED_FIELDS_GLOBAL.forEach(field => {
            if (!(!!subscription.values[field] && subscription.values[field] !== '')
            && (Object.values(subscription.errors).filter(error => error !== undefined).length === 0)) {
              canGoNextResult = false;
            }
          });
        }
      });
      break;
    default:
      break;
    }
    setCanGoNext(canGoNextResult && (Object.values(formState.errors).filter(error => error !== undefined).length === 0));
  }, [formState.values]);

  const handleAddSubscription = (numberOfSubscription = 1, newNumberOfCopies = formState.values.numberOfCopies) => {
    const referenceId = parseInt(newNumberOfCopies) - numberOfSubscription;
    const subscriptionsToAdd = [];
    for (let index = 1; index <= numberOfSubscription; index++) {
      subscriptionsToAdd.push({ id: referenceId + index, npai: 'true' });
    }
    formState.setField('subscriptions', [
      ...formState.values.subscriptions,
      ...subscriptionsToAdd
    ]);
  };

  const handleRemoveSubscription = (numberOfSubscription = 1) => {
    if (formState.values.subscriptions.length > numberOfSubscription) {
      const subscriptions = [...formState.values.subscriptions];
      subscriptions.splice(numberOfSubscription * -1);
      formState.setField('subscriptions', [...subscriptions]);
    }
  };

  const handlePlusSubscription = () => {
    formState.setField('numberOfCopies', formState.values.numberOfCopies + 1);
    handleAddSubscription(1, formState.values.numberOfCopies + 1);
  };

  const handleMinusSubscription = () => {
    if (formState.values.numberOfCopies > 1) {
      formState.setField('numberOfCopies', formState.values.numberOfCopies - 1);
      handleRemoveSubscription();
    }
  };

  const createSubscriberAndPaymentInfos = () => {
    const subscriptions = formState.values.subscriptions.map(subscription => (subscription.values ? subscription.values : subscription));
    localStorage.setItem('subscriber', JSON.stringify({ ...formState.values, subscriptions }));
    return new Promise(resolve => resolve({
      price: totalPrice,
      lastName: formState.values.lastName,
      firstName: formState.values.firstName,
      postalCode: formState.values.postalCode,
      email: formState.values.email
    }));
  };

  useEffect(() => {
    const lengthDifference = formState.values.numberOfCopies - formState.values.subscriptions.length;
    if (lengthDifference === 0) return;
    let subcriptionAction = null;
    if (lengthDifference > 0) {
      subcriptionAction = numberOfSubscription => handleAddSubscription(numberOfSubscription);
    } else if (lengthDifference < 0) {
      subcriptionAction = numberOfSubscription => handleRemoveSubscription(numberOfSubscription);
    }
    subcriptionAction(Math.abs(lengthDifference));
  }, [formState.values.numberOfCopies]);

  return (
    <Grid className={classes.container} container direction="column" spacing={4}>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <SectionHeader
              label={translate('subscription.personalInfos')}
              sectionNumber="1"
              onClick={(currentSectionOpen !== 1 && sectionsAlreadyOpen.includes(1)) ? () => handleSectionOpenChange(1) : null}
            />
          </Grid>
          {currentSectionOpen === 1 && (
            <Grid item style={{ marginLeft: '15px' }}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <SectionTitle icon={faUser} label={translate('subscription.yourInfos')} />
                    </Grid>
                    <Grid item xs={6}>
                      <PersonalInfos formState={formState} select={select} text={text} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={8}>
                    <Grid item xs={5}>
                      <Grid container direction="column">
                        <Grid item>
                          <SectionTitle icon={faLocationDot} label={translate('subscription.yourAddress')} />
                        </Grid>
                        {!isLoadingPrices ? (
                          <Grid item>
                            <FormAddress
                              displayPrice
                              formState={formState}
                              prices={prices}
                              raw={raw}
                              required
                              select={select}
                              text={text}
                              variant="outlined"
                            />
                          </Grid>
                        )
                          : (<Skeleton height={50} width="600px" />)}
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <Grid container direction="column">
                        <Grid item>
                          <SectionTitle icon={faPhone} label={translate('subscription.yourParticulars')} />
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" spacing={1}>
                            <Grid item>
                              <TextField
                                error={formState.errors.email && formState.errors.email !== null}
                                fullWidth
                                helperText={formState.errors.email}
                                id="email"
                                label={translate('common.email')}
                                name="email"
                                required
                                variant="outlined"
                                {...email('email')}
                              />
                            </Grid>
                            <Grid item>
                              <TextField
                                error={formState.errors.phone && formState.errors.phone !== null}
                                fullWidth
                                helperText={formState.errors.phone}
                                id="phone"
                                label={translate('common.phone')}
                                name="phone"
                                variant="outlined"
                                {...tel('phone')}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" justify="space-between" spacing={4}>
          <Grid item>
            <SectionHeader
              label={translate('subscription.subscriptionInfos')}
              sectionNumber="2"
              onClick={(currentSectionOpen !== 2 && sectionsAlreadyOpen.includes(2)) ? () => handleSectionOpenChange(2) : null}
            />
          </Grid>

          {currentSectionOpen === 2 && (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid alignItems="center" container spacing={4}>
                  <Grid item xs={2}>
                    <FormSelect
                      error={formState.errors.magazine && formState.errors.magazine !== null}
                      helperText={formState.errors.magazine}
                      id="magazine"
                      label="subscription.selectMagazine"
                      name="magazine"
                      options={MAGAZINE}
                      placeholder=""
                      required
                      variant="outlined"
                      {...select('magazine')}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <FormLabel required>
                          {translate('subscription.numberOfCopies')}
                        </FormLabel>
                      </Grid>
                      <Grid item>
                        <Grid alignItems="center" container spacing={2} wrap="nowrap">
                          <Grid item>
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              size="2x"
                              style={{
                                color: formState.values.numberOfCopies > 1 ? 'var(--secondary-color)' : 'var(--grey)',
                                cursor: formState.values.numberOfCopies > 1 ? 'pointer' : 'auto'
                              }}
                              onClick={handleMinusSubscription}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              className={classes.numberOfCopiesSelect}
                              error={formState.errors.numberOfCopies && formState.errors.numberOfCopies !== null}
                              helperText={formState.errors.numberOfCopies}
                              id="numberOfCopies"
                              name="numberOfCopies"
                              style={{ width: '75px' }}
                              variant="outlined"
                              {...number('numberOfCopies')}
                              type="tel"
                            />
                          </Grid>
                          <Grid item>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              size="2x"
                              style={{
                                color: 'var(--secondary-color)',
                                cursor: 'pointer'
                              }}
                              onClick={handlePlusSubscription}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {formState.values.subscriptions.map(subscription => (
                <Grid item>
                  <Subscription
                    formState={formState}
                    prices={prices}
                    subscriptionData={subscription.values ? { ...subscription.values } : { ...subscription }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" justify="space-between" spacing={4}>
          <Grid item>
            <SectionHeader
              label={translate('subscription.subscriptionSummary')}
              sectionNumber="3"
              onClick={(currentSectionOpen !== 3 && sectionsAlreadyOpen.includes(3)) ? () => handleSectionOpenChange(3) : null}
            />
          </Grid>
          {currentSectionOpen === 3 && (
            <Grid item xs={11}>
              <SubscriptionSummary checkbox={checkbox} formState={formState} prices={prices} setTotalPrice={setTotalPrice} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <Grid alignItems="flex-end" container direction="column">
          <Grid item>
            {currentSectionOpen !== 3
              ? (
                <Button
                  color="secondary"
                  disabled={!canGoNext}
                  style={{ width: '250px' }}
                  onClick={() => handleSectionOpenChange(0)}
                >
                  <Grid container justify="center" spacing={1}>
                    <Grid item>
                      {translate('subscription.continue')}
                    </Grid>
                    <Grid item>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Grid>
                  </Grid>
                </Button>
              )
              : (
                <PayboxPayment
                  buttonLabel="subscription.pay"
                  disabled={!canGoNext || !formState.values.conditionsAccepted}
                  getPaymentInformations={createSubscriberAndPaymentInfos}
                  providedEmail={formState.values.email}
                  typeId={PAYMENT_TYPE.SUBSCRIPTION.code}
                />
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PageSubscription;