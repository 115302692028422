import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import App from './app/App';

import * as serviceWorker from './serviceWorker';
import * as webVitals from './webVitals';
import './locales/i18n';
import './index.css';

configure({
  enforceActions: 'observed'
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const triggerSkipWaiting = (SW, state) => {
  if (state === 'installed' || state === 'waiting') {
    SW.postMessage({ type: 'SKIP_WAITING' });
  }
};

const updateSW = registration => {
  const newSW = registration.installing || registration.waiting;

  if (newSW) {
    triggerSkipWaiting(newSW, newSW.state);
    newSW.addEventListener('statechange', event => {
      triggerSkipWaiting(newSW, event.target.state);

      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const SWConfig = {
  onUpdate: registration => {
    updateSW(registration);

    registration.addEventListener('updatefound', () => updateSW(registration));
  }
};

serviceWorker.register(SWConfig);

webVitals.report();