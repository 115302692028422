import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  img {
    min-height: 50px;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  span {
    position: absolute;
    bottom: 0px;
    right: -4px;
    width: 24px;
    height: 24px;
    text-align: center;
    background: var(--white);
    border-radius: 50%;

    svg {
      color: var(--secondary-color);
      margin-top: 5px;
    }
  }
`;

const IconProfile = ({
  alt, src, width, icon
}) => (
  <StyledIcon>
    <img alt={alt} src={src} width={width} />
    {icon && (
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
    )}
  </StyledIcon>
);

IconProfile.propTypes = {
  alt: PropTypes.string,
  icon: PropTypes.shape({}),
  src: PropTypes.string,
  width: PropTypes.string
};

IconProfile.defaultProps = {
  alt: '',
  icon: null,
  src: '',
  width: '50px'
};

export default IconProfile;