export const messageFixture = ({
  type = '',
  message = '',
  roles = [],
  dateBegin = ''
} = {}) => ({
  type,
  message,
  roles,
  dateBegin
});

export const messagesListFixture = [
  messageFixture({
    type: 'warning',
    message: 'Merci de vérifier que votre adresse postale est à jour',
    roles: ['ADH_En activité'],
    dateBegin: '01/01'
  }),
  messageFixture({
    type: 'warning',
    message: 'Merci de vérifier que votre adresse postale est à jour',
    roles: [],
    dateBegin: '09/01'
  }),
  messageFixture({
    type: 'warning',
    message: 'Merci de renseigner votre nouvel avis d\'imposition',
    roles: ['ADH_En activité',
      'ADH_Membre non actif',
      'ADH_Ancien',
      'ADH_Couvert',
      'ADH_Couvert mais sans droits à la CSFC',
      'ADH_Membre bienfaiteur',
      'ADH_Orphelin',
      'ADH_Salarié',
      'ADH_Veuf'],
    dateBegin: '07/01'
  }),
  messageFixture({
    type: 'warning',
    message: 'Merci de renseigner votre nouvel avis d\'imposition',
    roles: ['ADH_En activité'],
    dateBegin: '09/01'
  }),
  messageFixture({
    type: 'error',
    message: 'Merci de contrôler que vos informations personnelles sont à jour',
    roles: ['ADH_En activité',
      'ADH_Membre non actif',
      'ADH_Ancien',
      'ADH_Couvert',
      'ADH_Couvert mais sans droits à la CSFC',
      'ADH_Membre bienfaiteur',
      'ADH_Orphelin',
      'ADH_Salarié',
      'ADH_Veuf'],
    dateBegin: '09/01'
  })
];

export const MESSAGE_DISPLAY_DURATION = 14;