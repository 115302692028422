import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const getToken = memberCode => RequestHelper.POST(API_ROUTES.FRANCEBILLET, memberCode);
const getConnectionUrl = () => RequestHelper.GET(API_ROUTES.FRANCE_BILLET_ACCESS_URL());

const FrancebilletService = {
  getToken,
  getConnectionUrl,
};

export default FrancebilletService;