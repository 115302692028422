import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, TextField, Typography
} from '@material-ui/core';
import { Datepicker, FormSectionTitle } from 'components';
import { faUsersMedical } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import shortid from 'shortid';

const Session = ({
  allowance, module, onChange, sessionNumber, sessions,
  handleSessionDateChange, disabled
}) => (
  <Grid container direction="column">
    <FormSectionTitle icon={faUsersMedical} title={module.name} />

    <Typography gutterBottom>{allowance.session}</Typography>

    <Grid container direction="column" spacing={1}>
      <Grid item sm={6} xs={12}>
        <TextField
          disabled={disabled}
          id="sessionNumber"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {translate('forms.sessionEndLabel')}
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {translate('forms.sessionStartLabel')}
              </InputAdornment>
            )
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            max: 10,
            min: 1,
            step: 1
          }}
          label={translate('common.sessionNumber')}
          name="sessionNumber"
          required
          type="number"
          value={sessionNumber}
          onChange={onChange}
          onKeyDown={e => { if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') e.preventDefault(); }}
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        {sessions && sessions.length > 0 && (
          sessions.map((ses, index) => (
            <Datepicker
              disabled={disabled}
              disableFuture
              key={shortid.generate()}
              label="common.sessionIndex"
              labelData={{ index: Number(index + 1) }}
              name={`session${Number(index + 1)}`}
              required
              value={ses.date}
              onChange={date => handleSessionDateChange(date, index)}
            />
          ))
        )}
      </Grid>
    </Grid>
  </Grid>
);

Session.propTypes = {
  allowance: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sessionNumber: PropTypes.number.isRequired
};

Session.defaultProps = {
  disabled: false
};

export default Session;