/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Card, CardContent, Grid, Select, TextField
} from '@material-ui/core';
import { CustomCardTitle, InfoLine, PayboxPayment } from 'components';
import { translate } from 'utils';
import { faBooks } from '@fortawesome/pro-regular-svg-icons';
import { useStores } from 'hooks';
import { PAYMENT_TYPE } from 'utils/constants';
import { Alert } from '@material-ui/lab';

import { UserService } from 'services';

const SubscriptionCard = ({
  subscription, disabled = false, tariff
}) => {
  const { userStore } = useStores();
  const { subscriptionsStatus, userConnected, isMembership } = userStore;
  const {
    email
  } = userConnected;
  const [civilities, setCivilites] = useState([]);
  const [isLoadingcivilities, setIsLoadingCivilites] = useState(true);
  const isDisabled = disabled || !!subscription.idAbonnementRenouvellement;
  const getStatus = id => subscriptionsStatus.find(sub => sub.id === id)?.status;

  const renewSubscription = () => UserService.renewalSubscription(subscription.id)
    .then(response => ({
      itemId: response.data,
      confirmParameter: `subscriptionId=${response.data}`,
      price: tariff.prixAdherent * subscription.numberOfCopies
    })).catch(() => undefined);

  const settleSubscription = () => new Promise(resolve => resolve({
    itemId: subscription.id,
    confirmParameter: `subscriptionId=${subscription.id}`,
    price: tariff.prixAdherent * subscription.numberOfCopies
  }));

  useEffect(() => {
    UserService.getCivilities().then(resp => {
      setCivilites(resp.data);
    }).finally(() => setIsLoadingCivilites(false));
  }, []);

  return (
    <Grid item key={`${subscription.address.ligne1}${subscription.numberOfCopies}`} sm={6} xs={12}>
      <Card>
        <CustomCardTitle
          icon={faBooks}
          subscriptionStatus={isMembership ? null : getStatus(subscription.id)}
          title="pageUserProfile.subscriptions.allo18"
        />
        <CardContent>
          <Grid container direction="column">
            {!subscription.estRegle && (
              <Grid item>
                <Alert severity="warning">{translate('pageUserProfile.subscriptions.subscriptionNotPayed')}</Alert>
              </Grid>
            )}
            <Grid item>
              <Grid item>
                <Grid alignItems="flex-end" container spacing={2}>
                  <Grid item>
                    {!isLoadingcivilities && (
                      <Select
                        color="secondary"
                        disabled={isDisabled}
                        fullWidth
                        labelId="civility"
                        native
                        value={subscription.idCiviliteBeneficiaire}
                      >
                        {civilities.map(civility => (
                          <option key={civility} value={civility.id}>
                            {civility.nomLong}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={isDisabled}
                      fullWidth
                      label={translate('common.lastName')}
                      name="lastName"
                      value={subscription.nomBeneficiaire}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      disabled={isDisabled}
                      fullWidth
                      label={translate('common.firstName')}
                      name="firstName"
                      value={subscription.prenomBeneficiaire}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column" spacing={1}>
                <Grid item xs>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    label={translate('common.address')}
                    name="ligne1"
                    value={subscription.address.ligne1}
                  />
                </Grid>
                {subscription.address.ligne2 && (
                  <Grid item xs>
                    <TextField
                      disabled={isDisabled}
                      fullWidth
                      label={translate('common.address2')}
                      name="address2"
                      value={subscription.address.ligne2}
                    />
                  </Grid>
                )}
                {subscription.address.ligne3 && (
                  <Grid item xs>
                    <TextField
                      disabled={isDisabled}
                      fullWidth
                      label={translate('common.address3')}
                      name="address3"
                      value={subscription.address.ligne3}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        disabled={isDisabled}
                        fullWidth
                        label={translate('common.postalCode')}
                        name="postalCode"
                        value={subscription.address.codePostal}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        disabled={isDisabled}
                        fullWidth
                        label={translate('common.city')}
                        name="city"
                        value={subscription.address.ville}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    label={translate('common.country')}
                    name="country"
                    value={subscription.address.pays}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                disabled
                fullWidth
                label={translate('common.numberOfCopies')}
                name="numberOfCopies"
                value={subscription.numberOfCopies}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled
                fullWidth
                label={translate('common.startDateSubscription')}
                name="startDate"
                value={new Date(subscription.startDate).toLocaleDateString()}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled
                fullWidth
                label={translate('common.endDateSubscription')}
                name="endDate"
                value={new Date(subscription.endDate).toLocaleDateString()}
              />
            </Grid>
            <Grid item>
              <InfoLine
                info={`${tariff?.titreRevue} (${tariff?.prixAdherent} €)`}
                label="common.unitPrice"
              />
            </Grid>
            <Grid item>
              <InfoLine
                info={`${tariff.prixAdherent * subscription.numberOfCopies}€`}
                label={disabled
                  ? 'common.price' : subscription.estRegle
                    ? 'common.subscriptionPrice' : 'common.subscriptionSettlePrice'}
              />
            </Grid>
            <Grid container>
              <Grid item>
                {email && !subscription.estRegle && (
                  <PayboxPayment
                    buttonLabel="button.settle"
                    getPaymentInformations={settleSubscription}
                    typeId={PAYMENT_TYPE.RESUBSCRIPTION.code}
                  />
                )}
                {email && !isMembership
                  && subscription.estRegle
                  && subscription.idAbonnementRenouvellement === null
                  && subscription.numberOfReviews <= 1 && (
                  <PayboxPayment
                    buttonLabel="button.resubscribe"
                    getPaymentInformations={renewSubscription}
                    typeId={PAYMENT_TYPE.RESUBSCRIPTION.code}
                  />
                )}
                {!email && (
                  <Alert severity="error">{translate('errors.errorPayboxMail')}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SubscriptionCard;