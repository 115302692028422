import { API_KEY } from 'utils/constants';
import { CookieHelper } from '.';

export const getDefaultHeaders = () => {
  const headers = {
    'Content-Type': 'application/json'
  };

  return headers;
};

export const httpSettings = settings => {
  const headers = settings.headers || getDefaultHeaders();
  const init = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
    case 'application/json':
      if (!(init.body instanceof FormData)) {
        init.body = JSON.stringify(init.body);
      }
      break;
    case 'application/x-www-form-urlencoded': {
      const formDataEntries = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
      const formDataArray = [];

      formDataEntries.forEach(([key, value]) => {
        formDataArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });
      init.body = formDataArray.join('&');
      break;
    }
    default:
      break;
    }
  }

  headers.Accept = '*/*';

  if (!settings.disableDefautHeader) {
    const userToken = CookieHelper.getCookie('token');
    if (userToken) {
      headers.Authorization = `Bearer ${userToken}`;
    }
    if (settings.useAPIKey) {
      headers['x-api-key'] = API_KEY;
    }
  }

  init.headers = headers;

  return init;
};