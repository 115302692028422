/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

import {
  Wrapper
} from 'components';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import { ROUTES } from 'utils/constants';

const PageConfirmSubscription = observer(() => {
  const history = useHistory();

  return (
    <Wrapper>
      <br />
      <Grid alignItems="center" container direction="column" spacing={4}>
        <Grid item xs>
          <img alt="ConfirmationPaybox" src="/assets/images/confirmation-leisure.png" />
        </Grid>
        <Grid item xs>
          <Typography align="center" className="bold" component="h2" variant="h5">
            {translate('forms.validateSubscriptionTitle1')}
          </Typography>
          <Typography align="center" className="bold" component="h2" variant="h5">
            {translate('forms.validateSubscriptionTitle2')}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Grid alignItems="center" container spacing={2} wrap="nowrap">
            <Grid item>
              <FontAwesomeIcon color="var(--tertiary-color)" icon={faPaperPlane} size="2x" />
            </Grid>
            <Grid item>
              <Typography align="flex-start">
                {translate('forms.validateSubscriptionText')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button color="secondary" style={{ width: '250px' }} onClick={() => history.push(ROUTES.CONNECT)}>
            {translate('subscription.goToConnect')}
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
});

export default PageConfirmSubscription;