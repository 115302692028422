import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { BANNER_COLORS, PATHS } from '../../../utils/constants';

const BannerMessageContainer = styled.div`
  @keyframes enteringAnimation {
   from {
    transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  animation: 0.5s ease-out 0s 1 enteringAnimation;
  width:100%;
  text-align:center;
  color:var(--white);
  padding: 1rem 6rem;
  font-weight:bold;
  font-size:1.8rem;
  cursor: pointer;

  &:hover{
    filter: brightness(0.85);
  }
`;

const getBannerColor = type => BANNER_COLORS.find(color => color.value === type).color;

const BannerMessage = ({ children, type }) => {
  const history = useHistory();
  return (
    <BannerMessageContainer
      style={{ backgroundColor: `var(${getBannerColor(type)})` }}
      onClick={() => history.push({
        pathname: PATHS.USER_PROFILE,
        state: { tab: 3 }
      })}
    >
      {children}
    </BannerMessageContainer>
  );
};

BannerMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  type: PropTypes.oneOf([
    'error',
    'warning'
  ])
};

BannerMessage.defaultProps = {
  type: 'error'
};

export default BannerMessage;