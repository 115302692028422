import React, { useEffect } from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import {
  InfoLine, PageTitle, PageTitleContainer, Wrapper
} from 'components';
import { Link } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { translate } from 'utils';

const PageMentions = () => {
  const website = <Link to={PATHS.HOME}>www.adherent.adosspp.com</Link>;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <PageTitleContainer>
        <PageTitle title="footer.legal" withMargin={false} />
      </PageTitleContainer>

      <Typography>
        Le site Internet
        {' '}
        {website}
        {' '}
        est édité par :
      </Typography>

      <p className="bold">Dénomination</p>

      <p>
        Association pour le Développement des Œuvres Sociales des Sapeurs-Pompiers de Paris
      </p>

      <InfoLine info="1, place Jules Renard 75017 PARIS" label="Siège social" />
      <InfoLine info="39 Boulevard Bourdon – CS 10622 – 75141 Paris Cedex 04" label="Administration" />
      <InfoLine info="775 692 213" label="N° SIREN" />
      <InfoLine info="M. André-Pierre LAGARDE" label="Directeur de la publication" />
      <InfoLine info="01.47.17.87.21" label={translate('common.phone')} />

      <p>
        L&apos;éditeur s&apos;engage à respecter l&apos;ensemble des lois concernant
        la mise en place et l&apos;activité d&apos;un site Internet.
      </p>

      <p className="bold">Informations techniques</p>

      <Typography>
        Il est rappelé que le secret des correspondances n&apos;est pas garanti sur le réseau Internet
        et qu&apos;il appartient à chaque utilisateur d&apos;Internet de prendre toutes les mesures appropriées
        de façon à protéger ses propres données et/ou logiciels de la contamination d&apos;éventuels virus
        circulant sur Internet.
      </Typography>

      <p className="bold">Hébergement</p>

      <InfoLine info="OVH" label="Société" />
      <InfoLine info="B424 761 419" label="Immatriculée au Registre du commerce et des sociétés de Roubaix sous le numéro" />
      <InfoLine info="2 rue Kellermann - 59100 ROUBAIX" label="Siège social" />

      <p className="bold">Conception et création</p>

      <InfoLine info="SUPRALOG" label="Nom" />
      <InfoLine info="80 Route des Lucioles Bâtiment N - 06560 Valbonne" label="Adresse" />

      <p className="bold">Liens hypertextes</p>

      <Typography>
        La création de liens hypertextes vers le site
        {' '}
        {website}
        {' '}
        est soumise à accord préalable du directeur de la publication.
        Les liens hypertextes établis en direction d&apos;autres sites à partir de :
        {' '}
        {website}
        {' '}
        ne sauraient, en aucun cas, engager la responsabilité de l&apos;ADOSSPP.
      </Typography>

      <p className="bold">Droits d&apos;auteur et Propriété Intellectuelle</p>

      <Typography>
        L’ADOSSPP détient les droits de propriété intellectuelle sur les éléments originaux qui
        composent le Site Web (structure générale, textes, graphiques, images, sons et vidéos),
        exception faite des éléments qui peuvent lui être remis par ses partenaires ou des tiers,
        lesquels restent la propriété exclusive de leurs auteurs respectifs et sont diffusés sur le Site Web
        avec leur autorisation.
      </Typography>

      <br />

      <Typography>
        Toute reproduction, représentation, diffusion, intégrale ou partielle, des contenus, des pages,
        des données, des marques et logos, publiés sur le Site Web ou tout autre élément constitutif du site,
        par quelque procédé que ce soit, sans l’autorisation préalable et écrite de l’ADOSSPP et/ou, le cas échéant,
        des autres titulaires de droits de propriété intellectuelle, est strictement interdite et constituerait
        une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
      </Typography>

      <br />

      <Typography>
        Toute reproduction, autorisée par l’éditeur, implique l’indication des mentions suivantes :
        <br />
        Copyrights / Crédits Photos « ADOSSPP » « Brigade de sapeurs-pompiers de Paris » ou « BSPP ».
      </Typography>

      <p className="bold">Objectif de qualité</p>

      <Typography>
        L’ADOSSPP prend toutes les dispositions pour fournir aux internautes un service de qualité.
        <br />
        L’ADOSSPP ne peut être tenue responsable de l&apos;utilisation ou de l&apos;interprétation des informations
        contenues sur le site
        {' '}
        {website}
        .
        <br />
        Seules les informations officielles publiées
        sur
        {' '}
        <a href="http://www.legifrance.gouv.fr" rel="noopener noreferrer" target="_blank">www.legifrance.gouv.fr</a>
        {' '}
        font foi.
      </Typography>

      <br />

      <Typography>
        L’ADOSSPP ne peut être tenue pour responsable du fait que :
        <br />
        Le site
        {' '}
        {website}
        {' '}
        réponde ou non aux exigences de l&apos;ensemble des utilisateurs,
        Les services offerts sur le site
        {' '}
        {website}
        {' '}
        puissent être interrompus de manière ponctuelle,
        Le site
        {' '}
        {website}
        {' '}
        et les documents qu&apos;il permet de télécharger soient exempts de virus
        ou de tout autres éléments nocifs.
      </Typography>

      <p className="bold">Inscription et Compte Utilisateur</p>

      <Typography>
        La création d’un Compte Utilisateur nécessite d’être adhérent à l’ADOSSPP.
        Cette création suppose l’enregistrement de certaines données personnelles de
        l’internaute telles que les nom, prénom, adresse, etc., à défaut desquelles
        l’ouverture du Compte Utilisateur n’est pas possible.
        <br />
        <br />
        L’utilisateur s’engage à communiquer des données conformes à la réalité et exactes.
        Dans l&apos;hypothèse où l&apos;Utilisateur fournirait des informations fausses, inexactes,
        périmées, incomplètes, trompeuses ou de nature à induire en erreur, l’ADOSSPP pourra
        immédiatement, sans préavis ni indemnité, suspendre ou résilier le Compte Utilisateur
        de l&apos;Utilisateur et lui refuser l&apos;accès, de façon temporaire ou définitive à tout ou
        partie des Services.
        <br />
        <br />
        Une fois son Compte Utilisateur créé, l’Utilisateur s’engage à garder son identifiant
        et son mot de passe confidentiels et personnels. L’ADOSSPP ne saurait être tenue responsable
        de la perte des identifiant et/ou mot de passe de l’Utilisateur. L’Utilisateur est seul responsable
        de l’utilisation frauduleuse ou non par des tiers de ses identifiants. Un Utilisateur
        ne peut être titulaire que d’un seul Compte Utilisateur. L’utilisation des Services du Site Web
        est personnelle et destinée exclusivement à des fins non-commerciales.
        L’Utilisateur peut solliciter la fermeture de son compte, à tout moment,
        sans devoir justifier d’un quelconque motif, en faisant la demande auprès de l’ADOSSPP :
        {website}
        .
        <br />
        <br />
        Le Compte Utilisateur sera alors désactivé, cette demande implique la radiation
        de l’association.
        De même, l’ADOSSPP se réserve le droit de prendre toutes les mesures nécessaires,
        y compris la résiliation d’un Compte Utilisateur dans le cas d’une décision judiciaire l’y contraignant,
        d’événements de force majeure, ou encore en cas de suspicion de fraude.
      </Typography>

      <p className="bold">Cookies</p>

      <Typography>
        L’ADOSSPP informe les internautes de l’utilisation de cookies. Un cookie est un petit fichier texte
        déposé sur votre ordinateur lors de votre visite d&apos;un site. Un élément qui ne permet pas d’identifier
        l’utilisateur, mais sert à enregistrer des informations relatives à la navigation de celui-ci
        sur le site internet.
        <br />
        <br />
        Il sert notamment à :
      </Typography>

      <List>
        <ListItem>Enregistrer des informations relatives à la navigation sur le site,</ListItem>
        <ListItem>Permettre l&apos;analyse de l&apos;audience du site,</ListItem>
        <ListItem>Permettre l&apos;utilisation de boutons de partage de contenus sur les réseaux sociaux</ListItem>
        <ListItem>Permettre la lecture directe de vidéos dans les pages du site.</ListItem>
      </List>

      <Typography>
        Vous pouvez, à tout moment, procéder au paramétrage de vos choix relatifs au dépôt de cookies
        en procédant au paramétrage de votre ordinateur afin d’éviter cette collecte.
        <br />
        A noter que la désactivation de certains cookies peut empêcher l’utilisation de certaines fonctionnalités du site.
      </Typography>

      <p className="bold">Cookies liés aux boutons de partage vers les réseaux sociaux</p>

      <Typography>
        Après acceptation, des cookies sont utilisés pour permettre à l&apos;internaute de partager
        des contenus du site sur les réseaux sociaux tels que Twitter ou Facebook.
        <br />
        A noter que la désactivation de certains cookies peut empêcher l’utilisation de certaines fonctionnalités du site.
      </Typography>

      <p className="bold">Cookies liés aux vidéos</p>

      <Typography>
        Après acceptation, des cookies sont utilisés pour permettre la lecture de vidéos
        (dailymotion ou Youtube) directement dans le site.
        A noter que la désactivation de certains cookies peut empêcher l’utilisation de certaines fonctionnalités du site.
      </Typography>

      <p className="bold">Google Analytics</p>

      <Typography>
        L’utilisation du site internet par les internautes est analysée par Google Analytics.
        Cette analyse donne lieu à utilisation de cookies concernant l’utilisation du site, y compris l’adresse IP.
        Ces données sont conservées sur les serveurs de Google aux Etats-Unis et sont susceptibles
        d’être communiquées à des tiers en cas d’obligation légale ainsi qu’à l’ADOSSPP et ce,
        pour les pages des sites spécifiques également.
        <br />
        <br />
        Vous pouvez procéder au paramétrage de votre ordinateur afin d’éviter la collecte de cookies.
        Une telle désactivation peut empêcher l’utilisation de certaines fonctionnalités du site.
        <br />
        <br />
        En utilisant ce site, vous consentez au traitement de vos données nominatives par Google
        dans les conditions et pour les finalités stipulées ci-dessus.
        <br />
        <br />
        En utilisant ce site, vous consentez au traitement de vos données nominatives par Google
        dans les conditions et pour les finalités stipulées ci-dessus.
      </Typography>

      <p className="bold">Données personnelles</p>

      <Typography>
        Des données personnelles sont collectées via le site quand l&apos;internaute utilise
        un des formulaires suivants. Les données collectées servent uniquement à répondre
        à la demande émise via le formulaire et ne sont pas utilisées ultérieurement.
      </Typography>

      <p className="bold">Destinataires des données collectées</p>

      <Typography>
        L’ADOSSPP pour la création et la mise à jour des comptes utilisateurs.
      </Typography>

      <p className="bold">Droits sur les données vous concernant</p>

      <Typography>
        Conformément au règlement général sur la protection des données (RGPD)
        et à la loi « informatique et libertés » du 6 janvier 1978, vous disposez du droit de demander
        l&apos;accès à vos données, leur rectification ou leur portabilité, leur effacement,
        l&apos;opposition au traitement ou sa limitation.
        <br />
        <br />
        Pour exercer ces droits et pour toute interrogation sur vos droits, vous pouvez adresser un message :
        {' '}
        <Link to={PATHS.CONTACT}>via le formulaire de contact</Link>
      </Typography>

      <InfoLine info="ADOSSPP, 39 Boulevard Bourdon – CS 10622 – 75141 Paris Cedex 04" label="ou par courrier à" />
    </Wrapper>
  );
};

export default PageMentions;