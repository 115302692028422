import React, { useCallback, useState } from 'react';
import {
  Button, Card, CardContent, Grid, Typography
} from '@material-ui/core';
import { TabContainer } from 'components/UserProfile/UserProfile';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-use-form-state';
import { CustomCardTitle, Password } from 'components';
import { translate } from 'utils';
import { faKey } from '@fortawesome/pro-regular-svg-icons';
import { useStores } from 'hooks';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { UserService } from 'services';

const ModifyPassword = observer(() => {
  const { userStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [formState, { text }] = useFormState({ });
  const [error, setError] = useState('');
  const [newPasswordRulesErrors, setNewPasswordRulesErrors] = useState([]);

  const handleUpdatePassword = useCallback(e => {
    e.preventDefault();

    if (formState.values.newPassword !== formState.values.newPasswordConfirmation) {
      setError('errors.newPasswordsDifferent');
      return;
    }
    setError('');
    setNewPasswordRulesErrors([]);
    UserService.getNewPassword(formState.values).then(resp => {
      if (!resp.data.isValid) {
        const rulesErrors = [];
        resp.errors.forEach(errorItem => {
          if (errorItem.code === 'E0421') {
            enqueueSnackbar(errorItem.detail, { variant: 'error' });
          } else {
            rulesErrors.push(errorItem.detail);
          }
        });
        setNewPasswordRulesErrors(rulesErrors);
      } else {
        history.push(PATHS.CONNECT);
        userStore.disconnectUser();
      }
    }).catch(err => {
      enqueueSnackbar(err.errorMessage, { variant: 'error' });
    });
  }, [formState.values, userStore, enqueueSnackbar, history]);

  return (
    <TabContainer>
      <form onSubmit={handleUpdatePassword}>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Card>
              <CustomCardTitle
                icon={faKey}
                title="pageUserProfile.password.title"
              />
              <CardContent>
                <Grid container direction="column">
                  <Grid item>
                    <Password
                      formState={formState}
                      label={translate('common.currentPassword')}
                      name="currentPassWord"
                      {...text('currentPassword')}
                    />
                  </Grid>
                  <Grid item>
                    <Password
                      formState={formState}
                      label={translate('common.newPassword')}
                      name="newPassword"
                      {...text('newPassword')}
                    />
                  </Grid>
                  <Grid item>
                    <Password
                      formState={formState}
                      label={translate('common.newPasswordConfirmation')}
                      name="newPasswordConfirmation"
                      {...text('newPasswordConfirmation')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="subtitle2">
                      {translate('common.newPasswordRules')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {error && (
                      <Typography color="error" gutterBottom>
                        {translate(error)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {newPasswordRulesErrors.length > 0 && (
                      newPasswordRulesErrors.map(err => (
                        <Typography color="error" gutterBottom>
                          {err}
                        </Typography>
                      ))
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Button
          aria-label={translate('button.save')}
          color="primary"
          style={{ marginTop: '2rem' }}
          type="submit"
        >
          {translate('button.save')}
        </Button>
      </form>
    </TabContainer>
  );
});

export default ModifyPassword;