import { ALLOWANCE_TYPE_MAPPER, ROUTES } from 'utils/constants';

const getAllowanceDetailUrl = (type, category, categoryMapper, id) => {
  if (!type || !category || !ALLOWANCE_TYPE_MAPPER[type.id]) return null;

  const mainType = ALLOWANCE_TYPE_MAPPER[type.id];
  const mainCat = categoryMapper[category.id];

  return ROUTES[mainType](mainCat, id);
};

const getAllowanceBookingUrl = (type, category, categoryMapper, id) => {
  if (!type || !category) return null;

  const mainCat = categoryMapper[category.id];

  return ROUTES.ALLOWANCE_LEISURE_DETAIL_BOOKING(mainCat, id);
};

const getAllowanceStatus = status => {
  if (status === 0 || status === 1) {
    return 'ongoing';
  }

  if (status === 2) {
    return 'accepted';
  }

  return 'done';
};

export const AllowanceHelper = {
  getAllowanceDetailUrl,
  getAllowanceBookingUrl,
  getAllowanceStatus
};