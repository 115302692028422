import i18n from 'i18next';
import { userStore } from 'stores';
import { SPECIFIC_ROUTE_201 } from 'utils/constants';

import { httpSettings } from './request';

const getErrorTranslation = (code, message) => {
  if (i18n.exists(`errors.${message}`)) {
    return i18n.t(`errors.${message}`);
  }

  return `${i18n.t('errors.UNCATCHED_ERROR')} (${i18n.t('errors.error')} ${code})`;
};
export class HTTPError extends Error {
  constructor(code, message) {
    super(getErrorTranslation(code, message));
    this.statusCode = code;
  }
}

const defaultResponseHandler = async response => {
  const { status, statusText, url } = response;
  switch (status) {
  case 200:
  case 422:
    return response.json();
  case 201: {
    if (SPECIFIC_ROUTE_201.includes(url) || url.includes('Abonnement')) {
      return response.json();
    }
    return true;
  }
  case 204:
    return true;
  case 401:
    return userStore.refreshToken()
      .then(() => setTimeout(() => window.location.reload(), 300))
      .catch(err => console.error(err));
  default:
    throw new HTTPError(status, statusText);
  }
};

const GET = (url, headers) => fetch(url, httpSettings({
  headers,
  method: 'GET'
})).then(defaultResponseHandler);

const UNLOGGED_GET = (url, headers) => fetch(url, httpSettings({
  headers,
  method: 'GET',
  useAPIKey: true
})).then(defaultResponseHandler);

const POST = (url, body = {}, headers = null, disableDefautHeader = false) => fetch(url, httpSettings({
  method: 'POST',
  headers,
  body,
  disableDefautHeader
})).then(defaultResponseHandler);

const UNLOGGED_POST = (url, body = {}, headers = null, disableDefautHeader = false) => fetch(url, httpSettings({
  method: 'POST',
  headers,
  body,
  disableDefautHeader,
  useAPIKey: true
})).then(defaultResponseHandler);

const POST_WITHOUT_HEADER = (url, body = {}) => fetch(url, {
  method: 'POST',
  body
}).then(defaultResponseHandler);

const PUT = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'PUT',
  headers,
  body
})).then(defaultResponseHandler);

const PATCH = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'PATCH',
  headers,
  body
})).then(defaultResponseHandler);

const DELETE = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'DELETE',
  headers,
  body
})).then(defaultResponseHandler);

export const RequestHelper = {
  GET,
  UNLOGGED_GET,
  POST,
  UNLOGGED_POST,
  PUT,
  PATCH,
  DELETE,
  POST_WITHOUT_HEADER
};