import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { translate } from 'utils';
import { observer } from 'mobx-react-lite';
import { AllowanceService } from 'services';

const FilterCategoryContainer = styled.aside`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  &::after {
    content: '';
    position: absolute;
    right: -1rem;
    top: 0;
    bottom: 0;
    width: 70px;
    background: linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,1) 90%);
    pointer-events: none;
  }

  div {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
  }

  button {
    position: relative;
    padding: 6px 16px;
    font-size: 1.6rem;

    &.selected::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 5px solid var(--tertiary-color);
      border-radius: 8px;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;

    button {
      min-width: 100px;
      font-size: 1.4rem;
    }
  }
`;

const FilterCategory = observer(({
  categoryId, filterSelected, setFilterSelected
}) => {
  const [subCategories, setSubCategories] = useState([]);
  const selectFilter = useCallback(filter => setFilterSelected(filter), [setFilterSelected]);

  useEffect(() => {
    if (categoryId) {
      AllowanceService.getAllowanceSubCategories(categoryId).then(resp => {
        setSubCategories(resp.map(r => r.name));
      });
    }
  }, [categoryId]);

  return (
    <FilterCategoryContainer>
      <div>
        <Button
          aria-label={translate('subcategory.SEEALL')}
          className={!filterSelected ? 'selected' : ''}
          variant="text"
          onClick={() => selectFilter(null)}
        >
          {translate('subcategory.SEEALL')}
        </Button>
        {subCategories.length > 0 && subCategories.map(filter => (
          <Button
            aria-label={translate(`subcategory.${filter}`)}
            className={filterSelected === filter ? 'selected' : ''}
            key={filter}
            variant="text"
            onClick={() => selectFilter(filter)}
          >
            {translate(`subcategory.${filter}`)}
          </Button>
        ))}
      </div>
    </FilterCategoryContainer>
  );
});

FilterCategory.propTypes = {
  categoryId: PropTypes.number.isRequired
};

export default FilterCategory;