import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Desktop, LargeWrapper, Mobile } from 'components';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { translate } from 'utils';
import { PATHS } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMapMarkerAlt, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { NavLink } from 'react-router-dom';

const FooterContainer = styled.footer`
  position: relative;
  padding-top: 2rem;
  margin-top: 15rem;
  color: var(--white);
  background: linear-gradient(90deg, var(--secondary-color) 60%, var(--secondary-color-light) 100%);
  border-top: 5px solid var(--primary-color);
  z-index: var(--zindexBig);

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -84px;
    width: 240px;
    height: 200px;
    background: url('/assets/images/bg/footer.png') no-repeat right top;
    background-size: contain;
  }

  a {
    color: var(--white);
    text-transform: uppercase;
    text-decoration: underline;
  }

  .MuiGrid-item {
    margin-bottom: 2rem;
  }

  @media (max-width: 1200px) {
    padding-top: 6rem;
  }

  @media (max-width: 768px) {
    padding-top: 10rem;
    padding-bottom: 4rem;
  }
`;

const StyledFooterInfo = styled.div`
  color: var(--white);

  p:first-child {
    font-family: 'ArimoBold';
    text-transform: uppercase;
  }
`;

const FooterInfo = ({ title, children }) => (
  <StyledFooterInfo>
    <Typography>
      {translate(title)}
    </Typography>
    <Typography variant="body2">
      {children}
    </Typography>
  </StyledFooterInfo>
);

const Footer = observer(({ isTunnel }) => (
  <>
    {!isTunnel && (
      <FooterContainer>
        <LargeWrapper>
          <Grid alignItems="flex-start" container justify="space-between">
            <Grid item>
              <FooterInfo title="footer.openingTimes">
                Du lundi au jeudi : 8h00-12h00 et 14h00-17h15
                <br />
                Le vendredi : 8h00-13h00
              </FooterInfo>
            </Grid>

            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <FooterInfo title="footer.address">
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=39 Boulevard Bourdon – 75141 Paris Cedex 04&zoom=14"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <FontAwesomeIcon className="mr1" icon={faMapMarkerAlt} />
                      39 Boulevard Bourdon – CS 10622 – 75141 Paris Cedex 04
                    </a>
                  </FooterInfo>
                </Grid>
                <Grid item>
                  <FooterInfo title="footer.phone">
                    <a href="tel:0147178717" rel="noreferrer noopener" target="_blank">
                      <FontAwesomeIcon className="mr1" icon={faPhone} />
                      01 47 17 87 17
                    </a>
                  </FooterInfo>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <NavLink to={PATHS.CONTACT}>
                    {translate('footer.contact')}
                    <FontAwesomeIcon className="ml1" icon={faChevronRight} />
                  </NavLink>
                </Grid>
                <Grid item>
                  <NavLink to={PATHS.MENTIONS}>
                    {translate('footer.legal')}
                    <FontAwesomeIcon className="ml1" icon={faChevronRight} />
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>

            <Grid item />
          </Grid>
        </LargeWrapper>
      </FooterContainer>
    )}

    <Grid alignItems="center" container justify="center" style={{ padding: '3rem 0' }}>
      <a href="http://www.intrassoc.com/" rel="noreferrer noopener" target="_blank">
        <Desktop>
          <img
            alt={translate('menu.logoAlt')}
            src="/assets/images/bg/footer-intrassoc.png"
            width="100%"
          />
        </Desktop>
        <Mobile>
          <img
            alt={translate('menu.logoAlt')}
            src="/assets/images/bg/footer-intrassoc-mobile.png"
            width="100%"
          />
        </Mobile>
      </a>
    </Grid>
  </>
));

export default Footer;