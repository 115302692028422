import React from 'react';

import {
  Grid
} from '@material-ui/core';
import {
  Desktop,
  Mobile
} from 'components';

import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isMobile, translate } from 'utils';
import { PATHS } from 'utils/constants';

const HeaderContainer = styled.div`
  position: relative;
  background-color: var(--white);
  box-shadow: 0 0px 10px rgba(0,0,0,0.2);
  z-index: var(--zindexBig);
  overflow: hidden;

  .logo {
    width: 120px;
    margin-right: 4rem;
  }

  .background {
    background-image: url("/assets/images/bg/header-wizard.png");
    background-repeat: no-repeat;
    background-size:100% 100%;
  }

  .readingImg {
    position: relative;
    width: 100px;
    height: 50px;
    border-radius: 10px;
  }

  @media (max-width: 990px) {
    .MuiGrid-item {
      padding: 4px;
    }

    .logo {
      width: 70px;
      margin: 0.5rem 1rem;
    }
  }

  @media (max-width: 560px) {
    .logo {
      width: 50px;
      margin: 0;
    }
  }
`;

const ImgReading = styled.img`
    z-index: 10000;
    position: relative;
    top: -90px;
    right: -70%;
    width: 400px;
    height: 150px;
`;

const Title = styled.div`
    position: relative;
    right: -15%;
    top: 25%;
    color: var(--white);
    font-size: 40px;
`;

const HeaderUnlogged = observer(() => (
  <header>
    <HeaderContainer>
      <Grid alignItems="center" container spacing={isMobile() ? 1 : 4} style={{ width: '100%' }} wrap="nowrap">
        <Grid item>
          <Link to={PATHS.HOME}>
            <Desktop>
              <img alt="ADOSSPP" className="logo" src="/assets/images/logo-2021-large.jpg" />
            </Desktop>
            <Mobile>
              <img alt="ADOSSPP" className="logo" src="/assets/images/logo-2021.jpg" />
            </Mobile>
          </Link>
        </Grid>
        <Grid className="background" item style={{ padding: '0', height: '100px' }} xs>
          <Title>
            {translate('subscription.title')}
          </Title>
        </Grid>
      </Grid>
    </HeaderContainer>
    <ImgReading
      alt="readingImg"
      src="/assets/images/man-kid-reading.png"
    />
  </header>
));

export default HeaderUnlogged;