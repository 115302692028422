import React from 'react';
import { Grid } from '@material-ui/core';
import shortid from 'shortid';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

const StyledNews = styled.div`
  position: relative;
  padding: 1rem 5rem;
  border: 1px solid var(--grey-light);
  background: url('/assets/images/bg/header-triangle.png') no-repeat -25px -45px;
  border-radius: 8px;

  &:after {
    content: '';
    position: absolute;
    top: 25px;
    bottom: 0;
    left: 10px;
    border: 1px solid var(--primary-color);
  }
`;

const SkeletonNews = () => (
  <StyledNews>
    <Skeleton height={30} />
    <Skeleton height={20} />
  </StyledNews>
);

export const SkeletonNewsList = () => (
  <Grid container direction="column" spacing={2}>
    {[...Array(3)].map(() => (
      <Grid item key={shortid.generate()} xs={12}>
        <SkeletonNews />
      </Grid>
    ))}
  </Grid>
);

export default SkeletonNews;