import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getAllowanceList = () => RequestHelper.GET(API_ROUTES.ALLOWANCES());

const getAllowanceListByTypeAndCategory = (typeId, categoryId) => (
  RequestHelper.GET(API_ROUTES.ALLOWANCES_TYPE_CATEGORY(typeId, categoryId))
);

const getAllowanceDetail = id => RequestHelper.GET(API_ROUTES.ALLOWANCE_DETAIL(id));

const getAllowanceCategories = typeId => RequestHelper.GET(API_ROUTES.ALLOWANCE_CATEGORIES(typeId));

const getAllowanceSubCategories = categoryId => RequestHelper.GET(API_ROUTES.ALLOWANCE_SUB_CATEGORIES(categoryId));

const getAllowancePaymentMeans = typeId => RequestHelper.GET(API_ROUTES.ALLOWANCE_PAYMENT_MEANS(typeId));

const getAllowanceHighSeasons = () => RequestHelper.GET(API_ROUTES.ALLOWANCE_HIGH_SEASONS());

const getAllowanceModules = () => RequestHelper.GET(API_ROUTES.ALLOWANCE_MODULES());

const getAllowancePaymentModes = () => RequestHelper.GET(API_ROUTES.ALLOWANCE_PAYMENT_MODES());

const getAllowanceTariffSections = () => RequestHelper.GET(API_ROUTES.ALLOWANCE_TARIFF_SECTIONS());

// POST
const getBookings = ({ date }) => RequestHelper.POST(API_ROUTES.ALLOWANCE_BOOKING(), { date });

const createAllowance = allowance => RequestHelper.POST(API_ROUTES.ALLOWANCES(), allowance);

const getAvailabilities = request => RequestHelper.POST(API_ROUTES.ALLOWANCE_AVAILABILITIES(), request);

const getAvailabilitiesByHalfDay = request => RequestHelper.POST(API_ROUTES.ALLOWANCE_AVAILABILITIES_BY_HALF_DAY(), request);

// PUT
const updateAllowance = (id, allowance) => RequestHelper.PUT(API_ROUTES.ALLOWANCE_DETAIL(id), allowance);

// DELETE
const deleteAllowance = id => RequestHelper.DELETE(API_ROUTES.ALLOWANCE_DETAIL(id));

const AllowanceService = {
  getAllowanceList,
  getAllowanceListByTypeAndCategory,
  getAllowanceDetail,
  getAllowanceCategories,
  getAllowanceSubCategories,
  getAllowancePaymentMeans,
  getAllowanceHighSeasons,
  getAllowanceModules,
  getAllowancePaymentModes,
  getAllowanceTariffSections,
  getBookings,
  createAllowance,
  updateAllowance,
  deleteAllowance,
  getAvailabilities,
  getAvailabilitiesByHalfDay,
};

export default AllowanceService;