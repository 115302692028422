import { DAY_TIMES } from 'utils/constants';

const removeUTC = date => {
  if (date) {
    return date.replace('Z', '');
  }
  return '';
};

const getHalfDayfromDate = date => (date.getHours() === 12 ? DAY_TIMES.EVENING : DAY_TIMES.MORNING);

const checkIfBetweenTwoDates = (dateFrom, dateTo, dateCheck) => (dateCheck >= dateFrom && dateCheck <= dateTo);

const checkIfBetweenTwoDatesWithoutHours = (dateFrom, dateTo, dateCheck) => {
  dateFrom.setHours(0, 0, 0, 0);
  dateTo.setHours(0, 0, 0, 0);
  dateCheck.setHours(0, 0, 0, 0);
  return (dateCheck >= dateFrom && dateCheck <= dateTo);
};

const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const dateToDDMMYYYY = date => {
  const dateParts = date.split('/');
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};

export const DateHelper = {
  removeUTC,
  getHalfDayfromDate,
  checkIfBetweenTwoDates,
  checkIfBetweenTwoDatesWithoutHours,
  addDays,
  dateToDDMMYYYY
};