import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const getConnectionUrl = () => RequestHelper.GET(API_ROUTES.GLADY_ACCESS_URL());
const addBeneficiary = () => RequestHelper.POST(API_ROUTES.GLADY_ACCESS_URL());

const GladyService = {
  getConnectionUrl,
  addBeneficiary
};

export default GladyService;