import React, { createRef, useCallback } from 'react';
import { IconButton, ThemeProvider } from '@material-ui/core';
import { ModalProvider } from 'hooks';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes/routes';
import { isMobile, translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import SimpleReactLightbox from 'simple-react-lightbox';

import ErrorBoundary from './ErrorBoundary';
import ADOSSPP_THEME from './ADOSSPP_THEME';

const App = () => {
  const notistackRef = createRef();

  const onClickDismiss = useCallback(key => () => {
    notistackRef.current.closeSnackbar(key);
  }, [notistackRef]);

  const displayCloseButton = useCallback(key => (
    <IconButton aria-label={translate('button.close')} onClick={onClickDismiss(key)}>
      <FontAwesomeIcon color="var(--white)" icon={faTimes} size="sm" />
    </IconButton>
  ), [onClickDismiss]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={ADOSSPP_THEME}>
        <SnackbarProvider
          action={displayCloseButton}
          dense={isMobile()}
          maxSnack={3}
          ref={notistackRef}
        >
          <ModalProvider>
            <SimpleReactLightbox>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
            </SimpleReactLightbox>
          </ModalProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;