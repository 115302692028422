/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FormHelper, translate } from 'utils';
import { useFormState } from 'react-use-form-state';
import { faLocationDot } from '@fortawesome/sharp-regular-svg-icons';
import { FormAddress } from 'components/_commons';

import { SubscriptionHelper } from 'utils/helpers';
import SectionTitle from './SectionTitle';
import PersonalInfos from './PersonalInfos';

const useStyles = makeStyles(() => ({
  price: {
    marginRight: '50px',
    color: 'var(--black)'
  }
}));

const Subscription = ({ formState, subscriptionData, prices }) => {
  const classes = useStyles();
  const [subscriptionFormState, {
    text, select, raw
  }] = useFormState({
    country: FormHelper.getDefaultCountry(),
    civility: 1,
    isValid: true,
    ...subscriptionData
  });

  const handleNpaiChange = (
    event,
    newNpai
  ) => {
    subscriptionFormState.setField('npai', newNpai);
  };

  const handleSaveSubscription = () => {
    const newSubscriptions = [...formState.values.subscriptions];
    newSubscriptions.splice(subscriptionFormState.values.id - 1, 1, subscriptionFormState);
    formState.setField('subscriptions', newSubscriptions);
  };

  useEffect(() => {
    if (!subscriptionData.values) {
      subscriptionFormState.setField('country', FormHelper.getDefaultCountry());
      subscriptionFormState.setField('civility', 1);
      subscriptionFormState.setField('isValid', true);
      subscriptionFormState.setField('idRevue', 1);
      handleSaveSubscription();
    }
  }, []);

  useEffect(() => {
    handleSaveSubscription();
  }, [subscriptionFormState.values]);

  const isFrance = useCallback(() => {
    if (subscriptionFormState.values.npai === 'true') {
      return formState.values.country.value === 'France';
    }
    if (subscriptionFormState.values.npai === 'false') {
      return subscriptionFormState.values.country.value === 'France';
    }
  }, [subscriptionFormState.values, formState.values]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <SectionTitle
          icon={faLocationDot}
          label={`${translate('subscription.deliveryAddress')} ${subscriptionFormState.values.id}`}
        />
      </Grid>
      <Grid item xs={11}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Grid alignItems="center" container spacing={2}>
              <Grid item>
                {`${translate('subscription.npai')}*`}
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  exclusive
                  value={subscriptionFormState.values.npai}
                  onChange={handleNpaiChange}
                >
                  <ToggleButton value="true">
                    {translate('subscription.yes')}
                  </ToggleButton>
                  <ToggleButton value="false">
                    {translate('subscription.no')}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.price} item>
            <Grid alignItems="flex-end" container direction="column" spacing={1}>
              <Grid item style={{ fontSize: '20px', fontWeight: 'bold' }}>{`${SubscriptionHelper.getSubscriptionRevue(prices, isFrance()).prixAdherent}€`}</Grid>
              <Grid item>
                {isFrance()
                  ? translate('subscription.franceTariff')
                  : translate('subscription.foreignTariff')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {subscriptionFormState.values.npai === 'false' && (
        <Grid item xs={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <PersonalInfos formState={subscriptionFormState} select={select} text={text} />
            </Grid>
            <Grid item>
              <FormAddress formState={subscriptionFormState} prices={prices} raw={raw} required select={select} text={text} variant="outlined" />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Subscription;