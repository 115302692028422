import React, { useEffect, useState } from 'react';
import {
  CardAllowance, FilterCategory, SkeletonAllowanceCardList, SkeletonNoAllowance, Wrapper,
  FranceBillet, MisterflyBillet
} from 'components';
import { translate } from 'utils';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { PATHS } from 'utils/constants';
import Glady from 'components/_commons/Glady/Glady';

const PageAllowanceCategory = observer(() => {
  const { pathname } = useLocation();
  const { allowanceStore } = useStores();
  const {
    allowanceListFiltered, isLoading, allowanceCategoryMapperReverse, leisureAllowanceCategories, socialAllowanceCategories
  } = allowanceStore;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [categoryId, setCategoryId] = useState();
  const [filterSelected, setFilterSelected] = useState(null);

  const category = pathname.split('/')[1];
  const subCategory = pathname.split('/')[2];

  const isLeisure = pathname.indexOf('loisirs') > -1;
  const error = isLeisure ? 'errors.noAllowanceLeisure' : 'errors.noAllowanceSocial';

  useEffect(() => {
    const typeId = isLeisure ? 2 : 1;

    setCategoryId(allowanceCategoryMapperReverse[subCategory]);

    if (categoryId && loading) {
      setFilterSelected(null);
      allowanceStore.getAllowanceListByTypeAndCategory(typeId, categoryId)
        .then(() => setLoading(false))
        .catch(() => enqueueSnackbar(translate('errors.failedAllowanceCategory'), { variant: 'error' }));
    } else {
      let promise = new Promise(resolve => { resolve(); });
      switch (`/${category}`) {
      case PATHS.ALLOWANCE_LEISURE_MAIN:
        if (!leisureAllowanceCategories) { promise = allowanceStore.getLeisureAllowanceCategories(); }
        break;
      case PATHS.ALLOWANCE_SOCIAL_MAIN:
        if (!socialAllowanceCategories) { promise = allowanceStore.getSocialAllowanceCategories(); }
        break;
      default:
        break;
      }
      promise.then(() => {
        const categoryIdTmp = allowanceCategoryMapperReverse[subCategory];
        setCategoryId(categoryIdTmp);
        setFilterSelected(null);
        allowanceStore.getAllowanceListByTypeAndCategory(typeId, categoryIdTmp)
          .then(() => setLoading(false))
          .catch(() => enqueueSnackbar(translate('errors.failedAllowanceCategory'), { variant: 'error' }));
      });
    }
    // eslint-disable-next-line
  }, [subCategory, leisureAllowanceCategories, socialAllowanceCategories]);

  useEffect(() => {
    allowanceStore.filterAllowanceList(filterSelected);
  }, [allowanceStore, filterSelected]);

  return (
    <Wrapper>
      {/* Temporary */}
      {/* <PageTitle title={title} /> */ }
      {pathname.indexOf('loisirs') === -1
      && <br />}
      {/* Temporary */}

      {subCategory === 'voyages_sejours' && (
        <>
          <br />
          <MisterflyBillet />
        </>
      )}

      {subCategory === 'sorties-culturelles' && (
        <>
          <br />
          <FranceBillet />
        </>
      )}

      {subCategory === 'bons-plans' && (
        <>
          <br />
          <Glady />
        </>
      )}

      {(pathname.indexOf('loisirs') > -1 && categoryId) && (
        <FilterCategory
          categoryId={categoryId}
          filterSelected={filterSelected}
          setFilterSelected={setFilterSelected}
        />
      )}

      {isLoading ? <SkeletonAllowanceCardList fullItem={isLeisure} /> : (
        <Grid container justify="center" spacing={2}>
          {(allowanceListFiltered && allowanceListFiltered.length) > 0
            ? (allowanceListFiltered.map(allowance => (
              <Grid item key={allowance.id} sm={4} xs={12}>
                <CardAllowance {...allowance} />
              </Grid>
            ))) : <SkeletonNoAllowance error={error} />}
        </Grid>
      )}
    </Wrapper>
  );
});

export default PageAllowanceCategory;