import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import shortid from 'shortid';

const Tag = styled.div`
  display: inline-flex;
  padding: 0.5rem;
  text-transform: uppercase;
  font-family: 'ArimoBold';
  font-size: 1.2rem;
  color: var(--black);
  border-radius: 2px;
  background-color: var(--grey-lighter);
`;

const TagList = ({
  tags, justify
}) => tags && tags.length > 0 && (
  <Grid
    alignItems="center"
    container
    justify={justify}
    spacing={1}
    style={{ marginTop: '0.5rem' }}
  >
    {tags.map(tag => (
      <Grid item key={shortid.generate()}>
        <Tag>{tag}</Tag>
      </Grid>
    ))}
  </Grid>
);

TagList.propTypes = {
  justify: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
};

TagList.defaultProps = {
  justify: 'center'
};

export default TagList;