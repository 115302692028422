import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4'; // DOCS: https://www.npmjs.com/package/react-ga4
import { GA4_TAG_ID } from 'utils/constants';

const GoogleAnalytics = () => {
  const history = useHistory();
  const { location } = history;

  useEffect(() => {
    ReactGA.initialize(GA4_TAG_ID);
  }, []);

  useEffect(() => {
    // Transform current url from ie: '/stocks/stats' to 'stocks-stats'
    const urlTitle = location.pathname.split('/').join('-').substring(1);

    document.title = `ADOSSPP ${urlTitle}`;

    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
      title: `ADOSSPP ${urlTitle}`
    });
  }, [location]);

  return null;
};

export default GoogleAnalytics;