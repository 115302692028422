import { createMuiTheme } from '@material-ui/core';

const ADOSSPP_THEME = createMuiTheme({
  palette: {
    primary: {
      main: '#e30713',
      dark: '#ab1009'
    },
    secondary: {
      main: '#007db3',
      dark: '#0b5897'
    },
    error: {
      main: '#dc004e'
    }
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: 'inherit',
    h1: {
      fontSize: '3.2rem'
    },
    h2: {
      fontSize: '2.8rem'
    },
    h3: {
      fontSize: '2.4rem'
    },
    h4: {
      fontSize: '2.2rem'
    },
    h5: {
      fontSize: '1.8rem'
    }
  },
  props: {
    MuiButton: {
      variant: 'contained'
    },
    MuiTextField: {
      color: 'secondary'
    },
    MuiCard: {
      elevation: 3
    }
  },
  overrides: {
    MuiButton: {
      text: {
        color: '#007db3'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#007db3'
      }
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#007db3'
      }
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#007db3'
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '1.6rem'
      }
    }
  },
  breakpoints: {
    values: {
      sm: 768
    }
  }
});

export default ADOSSPP_THEME;