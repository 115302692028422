/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import {
  DialogActions, DialogContent, Button, Grid, Typography
} from '@material-ui/core';
import { translate } from 'utils';

import { ModalHeader } from './_ModalHeader';

export const SubscriberExistsModal = ({
  onClose, onProceed, subscriber, onCancel
}) => {
  const handleProceed = useCallback(() => {
    onClose();
    onProceed();
  }, [onProceed]);

  const handleClose = useCallback(() => {
    onClose();
    onCancel();
  }, [onProceed]);

  return (
    <form style={{ maxWidth: '800px' }}>
      <ModalHeader className="subscriberExistsModal" onClose={handleClose}>
        {translate('subscription.subscriberExistsModalTitle')}
      </ModalHeader>

      <DialogContent style={{ minHeight: '100px' }}>
        <Typography>
          {`${translate('subscription.subscriberAlreadyExists1')} ${subscriber.lastName} ${subscriber.firstName} (${subscriber.email}) ${translate('subscription.subscriberAlreadyExists2')}`}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Grid container justify="space-between">
          <Button
            aria-label={translate('button.cancel')}
            variant="text"
            onClick={handleClose}
          >
            {translate('button.cancel')}
          </Button>
          <Button
            aria-label={translate('button.connect')}
            variant="text"
            onClick={handleProceed}
          >
            {translate('button.connect')}
          </Button>
        </Grid>
      </DialogActions>
    </form>
  );
};