import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import shortid from 'shortid';
import { translate } from 'utils';
import { Desktop, Mobile } from 'components';
import { useStores } from 'hooks';
import { PATHS } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledSubMenu = styled.nav`
  height: 75px;
  background: var(--secondary-color) url('/assets/images/bg/menu.png') no-repeat center;
  background-size: cover;
`;

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  padding: 1rem 3rem;
  font-size: 1.4rem;
  font-family: 'Arimo';
  color: var(--white);
  text-transform: uppercase;

  &.active,
  &:hover {
    color: var(--white);
    text-decoration: none;
    font-family: 'ArimoBold';
    background: rgba(255, 255, 255, 0.2);

    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 0;
      transition: all var(--transitionTime);
      border-bottom: 5px solid rgba(255,255,255,0.8);
    }

    svg {
      color: var(--primary-color);
    }
  }

  svg {
    margin-right: 1rem;
    padding: 0.5rem;
    color: var(--white);
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;

    svg {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
    font-size: 1.1rem;
  }
`;

const HeaderSubMenu = () => {
  const { pathname } = useLocation();
  const subMenu = pathname.split('/')[1];
  const { allowanceStore } = useStores();
  const { subMenuItems } = allowanceStore;
  const [subMenuItemsArray, setSubMenuItemsArray] = useState([]);

  useEffect(() => {
    switch (`/${subMenu}`) {
    case PATHS.ALLOWANCE_LEISURE_MAIN:
      allowanceStore.getLeisureAllowanceCategories().then(() => {
        setSubMenuItemsArray(subMenuItems[`/${subMenu}`]);
      });
      break;
    case PATHS.ALLOWANCE_SOCIAL_MAIN:
      allowanceStore.getSocialAllowanceCategories().then(() => {
        setSubMenuItemsArray(subMenuItems[`/${subMenu}`]);
      });
      break;
    default:
      setSubMenuItemsArray([]);
      break;
    }
    // eslint-disable-next-line
  }, [subMenu]);

  return (
    <StyledSubMenu>
      <Grid alignItems="flex-start" container justify="center">
        {subMenuItemsArray && subMenuItemsArray.map(link => (
          <Grid container item justify="center" key={shortid.generate()} xs>
            <StyledLink activeClassName="active" to={link.url}>
              <FontAwesomeIcon icon={link.icon} size="3x" />
              <Desktop>{translate(link.label)}</Desktop>
              <Mobile>{translate(link.labelMobile)}</Mobile>
            </StyledLink>
          </Grid>
        ))}
      </Grid>
    </StyledSubMenu>
  );
};

export default HeaderSubMenu;