import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FormCheckboxContainer, FormSectionTitle, TextLine
} from 'components';
import { useSnackbar } from 'notistack';
import { useFormState } from 'react-use-form-state';
import {
  Button, Checkbox, FormControlLabel, Grid, InputLabel, List, ListItem,
  Select, Step, StepButton, StepContent, Stepper, TextField, Typography
} from '@material-ui/core';
import {
  faCalendarAlt, faEnvelope, faInfoSquare, faMapMarkerAlt, faPhone, faUsers
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils';
import { ROUTES } from 'utils/constants';
import { AllowanceLeisureContainer } from 'components/_commons';

const ExternOption = ({ allowance }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [formState, {
    number, checkbox, select
  }] = useFormState({
    adults: 2
  });
  const category = pathname.split('/')[2];

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBooking = useCallback(e => {
    e.preventDefault();
    enqueueSnackbar(translate('confirms.bookingDone'), { variant: 'success' });
    history.push(ROUTES.ALLOWANCE_LEISURE_DETAIL_CONFIRM(category, allowance.id));
  }, [formState, history, category, enqueueSnackbar, allowance.id]);

  return (
    <AllowanceLeisureContainer onSubmit={handleBooking}>
      <TextLine className="bold">Pour procéder à la réservation, réaliser dans l’ordre les étapes ci-dessous : </TextLine>

      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepButton
            icon={<FontAwesomeIcon icon={faCalendarAlt} size="2x" />}
            onClick={() => setActiveStep(0)}
          >
            1. D’abord, posez une option auprès d&apos;un prestataire externe.
          </StepButton>

          <StepContent>
            <Grid container direction="column" spacing={2}>
              {/* <Grid item sm={6} xs={12}>
                  <Typography>Séléctionnez le mois concerné pour votre séjour :</Typography>

                  <Datepicker
                    disablePast
                    label="common.bookingDate"
                    labelFunc={handleParseDate}
                    leftArrowIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                    openTo="month"
                    required
                    value={bookingDate}
                    views={['year', 'month']}
                    onChange={date => setBookingDate(date)}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Typography>Séléctionnez ensuite le nombre d’adultes et d’enfants :</Typography>

                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="adults"
                        label={translate('common.nbAdults')}
                        name="adults"
                        required
                        {...number('adults')}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="children"
                        label={translate('common.nbChildren')}
                        name="children"
                        required
                        {...number('children')}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}

              {/* <Grid item>
                  <Button color="secondary" onClick={handleDisplayBooking}>
                    {translate('button.viewBooking')}
                  </Button>
                </Grid> */}

              <Grid item>
                <List>
                  <ListItem disableGutters>
                    Allez tout d&apos;abord sur ce site :
                    <a
                      href="https://www.odalys-vacances.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Odalys
                    </a>
                  </ListItem>
                  <ListItem disableGutters>
                    Laisser-vous guider sur le site internet dédié en selectionnant votre hébergement,
                    vos dates et vos coordonnées.
                  </ListItem>
                  <ListItem disableGutters>
                    Répondez &quot;oui&quot; à la phrase : Vous pouvez prétendre à une subvention de votre CE.
                  </ListItem>
                  <ListItem disableGutters>
                    Sélectionnez votre tranche tarifaire.
                  </ListItem>
                </List>

                <Button color="secondary" onClick={() => setActiveStep(1)}>
                  {translate('button.putOption')}
                </Button>
              </Grid>
            </Grid>
          </StepContent>
        </Step>
        <Step>
          <StepButton
            icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
            onClick={() => setActiveStep(1)}
          >
            2. Enfin, saisissez les informations des occupants pour finaliser votre demande.
          </StepButton>

          <StepContent>
            <Grid container direction="column" spacing={2}>
              <Grid item sm={6} xs={12}>
                <FormSectionTitle title={translate('common.people')} />
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel id="person1">Occupant 1 (ADULTE)</InputLabel>
                    <Select
                      color="secondary"
                      fullWidth
                      labelId="person1"
                      native
                      variant="outlined"
                      {...select('person1')}
                    >
                      <option aria-label={translate('common.none')} value="" />
                      <option value="pierre">Pierre Dupont</option>
                      <option value="marie">Marie Dupont</option>
                    </Select>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel id="person2">Occupant 2 (ADULTE)</InputLabel>
                    <Select
                      color="secondary"
                      fullWidth
                      labelId="person2"
                      native
                      variant="outlined"
                      {...select('person2')}
                    >
                      <option aria-label={translate('common.none')} value="" />
                      <option value="pierre">Pierre Dupont</option>
                      <option value="marie">Marie Dupont</option>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <InputLabel id="person3">Occupant 3 (ENFANT)</InputLabel>
                    <Select
                      color="secondary"
                      fullWidth
                      labelId="person3"
                      native
                      variant="outlined"
                      {...select('person3')}
                    >
                      <option aria-label={translate('common.none')} value="" />
                      <option value="elodie">Élodie Dupont</option>
                      <option value="lucas">Lucas Dupont</option>
                    </Select>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <InputLabel id="person4">Occupant 4 (ENFANT)</InputLabel>
                    <Select
                      color="secondary"
                      fullWidth
                      labelId="person4"
                      native
                      variant="outlined"
                      {...select('person4')}
                    >
                      <option aria-label={translate('common.none')} value="" />
                      <option value="elodie">Élodie Dupont</option>
                      <option value="lucas">Lucas Dupont</option>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <FormSectionTitle icon={faInfoSquare} title={translate('common.moreInfos')} />
                <TextLine>
                  Afin de mieux répondre à votre demande de subvention et besoins,
                  n&apos;hésitez pas à ajouter des informations complémentaires à votre dossier.
                </TextLine>
                <Typography>Remarques :</Typography>
                <TextField
                  fullWidth
                  id="observations"
                  label={translate('common.observations')}
                  multiline
                  name="observations"
                  rows={3}
                  variant="outlined"
                  {...number('observations')}
                />
              </Grid>

              <Grid item>
                <FormSectionTitle icon={faMapMarkerAlt} title={translate('common.yourContact')} />
                <Grid alignItems="center" container spacing={4}>
                  <Grid item>
                    <Typography>
                      Pierre Dupont
                      <br />
                      39 Boulevard Bourdon – CS 10622
                      <br />
                      75141 Paris Cedex 04
                      <br />
                      <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                      <a href="mailto:pierre.dupont@adosspp.com" rel="noreferrer noopener" target="_blank">
                        pierre.dupont@adosspp.com
                      </a>
                      <br />
                      <FontAwesomeIcon className="mr1" icon={faPhone} />
                      <a href="tel:0645789532" rel="noreferrer noopener" target="_blank">
                        06.45.78.95.32
                      </a>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button color="secondary" variant="outlined">
                      {translate('button.modifyContact')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <FormCheckboxContainer>
                  <FormControlLabel
                    control={<Checkbox required />}
                    label={translate('forms.acceptSalesConditions')}
                    {...checkbox('certifyData')}
                  />
                  <FormControlLabel
                    control={<Checkbox required />}
                    label={translate('forms.certifyPersonalData')}
                    {...checkbox('certifyPersonalData')}
                  />
                </FormCheckboxContainer>
              </Grid>

              <Grid item>
                <Button color="primary" type="submit">
                  {translate('button.confirmBooking')}
                </Button>
              </Grid>
            </Grid>
          </StepContent>
        </Step>
      </Stepper>
    </AllowanceLeisureContainer>
  );
};

export default ExternOption;