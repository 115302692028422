import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, TextField, Typography
} from '@material-ui/core';
import { Datepicker, FormSectionTitle } from 'components';
import { faHospitalUser } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';

const Hospital = ({
  allowance, module, hospital, dateIn, dateOut, timeSpent, disabled
}) => (
  <Grid container direction="column">
    <FormSectionTitle icon={faHospitalUser} title={module.name} />

    <Typography gutterBottom>{allowance.hospital}</Typography>

    <Grid container direction="column" spacing={2}>
      <Grid item sm={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={7} xs={12}>
            <TextField
              disabled={disabled}
              fullWidth
              id="hospital"
              label={translate('common.hospital')}
              name="hospital"
              required
              {...hospital}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <TextField
              disabled={disabled}
              fullWidth
              id="timeSpent"
              label={translate('common.timeSpent')}
              name="timeSpent"
              required
              type="number"
              {...timeSpent}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {translate('common.days')}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Datepicker
              label="common.dateIn"
              {...dateIn}
              disabled={disabled}
              disableFuture
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Datepicker
              label="common.dateOut"
              {...dateOut}
              disabled={disabled}
              disableFuture
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

Hospital.propTypes = {
  allowance: PropTypes.shape({}).isRequired,
  dateIn: PropTypes.shape({}).isRequired,
  dateOut: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  hospital: PropTypes.shape({}).isRequired,
  timeSpent: PropTypes.shape({}).isRequired
};

Hospital.defaultProps = {
  disabled: false
};

export default Hospital;