import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImageZoomContainer = styled.picture`
  display: block;
  width: 100%;
  overflow: hidden;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 50%;
    border-radius: 8px;
    transition: transform var(--transitionTime);
    object-fit: cover;
    cursor: pointer;

    &:hover {
      transform: scale(1.08);
    }
  }

  &[data-firstImage="true"]{
  text-align: center;
    img {
      height: 100%;
    }
  }
`;

const ImageZoom = ({ image }) => (
  <ImageZoomContainer>
    <img alt={image.name} src={image.url} />
  </ImageZoomContainer>
);

ImageZoom.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

export default ImageZoom;