/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import { RGPD_LINK } from 'utils/constants';
import { ModalHeader } from './_ModalHeader';

export const RGPDModal = ({
  onClose, onProceed
}) => {
  const handleProceed = useCallback(() => {
    onClose();
    onProceed();
  }, [onProceed]);

  return (
    <div style={{ maxWidth: '1000px' }}>
      <ModalHeader className="rgpdModal" onClose={onClose}>
        {translate('subscription.rgpdTitle')}
      </ModalHeader>

      <DialogContent>
        <Grid alignItems="center" container spacing={2} wrap="nowrap">
          <Grid item>
            <img alt="ConfirmationPaybox" src="/assets/images/rgpd.png" />
          </Grid>
          <Grid item style={{ height: '100%' }}>
            <Grid container direction="column" wrap="nowrap">
              <Grid item>
                <Grid item>
                  <Typography>
                    {translate('subscription.rgpd1')}
                  </Typography>
                </Grid>
                <br />
                <Grid item>
                  <Typography>
                    {translate('subscription.rgpd2')}
                    <a
                      href={RGPD_LINK}
                      rel="noopener noreferrer"
                      style={{ marginLeft: '5px' }}
                      target="_blank"
                    >
                      {RGPD_LINK}
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="var(--white)" onClick={onClose}>
          {translate('button.cancel')}
        </Button>
        <Button color="secondary" onClick={handleProceed}>
          {translate('subscription.understand&Accept')}
        </Button>
      </DialogActions>
    </div>
  );
};