import React, {
  lazy, Suspense, useCallback, useState
} from 'react';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import {
  Grid, Button, Drawer, Typography
} from '@material-ui/core';
import {
  Desktop, FancyWord, IconProfile, LargeWrapper,
  SkeletonUserMenu, BannerMessage, Mobile
} from 'components';
import { useStores, useAccessRights } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { isMobile, translate } from 'utils';
import { PATHS, ADOSSPP_ROLES } from 'utils/constants';
import HeaderMenu from './HeaderMenu';
import HeaderSubMenu from './HeaderSubMenu';
import HeaderTunnel from './HeaderTunnel';

const UserMenu = lazy(() => import('./UserMenu'));

const HeaderContainer = styled.div`
  position: relative;
  background-color: var(--white);
  box-shadow: 0 0px 10px rgba(0,0,0,0.2);
  z-index: var(--zindexBig);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    right: -46px;
    top: 5px;
    width: 72px;
    height: 72px;
    background: url('/assets/images/bg/header-triangle.png') no-repeat;

    @media (max-width: 920px) {
      display: none;
    }
  }

  .logo {
    width: 120px;
    margin-right: 4rem;
  }

  @media (max-width: 990px) {
    .MuiGrid-item {
      padding: 4px;
    }

    .logo {
      width: 70px;
      margin: 0.5rem 1rem;
    }
  }

  @media (max-width: 560px) {
    .logo {
      width: 50px;
      margin: 0;
    }
  }
`;

const UserConnectedButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  && {
    text-transform: initial;
    text-align: left;
  }
`;

const Header = observer(({ isTunnel, canGoBack }) => {
  const { userStore } = useStores();
  const { isConnected, userConnected } = userStore;
  const isInactive = useAccessRights([ADOSSPP_ROLES.INACTIVE]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = useCallback(() => setToggleMenu(!toggleMenu), [toggleMenu]);

  return (
    <header>
      <HeaderContainer>
        <LargeWrapper>
          <Grid alignItems="center" container justify="space-between" wrap="nowrap">
            <Grid item xs>
              <Grid alignItems="center" container spacing={isMobile() ? 1 : 4} wrap="nowrap">
                <Grid item>
                  <Link to={PATHS.HOME}>
                    <Desktop>
                      <img alt="ADOSSPP" className="logo" src="/assets/images/logo-2021-large.jpg" />
                    </Desktop>
                    <Mobile>
                      <img alt="ADOSSPP" className="logo" src="/assets/images/logo-2021.jpg" />
                    </Mobile>
                  </Link>
                </Grid>
                {isTunnel ? (
                  <Grid item xs>
                    <HeaderTunnel canGoBack={canGoBack} />
                  </Grid>
                ) : (
                  <Grid item>
                    <HeaderMenu />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {isConnected && (
              <Grid item>
                <Grid
                  alignItems="center"
                  container
                  justify="flex-end"
                >
                  <UserConnectedButton
                    data-cy="headerMenu"
                    data-tour="step-header-user"
                    variant="text"
                    onClick={handleToggleMenu}
                  >
                    <Grid alignItems="center" container justify="center" spacing={1}>
                      <Grid item>
                        <IconProfile
                          icon={faBars}
                          src={userConnected.image}
                        />
                      </Grid>
                      <Desktop>
                        <Grid item>
                          <Typography>{userConnected.login}</Typography>
                          <Typography component="legend" style={{ fontFamily: 'OpenSans', letterSpacing: '-0.5px' }}>
                            <FancyWord fontSize="1.4rem">{translate('common.my')}</FancyWord>
                            {' '}
                            {translate('userMenu.data')}
                          </Typography>
                        </Grid>
                      </Desktop>
                    </Grid>
                  </UserConnectedButton>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Drawer anchor="right" open={toggleMenu} onClose={handleToggleMenu}>
            <Suspense fallback={SkeletonUserMenu()}>
              <UserMenu setToggleMenu={setToggleMenu} />
            </Suspense>
          </Drawer>
        </LargeWrapper>
      </HeaderContainer>

      {isInactive && (
        <BannerMessage>
          {translate('pageHome.notActive')}
        </BannerMessage>
      )}

      {!isTunnel && (
        <HeaderSubMenu />
      )}
    </header>
  );
});

export default Header;