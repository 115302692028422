import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getCityByPostalCode = postalCode => RequestHelper.GET(API_ROUTES.CITY_BY_POSTAL_CODE(postalCode));

const UserService = {
  getCityByPostalCode
};

export default UserService;