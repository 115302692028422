import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide } from '@material-ui/core';
import {
  ConfirmModal, ErrorModal, WarningModal,
  RightHolderModal, DeleteAllowanceApplicationModal, SubscriptionModal, RGPDModal, SubscriberExistsModal
} from '.';

const MODAL_TYPES = {
  CONFIRM: ConfirmModal,
  ERROR: ErrorModal,
  WARNING: WarningModal,
  RIGHT_HOLDER: RightHolderModal,
  DELETE_ALLOWANCE_APPLICATION: DeleteAllowanceApplicationModal,
  SUBSCRIPTION: SubscriptionModal,
  RGPD: RGPDModal,
  SUBSCRIBER_EXISTS: SubscriberExistsModal
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// onClose, onSubmit, open, type, disableClose,
const Modal = ({
  onClose, onSubmit, open, type, disableClose, ...rest
}) => {
  const CurrentModal = MODAL_TYPES[type];
  const isFullscreen = document.getElementsByTagName('body')[0].clientWidth <= 768;

  return (
    <Dialog
      disableBackdropClick={disableClose}
      disableEscapeKeyDown={disableClose}
      fullScreen={isFullscreen}
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      transitionDuration={300}
      onClose={onClose}
    >
      <CurrentModal onClose={onClose} onSubmit={onSubmit} {...rest} />
    </Dialog>
  );
};

Modal.propTypes = {
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string
};

Modal.defaultProps = {
  disableClose: true,
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  type: 'CONFIRM'
};

export default Modal;