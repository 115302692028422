/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import {
  Button, 
  Grid, 
  Typography,
} from '@material-ui/core';
import { translate } from 'utils';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';

const MisterflyBilletContainer = styled.div`
  color: var(--black);
  padding: 4rem;
  background: url('/assets/images/bg/banner-misterfly.jpg') no-repeat center;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  position: relative;
`;

const MisterflyBillet = () => {
  const history = useHistory();

  return (
    <MisterflyBilletContainer>
      <Grid container direction="column" spacing={1}>
        <Grid item style={{ borderRadius: '10px', position: 'relative', left: '4px' }}>
          <Typography
            style={{
              fontFamily: 'Arimo',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '20px',
              textAlign: useMediaQuery({ minWidth: 565 }) ? 'left' : '',
              color: '#FFFFFF',
              letterSpacing: '1px',
              marginLeft: 'auto',
              marginRight: 0,
              width: useMediaQuery({ minWidth: 565 }) ? '552px' : 'auto'
            }}
          >
            {useMediaQuery({ minWidth: 565 }) ? translate('misterflyBillet.description') : translate('misterflyBillet.title')}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: useMediaQuery({ minWidth: 565 }) ? 'right' : '' }}>
          <Button
            style={{ 
              backgroundColor: '#4e8abe',
              color: '#FFFFFF', 
              position: 'absolute',
              right: '1rem',
              bottom: 0,
              padding: '2px 4px',
              borderRadius: '5px 5px 0 0',
              boxShadow: 'none'
            }}
            type="submit"
            onClick={() => history.push('/prestations-loisirs/voyages_sejours/82')}
          >
            {translate('misterflyBillet.button')}
          </Button>
        </Grid>
      </Grid>
    </MisterflyBilletContainer>
  );
};

export default MisterflyBillet;