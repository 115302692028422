import { PATHS, ROUTES } from 'utils/constants';

export const allowanceFixture = ({
  id = 1, name = '', url = '', thumbnail = '',
  subcategory = '', location = '', tags = [],
  description = '', info = '', gainDescription = '',
  images = [], address = '', coordinate, status = '',
  phoneNumber1 = '', fax = '', email = '', website = '',
  furtherInformation = '', documentsLabel = '',
  hospital = '', session = '', activityCondition = false,
  fixedAmount = 0, modules = [], paymentMethod = null,
  amountCalculatingMeans = null, type = null, category = null,
  attachments = '', nature = '', ticketPrice = '', markupPrice = '', stock = '',
  advantageTypes, tariffType,
  conditions, cancellationConditions,
  interEstablishments, reservationPeriods,
  cancellationWebSite, grantAmounts,
  step1Title, step1Description, step2Title, limit = ''
} = {}) => ({
  id,
  name,
  url,
  thumbnail,
  location,
  tags,
  description,
  info,
  gainDescription,
  images,
  subcategory,
  status,
  address,
  coordinate,
  phoneNumber1,
  fax,
  email,
  website,
  documentsLabel,
  furtherInformation,
  hospital,
  session,
  activityCondition,
  fixedAmount,
  modules,
  paymentMethod,
  amountCalculatingMeans,
  type,
  category,
  limit,
  nature,
  attachments,
  ticketPrice,
  markupPrice,
  stock,
  advantageTypes,
  tariffType,
  interEstablishments,
  reservationPeriods,
  conditions,
  cancellationConditions,
  cancellationWebSite,
  step1Title,
  step1Description,
  step2Title,
  grantAmounts
});

export const defaultAllowanceSocial = allowanceFixture({
  name: 'Allocation chèque naissance',
  info: 'Pour cet heureux évènement nous vous offrons 60 euros de chèques cadeaux.',
  gainDescription: 'Les chèques cadeaux sont à demander à l’ADOSSPP, avant le premier anniversaire de l’enfant.',
  thumbnail: '/assets/images/fixtures/baby.png',
  url: ROUTES.ALLOWANCE_SOCIAL_DETAIL('enfant', 1),
  furtherInformation: 'Veuillez préciser vos dates de séjours.',
  hospital: 'Informations liées à votre hospitalisation : ',
  session: "Informations liées à mes séances prises en charges dans le cadre d'une prestation : ",
  grantAmounts: [],
  documentsLabel: `Veuillez fournir les pièces justificatives suivantes :<br />
    <ul>
    <li>Acte de naissance</li>
    <li>Livret de famille</li>
    <li>Facture de garde d'enfant</li>
    </ul>`,
  amountCalculatingMeans: {}
});

export const defaultAllowanceLeisure = allowanceFixture({
  id: 1,
  name: 'Disneyland - visite 1 jour / 2 parcs',
  url: PATHS.HOME,
  location: 'Ile de France',
  thumbnail: { id: 4, name: '/assets/images/fixtures/disney.png' },
  tags: ['Subventions'],
  subcategory: 'PARCS',
  category: { id: 7, name: '' },
  type: { id: 2, name: '' },
  gainDescription: 'Test',
  nature: { id: 1, name: '' },
  conditions: 'blabla',
  cancellationConditions: 'blabla',
  cancellationWebSite: 'www.google.fr',
  step1Description: 'blabla',
  coordinate: {},
  grantAmounts: [],
  advantageTypes: [
    {
      id: 1, name: 'Prix réduit'
    }
  ],
  tariffType: { id: 2, name: 'Par semaine et type de prestation' },
  amountCalculatingMeans: { id: 1, name: 'Unique' },
  interEstablishments: [
    {
      id: 0,
      name: 'Truc',
      grantAmounts: [
        {
          id: 0,
          tariffSectionCode: 'T1',
          grantPercentage: 0,
          grantCeiling: 100,
          grantCeilingHighSeason: 250
        },
        {
          id: 1,
          tariffSectionCode: 'T2',
          grantPercentage: 0,
          grantCeiling: 200,
          grantCeilingHighSeason: 250
        }
      ],
      unitCapacity: 3,
      rooms: [
        {
          id: 0,
          name: 'string'
        }
      ]
    },
    {
      id: 0,
      name: 'Truc',
      grantAmounts: [
        {
          id: 0,
          tariffSectionCode: 'T1',
          grantPercentage: 0,
          grantCeiling: 100,
          grantCeilingHighSeason: 250
        },
        {
          id: 1,
          tariffSectionCode: 'T2',
          grantPercentage: 0,
          grantCeiling: 200,
          grantCeilingHighSeason: 250
        }
      ],
      unitCapacity: 3,
      rooms: [
        {
          id: 0,
          name: 'string'
        }
      ]
    },
    {
      id: 0,
      name: 'Truc',
      grantAmounts: [
        {
          id: 0,
          tariffSectionCode: 'T1',
          grantPercentage: 0,
          grantCeiling: 100,
          grantCeilingHighSeason: 250
        },
        {
          id: 1,
          tariffSectionCode: 'T2',
          grantPercentage: 0,
          grantCeiling: 200,
          grantCeilingHighSeason: 250
        }
      ],
      unitCapacity: 3,
      rooms: [
        {
          id: 0,
          name: 'string'
        }
      ]
    }
  ],
  reservationPeriods: [
    {
      id: 0,
      periodStart: '04/01/2021',
      periodEnd: '09/01/2021'
    },
    {
      id: 1,
      periodStart: '10/02/2021',
      periodEnd: '12/01/2021'
    }
  ]
});

export const defaultAllowanceLife = allowanceFixture({
  id: 3,
  name: 'Aide au dépôt de garantie 1er logement BSPP',
  url: '#test',
  thumbnail: '/assets/images/fixtures/3.png'
});

export const allowanceListSocialLife = [
  allowanceFixture({
    id: 1,
    name: 'Aide au dépôt de garantie 1er logement BSPP',
    url: PATHS.HOME,
    thumbnail: '/assets/images/fixtures/3.png',
    location: 'Ile de France',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' },
    limit: {
      type: 'Financial', linkedTo: 'home', number: 10, frequency: 'month'
    }
  }),
  allowanceFixture({
    id: 2,
    name: 'Assistance juridique droit à la famille et du patrimoine',
    url: PATHS.HOME,
    thumbnail: '/assets/images/fixtures/2.png',
    location: 'Ile de France',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' }
  }),
  allowanceFixture({
    id: 3,
    name: 'Médiateur familial ou conseiller conjugual',
    url: PATHS.HOME,
    thumbnail: '/assets/images/fixtures/1.png',
    location: 'Ile de France',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' }
  })
];

export const allowanceListSocialChildren = [
  allowanceFixture({
    name: "Allocation soutien aux familles garde d'enfant -3ans",
    url: ROUTES.ALLOWANCE_SOCIAL_DETAIL('enfant', 1),
    thumbnail: '/assets/images/fixtures/baby2.png'
  }),
  allowanceFixture({
    id: 2,
    name: 'Soutien à la difficulté scolaire',
    url: ROUTES.ALLOWANCE_SOCIAL_DETAIL('enfant', 2),
    thumbnail: '/assets/images/fixtures/student.png'
  }),
  allowanceFixture({
    id: 3,
    name: "Allocation d'éducation handicap -21ans",
    url: ROUTES.ALLOWANCE_SOCIAL_DETAIL('enfant', 3),
    thumbnail: '/assets/images/fixtures/4.png'
  }),
  allowanceFixture({
    id: 4,
    name: 'Allocation chèque naissance',
    url: ROUTES.ALLOWANCE_SOCIAL_DETAIL('enfant', 4),
    thumbnail: '/assets/images/fixtures/baby.png'
  }),
  allowanceFixture({
    id: 5,
    name: 'Soutien au retour maternité',
    url: ROUTES.ALLOWANCE_SOCIAL_DETAIL('enfant', 5),
    thumbnail: '/assets/images/fixtures/baby3.png'
  })
];

export const allowanceListLeisureCompany = [
  allowanceFixture({
    name: 'La Toussuire',
    url: ROUTES.ALLOWANCE_LEISURE_DETAIL('etablissements', 2),
    thumbnail: { id: 1, name: '/assets/images/fixtures/toussuire.png' },
    location: 'Savoie',
    tags: ['Subventions', 'Réductions'],
    subcategory: 'ADOSSPP',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  }),
  allowanceFixture({
    id: 2,
    name: 'La Frossardière',
    url: PATHS.HOME,
    thumbnail: { id: 2, name: '/assets/images/fixtures/frossardiere.png' },
    location: 'Bretagne',
    tags: ['Réductions'],
    subcategory: 'ADOSSPP',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  }),
  allowanceFixture({
    id: 3,
    name: 'VVF Résidence Les Eucalyptus',
    url: PATHS.HOME,
    thumbnail: { id: 3, name: '/assets/images/fixtures/eucalyptus.jpeg' },
    location: 'Antibes',
    tags: ['Réductions'],
    subcategory: 'HOLIDAYS',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  })
];

export const allowanceListLeisureCultural = [
  allowanceFixture({
    id: 1,
    name: 'Disneyland - visite 1 jour / 2 parcs',
    url: PATHS.HOME,
    location: 'Ile de France',
    thumbnail: { id: 4, name: '/assets/images/fixtures/disney.png' },
    tags: ['Subventions'],
    subcategory: 'PARCS',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' },
    gainDescription: 'Test',
    ticketPrice: 10,
    markupPrice: 5,
    stock: 10,
    modules: [],
    nature: { id: 2, name: '' },
    conditions: 'blabla',
    cancellationConditions: 'blabla',
    cancellationWebSite: 'www.google.fr',
    advantageTypes: [
      {
        id: 1, name: 'Prix réduit'
      }
    ],
    tariffType: { id: 2, name: 'Par semaine et type de prestation' },
    amountCalculatingMeans: { id: 1, name: 'Unique' },
    interEstablishments: [
      {
        id: 0,
        name: 'Truc',
        highSeasonTariff: 100,
        lowSeasonTariff: 50,
        grantAmounts: [
          {
            id: 0,
            tariffSectionCode: 'T1',
            grantPercentage: 0,
            grantCeiling: 100
          },
          {
            id: 1,
            tariffSectionCode: 'T2',
            grantPercentage: 0,
            grantCeiling: 200
          }
        ],
        unitCapacity: 3,
        unitNumber: 10
      },
      {
        id: 1,
        name: 'Bidule',
        highSeasonTariff: 50,
        lowSeasonTariff: 25,
        grantAmounts: [
          {
            id: 0,
            tariffSectionCode: 'T1',
            grantPercentage: 0,
            grantCeiling: 100
          },
          {
            id: 1,
            tariffSectionCode: 'T2',
            grantPercentage: 0,
            grantCeiling: 200
          }
        ],
        unitCapacity: 1,
        unitNumber: 20
      },
      {
        id: 2,
        name: 'Machin',
        highSeasonTariff: 200,
        lowSeasonTariff: 100,
        grantAmounts: [
          {
            id: 0,
            tariffSectionCode: 'T1',
            grantPercentage: 0,
            grantCeiling: 200
          },
          {
            id: 1,
            tariffSectionCode: 'T2',
            grantPercentage: 0,
            grantCeiling: 300
          }
        ],
        unitCapacity: 4,
        unitNumber: 30
      }
    ],
    reservationPeriods: [
      {
        id: 0,
        periodStart: '04/01/2021',
        periodEnd: '09/01/2021'
      },
      {
        id: 1,
        periodStart: '10/02/2021',
        periodEnd: '12/01/2021'
      }
    ],
    step1Title: '1. D’abord, posez une option auprès d\'un prestataire externe',
    step1Description: 'Contactez la cerntrale de reservation externe a propos de leur prestation : TRUC au 06-57-65-18-80',
    step2Title: '2. Enfin, saississez les informations des occupants pour finaliser votre demande'
  }),
  allowanceFixture({
    id: 2,
    name: 'Wonderbox',
    url: PATHS.HOME,
    location: 'International',
    thumbnail: { id: 5, name: '/assets/images/fixtures/wonderbox.png' },
    tags: ['Subventions', 'Réductions'],
    subcategory: 'GIFTS',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' },
    gainDescription: 'Test',
    contactInfos: '02-31-28-15-00',
    nature: { id: 2, name: '' }
  }),
  allowanceFixture({
    id: 3,
    name: 'Cabaret',
    url: PATHS.HOME,
    location: 'Normandie',
    thumbnail: { id: 6, name: '/assets/images/fixtures/cabaret.png' },
    tags: ['Subventions', 'Réductions'],
    subcategory: 'SPECTACLE',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  }),
  allowanceFixture({
    id: 4,
    name: 'Astérix - visite journée',
    url: PATHS.HOME,
    location: 'Hauts de France',
    thumbnail: { id: 7, name: '/assets/images/fixtures/asterix.png' },
    tags: ['Subventions', 'Réductions'],
    subcategory: 'PARCS',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  }),
  allowanceFixture({
    id: 5,
    name: 'Aquaboulevard',
    url: PATHS.HOME,
    location: 'Ile de France',
    thumbnail: { id: 8, name: '/assets/images/fixtures/aquaboulevard.png' },
    tags: ['Réductions'],
    subcategory: 'PARCS',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  }),
  allowanceFixture({
    id: 6,
    name: 'Salle de remise en forme',
    url: PATHS.HOME,
    location: 'Ile de France',
    thumbnail: { id: 9, name: '/assets/images/fixtures/sport.png' },
    tags: ['Réductions'],
    subcategory: 'WELLBEING',
    category: { id: 7, name: '' },
    type: { id: 2, name: '' }
  })
];

export const ALLOWANCE_CATEGORY_LIST_MAPPER = {
  vie: allowanceListSocialLife,
  enfant: allowanceListSocialChildren,
  aide: [],
  etablissements: allowanceListLeisureCompany,
  enfants: [],
  voyages: [],
  sorties: allowanceListLeisureCultural,
  bonsPlans: [],
  random: [
    defaultAllowanceSocial, defaultAllowanceLeisure, defaultAllowanceLife
  ]
};