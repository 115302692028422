import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@material-ui/core';
import { Tooltip } from 'components';

const StyledFormSectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 1rem 0;
  padding: 0.5rem 0;
  border-left: 5px solid ${props => props.color};

  svg {
    margin: 0 1rem;
  }

  p {
    font-family: 'ArimoBold';
  }
`;

const FormSectionTitle = ({
  title, icon, color, tooltip
}) => (
  <StyledFormSectionTitle color={color}>
    <FontAwesomeIcon icon={icon} size="lg" />
    <Typography>{title}</Typography>
    {tooltip && <Tooltip label={tooltip} />}
  </StyledFormSectionTitle>
);

FormSectionTitle.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};

FormSectionTitle.defaultProps = {
  color: 'var(--tertiary-color)',
  icon: faUser,
  tooltip: ''
};

export default FormSectionTitle;