

import { useStores, useAccessRights } from 'hooks';
import { ADOSSPP_ROLES } from 'utils/constants';

const useCanBook = (allowanceRoles = []) => {
  const { userStore } = useStores();
  const { userConnected } = userStore;
  const roles = allowanceRoles;
  if (roles.length === 0) {
    roles.push(...Object.values(ADOSSPP_ROLES));
  }
  const allowanceConditionBoolean = useAccessRights(roles);

  return [
    {
      name: 'noTaxNotice',
      value: userConnected.taxNoticeFileName || userConnected.ignoreNoTaxNotice
    },
    {
      name: 'noRIB',
      value: userConnected.ribFileName
    },
    {
      name: 'contributionOutdated',
      value: userConnected.isContributionUpToDate
    },
    {
      name: 'pricingRangeNotUpToDate',
      value: userStore.isPricingRangeUpToDate()
    },
    {
      name: 'notRightRole',
      value: allowanceConditionBoolean
    }
  ];
}

export default useCanBook;