/* ***
** Usage

const Component = () => {
  const {
    add,
    clear,
    removeById,
    removeIndex,
    value: animalList,
    setValue: setAnimalList
  } = useArray(['cat','dog','bird']);
};

*/


import { useState, useCallback } from 'react';

const useArray = initial => {
  const [value, setValue] = useState(initial);

  return {
    value,
    setValue,
    add: useCallback(a => setValue(v => [...v, a]), []),
    clear: useCallback(() => setValue(() => []), []),
    findIndex: useCallback((index, arr) => arr.find((_, i) => i === index), []),
    removeById: useCallback(id => setValue(arr => arr.filter(v => v && v.id !== id)), []),
    removeIndex: useCallback(index => setValue(arr => arr.filter((_, i) => i !== index)), []),
  };
};

export default useArray;