/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button, CircularProgress, Grid, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import { useStores } from 'hooks';
import { FRANCEBILLET_URL, FRANCEBILLET_MODE } from 'utils/constants';
import { FrancebilletService } from 'services';
import { useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';

const FranceBilletContainer = styled.div`
  color: var(--black);
  padding: 3rem;
  background: url('/assets/images/bg/banner-franceBillet.png') no-repeat center;
  background-size: 100% auto;
  background-position: 20% center;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;

  @media (max-width: 1130px) {
    background-size: cover;
  }
`;

const FranceBillet = () => {
  const { userStore } = useStores();
  const { userConnected } = userStore;
  const {
    memberCode
  } = userConnected;
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (`${FRANCEBILLET_MODE}` === 'V2') {
      FrancebilletService.getConnectionUrl().then(resp => {
        setError(false);
        setUrl(`${resp.data}`);
      }).catch(() => {
        setError(true);
        enqueueSnackbar(translate('francebillet.error'), { variant: 'error' });
      });
    } else {
      FrancebilletService.getToken({ memberCode }).then(resp => {
        setError(false);
        setUrl(`${FRANCEBILLET_URL}${resp.token}`);
      }).catch(() => {
        setError(true);
        enqueueSnackbar(translate('francebillet.error'), { variant: 'error' });
      });
    }
  }, [memberCode, enqueueSnackbar]);

  return (
    <FranceBilletContainer>
      <Grid container direction="column" spacing={1}>
        <Grid
          item
          style={{
            borderRadius: '10px',
            position: 'relative',
            left: useMediaQuery({ minWidth: 565 }) ? '40px' : '',
            marginLeft: useMediaQuery({ minWidth: 565 }) ? 'auto' : '',
            marginRight: 0
          }}
        >
          <Typography
            style={{
              fontFamily: 'Arimo',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '20px',
              textAlign: useMediaQuery({ minWidth: 565 }) ? 'left' : '',
              color: '#FFFFFF',
              letterSpacing: '1px',
              width: useMediaQuery({ minWidth: 565 }) ? '472px' : 'auto'
            }}
          >
            {useMediaQuery({ minWidth: 565 }) ? translate('francebillet.description') : translate('francebillet.title')}
          </Typography>
          <Typography
            style={{
              fontFamily: 'Arimo',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              textAlign: useMediaQuery({ minWidth: 565 }) ? 'left' : '',
              color: '#FFFFFF',
              letterSpacing: '1px',
              width: useMediaQuery({ minWidth: 565 }) ? '490px' : 'auto'
            }}
          >
            {useMediaQuery({ minWidth: 565 }) && translate('francebillet.subDescription')}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: useMediaQuery({ minWidth: 565 }) ? 'right' : '' }}>
          {url ? (
            <Button
              style={{ color: '#1D71B8' }}
              type="submit"
              onClick={() => window.open(url, '_blank')}
            >
              {translate('francebillet.button')}
            </Button>
          )
            : error
              ? <Alert severity="error">{translate('francebillet.error')}</Alert>
              : <CircularProgress color="secondary" />}
        </Grid>
        <Grid
          item
          style={{
            borderRadius: '10px',
            position: 'relative',
            left: '20px',
            marginLeft: 'auto',
            marginRight: 0
          }}
        >
          <Typography
            style={{
              fontFamily: 'Arimo',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '16px',
              textAlign: useMediaQuery({ minWidth: 565 }) ? 'left' : '',
              color: '#FFFFFF',
              letterSpacing: '1px',
              width: useMediaQuery({ minWidth: 565 }) ? '472px' : 'auto'
            }}
          >
            {useMediaQuery({ minWidth: 565 }) && translate('francebillet.bottomDescription')}
          </Typography>
        </Grid>
      </Grid>
    </FranceBilletContainer>
  );
};

export default FranceBillet;