/* eslint-disable radix */
import React, { useEffect, useRef, useState } from 'react';
import {
  Card, CardContent, Grid, Select, TextField, Typography
} from '@material-ui/core';
import { TabContainer } from 'components/UserProfile/UserProfile';
import { observer } from 'mobx-react-lite';
import {
  CustomCardTitle, BannerMessage, FormAddress, InfoLine, PayboxPayment
} from 'components';
import { FormHelper, translate } from 'utils';
import styled from 'styled-components';
import {
  PAYMENT_TYPE, SUBSCRIPTION_STATUS, ID_FRANCE_ISO_3166, FRANCE_REVUE_CODE, WORLDWIDE_REVUE_CODE
} from 'utils/constants';
import { faBooksMedical } from '@fortawesome/pro-regular-svg-icons';
import { useStores } from 'hooks';
import { useFormState } from 'react-use-form-state';
import { SubscriptionCard, SubscriptionRow } from 'components/_commons';
import { UserService } from 'services';

import { Alert } from '@material-ui/lab';

const BannerMessageContainer = styled.div`
  padding-bottom: 25px;
`;

const Subscriptions = observer(() => {
  const { userStore } = useStores();
  const [civilities, setCivilites] = useState([]);
  const [isLoadingcivilities, setIsLoadingCivilites] = useState(true);
  const [displayOldSubscriptions, setDisplayOldSubscriptions] = useState(false);
  const [displayExpiredSubscriptions, setDisplayExpiredSubscriptions] = useState(false);
  const [displayRenewedSubscriptions, setDisplayRenewedSubscriptions] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);
  const [oldSubscriptions, setOldSubscriptions] = useState([]);
  const [renewedSubscriptions, setRenewedSubscriptions] = useState([]);
  const [prices, setPrices] = useState([]);
  const cardRef = useRef(null);
  const { userConnected, subscriptionsStatus, isMembership } = userStore;
  const {
    allo18Subsciptions, address, memberCode, email
  } = userConnected;
  const [formState, {
    text, number, raw, select
  }] = useFormState({
    ...address,
    numberOfCopies: 1,
    country: FormHelper.getDefaultCountry(address),
    unitPrice: 10,
    price: 0,
    civility: 1
  });

  useEffect(() => {
    UserService.getSubscriptionsPrices().then(resp => {
      setPrices(resp.data);
    });
  }, []);

  useEffect(() => {
    formState.setField('price', formState.values.unitPrice * formState.values.numberOfCopies);
  }, [formState, formState.values.numberOfCopies, formState.values.unitPrice]);

  const getStatus = id => subscriptionsStatus.find(sub => sub.id === id)?.status;

  useEffect(() => {
    let revue;
    if (formState.values.country === undefined || formState.values.country.id === ID_FRANCE_ISO_3166) {
      revue = prices.find(x => x.codeRevue === FRANCE_REVUE_CODE);
    } else {
      revue = prices.find(x => x.codeRevue === WORLDWIDE_REVUE_CODE);
    }

    if (revue !== undefined) {
      formState.setField('unitPrice', revue.prixAdherent);
    }
  }, [prices, formState, formState.values.country]);

  useEffect(() => {
    UserService.getCivilities().then(resp => {
      setCivilites(resp.data);
    }).finally(() => setIsLoadingCivilites(false));
  }, []);

  useEffect(() => {
    if (prices.length !== 0 && allo18Subsciptions) {
      const subs = [];
      const oldSubs = [];
      const renewedSubs = [];
      const expiredSubs = [];

      allo18Subsciptions.forEach(sub => {
        switch (getStatus(sub.id)) {
        case SUBSCRIPTION_STATUS.OLD:
          if (sub.estRegle) {
            oldSubs.push(sub);
          }
          break;
        case SUBSCRIPTION_STATUS.RENEWED:
          if (sub.estRegle) {
            renewedSubs.push(sub);
          }
          break;
        case SUBSCRIPTION_STATUS.EXPIRED:
          if (sub.estRegle) {
            expiredSubs.push(sub);
          }
          break;
        default:
          subs.push(sub);
          break;
        }
      });
      setOldSubscriptions(oldSubs);
      setRenewedSubscriptions(renewedSubs);
      setExpiredSubscriptions(expiredSubs);
      setSubscriptions(subs);
    }
    // eslint-disable-next-line
  }, [allo18Subsciptions, prices]);

  const createNewSubscription = () => {
    const tariff = parseInt(formState.values.unitPrice);
    const { idRevue } = prices.find(price => price.prixAdherent === tariff);
    return UserService.createSubscription({
      ...formState.values,
      idRevue,
      codeAdherent: memberCode,
      idCiviliteBeneficiaire: formState.values.civility,
      nomBeneficiaire: formState.values.lastName,
      prenomBeneficiaire: formState.values.firstName,
      nombreDeCopies: formState.values.numberOfCopies,
      adresse: {
        ligne1: formState.values.address1,
        ligne2: formState.values.address2,
        ligne3: formState.values.address3,
        lieuDit: null,
        codePostal: formState.values.postalCode,
        ville: formState.values.city,
        pays: formState.values.country.label.toUpperCase(),
        npai: false
      }
    }).then(response => ({
      itemId: response.data,
      confirmParameter: `subscriptionId=${response.data}`,
      price: tariff * formState.values.numberOfCopies
    })).catch(() => undefined);
  };

  return (
    <TabContainer>
      {!isMembership && (
        <BannerMessageContainer>
          {subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.EXPIRED) && (
            <BannerMessage>
              {translate('pageHome.SubscriptionExpired')}
            </BannerMessage>
          )}
          {subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.CLOSE_TO_EXPIRED) && (
            <BannerMessage type="warning">
              {translate('pageHome.SubscriptionCloseToExpired')}
            </BannerMessage>
          )}
          {subscriptionsStatus.find(sub => sub.status === SUBSCRIPTION_STATUS.UNPAYED) && (
            <BannerMessage type="warning">
              {translate('pageHome.SubscriptionUnPayed')}
            </BannerMessage>
          )}
        </BannerMessageContainer>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container id="subscriptionContainer" spacing={2}>
            {subscriptions.length > 0 && subscriptions.map(sub => (
              <SubscriptionCard
                disabled
                key={`subscriptionCard${sub.id}`}
                price={prices.find(price => price.idRevue === sub.idRevue)?.prixAdherent}
                subscription={sub}
                tariff={prices.find(price => price.idRevue === sub.idRevue)}
              />
            ))}
          </Grid>
        </Grid>
        <SubscriptionRow
          disabled
          display={displayExpiredSubscriptions}
          label={{
            hide: 'pageUserProfile.subscriptions.hideExpiredSubscriptions',
            display: 'pageUserProfile.subscriptions.displayExpiredSubscriptions'
          }}
          prices={prices}
          setDisplay={setDisplayExpiredSubscriptions}
          subscriptions={expiredSubscriptions}
        />
        <SubscriptionRow
          disabled
          display={displayOldSubscriptions}
          label={{
            hide: 'pageUserProfile.subscriptions.hideOldSubscriptions',
            display: 'pageUserProfile.subscriptions.displayOldSubscriptions'
          }}
          prices={prices}
          setDisplay={setDisplayOldSubscriptions}
          subscriptions={oldSubscriptions}
        />
        <SubscriptionRow
          disabled
          display={displayRenewedSubscriptions}
          label={{
            hide: 'pageUserProfile.subscriptions.hideRenewedSubscriptions',
            display: 'pageUserProfile.subscriptions.displayRenewedSubscriptions'
          }}
          prices={prices}
          setDisplay={setDisplayRenewedSubscriptions}
          subscriptions={renewedSubscriptions}
        />

        <Grid item>
          {!isMembership && (
            <Card ref={cardRef}>
              <CustomCardTitle
                icon={faBooksMedical}
                title="pageUserProfile.subscriptions.addAllo18Subscription"
              />
              <CardContent>
                <Grid container direction="column">
                  <Grid item xs={3}>
                    <Typography component="legend">
                      {translate('common.civility')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid alignItems="flex-end" container spacing={1}>
                      {!isLoadingcivilities && (
                        <Select
                          color="secondary"
                          fullWidth
                          labelId="civility"
                          native
                          variant="outlined"
                          {...select('civility')}
                        >
                          {civilities.map(civility => (
                            <option key={civility} value={civility.id}>
                              {civility.nomLong}
                            </option>
                          ))}
                        </Select>
                      )}
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        id="lastName"
                        label={translate('common.lastName')}
                        name="lastName"
                        {...text('lastName')}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        id="firstName"
                        label={translate('common.firstName')}
                        name="firstName"
                        {...text('firstName')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <FormAddress formState={formState} raw={raw} text={text} />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      id="numberOfCopies"
                      label={translate('common.numberOfCopiesToReceive')}
                      name="numberOfCopies"
                      {...number('numberOfCopies')}
                    />
                  </Grid>
                  <Grid item>
                    <Typography component="legend">
                      {translate('common.unitPrice')}
                    </Typography>
                    <Select
                      color="secondary"
                      disabled
                      fullWidth
                      labelId="unitPrice"
                      native
                      variant="outlined"
                      {...select('unitPrice')}
                    >
                      {prices.map(price => (
                        <option key={price.idRevue} value={price.prixAdherent}>
                          {`${price.titreRevue} (${price.prixAdherent} €)`}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                  <br />
                  <Grid item>
                    <InfoLine
                      info={`${formState.values.price}€`}
                      label="common.price"
                    />
                  </Grid>
                  <Grid item>
                    {email && (
                      <PayboxPayment
                        buttonId="payboxPaymentButton"
                        buttonLabel="button.add"
                        getPaymentInformations={createNewSubscription}
                        typeId={PAYMENT_TYPE.RESUBSCRIPTION.code}
                      />
                    )}
                    {!email && (
                      <Alert severity="error">{translate('errors.errorPayboxMail')}</Alert>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </TabContainer>
  );
});

export default Subscriptions;