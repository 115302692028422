export const userFixture = ({
  id = 313380,
  civility = 'Mr',
  lastName = 'AZERTY',
  firstName = 'Albert',
  birthdate = '02/01/1980',
  login = 'Albert AZERTY',
  image = '/assets/images/fixtures/pompier.png',
  memberCode = 'DH1013141',
  familyStatus = 'Marié',
  profile = 'withAccount',
  job = 'CEO',
  jobSecondary = 'Développeur',
  status = '',
  isMember = true,
  isContributionUpToDate = true,
  registerDate = '01/01/2015',
  rdcDate = '',
  address = {
    address1: 'Route des macarons',
    address2: 'Compl 1',
    postalCode: '06560',
    city: 'VALBONNE'
  },
  phoneHome = '0102030405',
  phoneMobile = '0102030405',
  email = 'test@supralog.com',
  bsppActive = true,
  incorporationNumber = '123456',
  militaryGrade = 'Caporal',
  company = 'Régiment des parachutistes',
  iban = 'FR768975644654897465654',
  bic = 'BARCGB22XXX',
  trancheTarifaire = 'T3',
  isHandicaped = false,
  privileges = ['EspaceAdherentVoirListePrestationsSociales', 'EspaceAdherentVoirListePrestationsLoisirs', 'EspaceAdherentIndividuAyantDroit'],
  lastTaxNoticeUpdate = '18/03/2021',
  lastTariffSectionUpdate = '18/03/2021',
  allo18Subsciptions =
  [
    {
      id: 1,
      address: {
        address1: 'Route des macarons',
        address2: 'Compl 1',
        postalCode: '06560',
        city: 'VALBONNE'
      },
      numberOfCopies: 1,
      startDate: '03/18/2021',
      endDate: '04/25/2021'
    },
    {
      id: 2,
      address: {
        address1: 'Route des michels',
        address2: 'Compl 1.2',
        postalCode: '06000',
        city: 'Nice'
      },
      numberOfCopies: 4,
      startDate: '03/18/2021',
      endDate: '03/17/2021'
    },
    {
      id: 3,
      address: {
        address1: 'Route des campements',
        address2: 'Compl 1.3',
        postalCode: '74560',
        city: 'AVIERNOZ'
      },
      numberOfCopies: 3,
      startDate: '03/18/2021',
      endDate: '07/17/2022'
    }
  ]
} = {}) => ({
  id,
  civility,
  lastName,
  firstName,
  birthdate,
  login,
  image,
  memberCode,
  familyStatus,
  profile,
  job,
  jobSecondary,
  status,
  isMember,
  isContributionUpToDate,
  registerDate,
  rdcDate,
  address,
  phoneHome,
  phoneMobile,
  email,
  bsppActive,
  incorporationNumber,
  militaryGrade,
  company,
  iban,
  bic,
  allo18Subsciptions,
  trancheTarifaire,
  isHandicaped,
  privileges,
  lastTaxNoticeUpdate,
  lastTariffSectionUpdate
});

export const allowanceApplication = ({
  id = '',
  allowanceId = '',
  memberCode = '',
  allowanceName = '',
  status = '',
  creationDate = '',
  lastChangeStatusDate = '',
  type = '',
  category = '',
  limit = '',
  rightHolderId = '',
  tickets = 0,
  value = 0
} = {}) => ({
  id,
  allowanceId,
  memberCode,
  allowanceName,
  status,
  creationDate,
  lastChangeStatusDate,
  category,
  type,
  limit,
  rightHolderId,
  tickets,
  value
});

export const allowanceApplicationList = [
  allowanceApplication({
    id: 1,
    allowanceId: 1,
    allowanceName: 'Aide au dépôt de garantie 1er logement BSPP',
    creationDate: '03/10/2021',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' },
    rightHolderId: 1,
    tickets: 1,
    value: 120
  }),
  allowanceApplication({
    id: 2,
    allowanceId: 1,
    allowanceName: 'Aide au dépôt de garantie 1er logement BSPP',
    creationDate: '03/09/2021',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' },
    rightHolderId: 1,
    tickets: 7,
    value: 80
  }),
  allowanceApplication({
    id: 3,
    allowanceId: 1,
    allowanceName: 'Aide au dépôt de garantie 1er logement BSPP',
    creationDate: '02/10/2021',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' },
    rightHolderId: 2,
    tickets: 2,
    value: 100
  }),
  allowanceApplication({
    id: 4,
    allowanceId: 1,
    allowanceName: 'Aide au dépôt de garantie 1er logement BSPP',
    creationDate: '04/10/2020',
    category: { id: 1, name: '' },
    type: { id: 1, name: '' }
  })
];