/* eslint-disable radix */
/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components';
import {
  Grid,
  Select, InputLabel, Typography
} from '@material-ui/core';
import { AllowanceService } from 'services';
import { BookingHelper, translate } from 'utils';
import { useStores } from 'hooks';
import { ALLOWANCE_LEISURE_ADVANTAGE_TYPE, DAY_TIMES } from 'utils/constants';
import { DateHelper } from 'utils/helpers/DateHelper';
import { action } from 'mobx';

const CalendarContainer = styled.div`
  .react-calendar{
    width: 500px;
  }
  &[data-toomuchdays="true"]{
    .react-calendar__tile--active {
      background-color: red;
    }
  }

  .react-calendar__tile:disabled {
    background-color: grey;
    color: white;
  }
`;

const PriceContainer = styled.div`
  font-size: 3rem;
  font-weight: bold;
`;

const BookingByHalfDay = ({
  formState, dateRange, setDateRange, select, setPrice, allowance, userTariffRange, setInterEstablishmentID, isError, setIsError, userAllowanceApplicationDetail
}) => {
  const [enabledDays, setEnabledDays] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);
  const [bookingsAvailable, setBookingsAvailable] = useState([]);
  const [accommodationTypes, setAccommodationTypes] = useState([]);
  const [isFormDisabled, setIsFormDisabled] = useState(!dateRange);
  const { advantageTypes } = allowance;
  const { allowanceStore } = useStores();
  const { allowanceHighSeasons } = allowanceStore;
  const [isAvailable, setIsAvailable] = useState(false);
  const [isTtError, setIsTtError] = useState(false);

  const halfDayTranslationKey = `common.${formState.values.halfDay}`;
  const halfDayLabel = translate(halfDayTranslationKey);

  const getDisabledDays = useCallback(
    ({ date }) => {
      const result = !enabledDays.some(days => DateHelper.checkIfBetweenTwoDates(days[0], days[1], date))
        || (availableDays !== undefined && !availableDays.some(availableDay => date.getTime() === availableDay.getTime()))
      return result;
    },
    [enabledDays, availableDays]
  );

  const getAllDay = useCallback(({ date }) => date, []);

  const getAccommodationTypes = useCallback(() => {
    if (userAllowanceApplicationDetail) {
      formState.setField('accommodation', userAllowanceApplicationDetail.interEstablishmentAllowance.unitCapacity);
      formState.setField('halfDay', DateHelper.getHalfDayfromDate(new Date(userAllowanceApplicationDetail.startDate)));
    } else {
      const tmpArray = [];
      bookingsAvailable.forEach(booking => {
        const dateBegin = new Date(booking.dateBegin);
        const dateEnd = new Date(booking.dateEnd);
        if (DateHelper.checkIfBetweenTwoDates(dateBegin, dateEnd, dateRange)) {
          tmpArray.push(booking.capacity);
        }
      });
      tmpArray.sort((a, b) => a - b);
      setAccommodationTypes(tmpArray);
      formState.setField('accommodation', tmpArray[0]);
      formState.setField('halfDay', DAY_TIMES.MORNING);
    }
    // eslint-disable-next-line
  }, [bookingsAvailable, BookingHelper, dateRange]);

  useEffect(() => {
    const allBookingList = BookingHelper.getFormattedBookingByDay(allowance, userTariffRange);
    setBookingsAvailable(allBookingList);
    // eslint-disable-next-line
  }, [allowance, userTariffRange]);

  useEffect(() => {
    setEnabledDays(allowance.reservationPeriods.map(period => [new Date(period.periodStart), new Date(period.periodEnd)]));
    refreshAvailableDays(dateRange);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let error = isTtError || (isAvailable !== undefined && !isAvailable);
    setIsError(error);
  }, [setIsError, isAvailable, isTtError])

  useEffect(() => {
    setIsAvailable(undefined);
    if (formState.values.interEstablishmentAllowanceId === undefined ||
      formState.values.interEstablishmentAllowanceId === -1 ||
      dateRange === undefined) {
      return;
    }

    const requestArray = [];
    const requestAvailability = {};
    requestAvailability.internalId = 1;
    requestAvailability.interEstablishmentId = formState.values.interEstablishmentAllowanceId;
    let startDate = new Date(dateRange);
    if (formState.values.halfDay === DAY_TIMES.EVENING) {
      startDate = new Date(startDate.setHours(startDate.getHours() + 12));
    }

    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + 12)

    requestAvailability.startDate = manageTimeZone(startDate);
    requestAvailability.endDate = manageTimeZone(endDate);
    requestArray.push(requestAvailability);

    AllowanceService.getAvailabilities(requestArray).then(action(result => {
      const availability = result.find(r => r.internalId === 1)
      if (availability !== undefined) {
        setIsAvailable(availability.isAvailable);
      }
    })).catch(() => {
      setIsAvailable(false);
    });
  }, ([dateRange, formState.values.halfDay, setIsAvailable, formState.values.interEstablishmentAllowanceId]))

  useEffect(() => {
    if (bookingsAvailable.length > 0) {
      getAccommodationTypes();
    }
  }, [bookingsAvailable, getAccommodationTypes]);

  const getInterEstablishmentID = useCallback(item => allowance.interEstablishments.find(interEstablishment => item.name === interEstablishment.name).id, [allowance]);

  useEffect(() => {
    const accommodation = typeof formState.values.accommodation === 'string'
      ? Number(formState.values.accommodation)
      : formState.values.accommodation;
    if (dateRange && accommodation && formState.values.halfDay) {
      bookingsAvailable.forEach(booking => {
        const dateBegin = new Date(booking.dateBegin);
        const dateEnd = new Date(booking.dateEnd);
        if (DateHelper.checkIfBetweenTwoDates(dateBegin, dateEnd, dateRange) && booking.capacity === accommodation) {
          if (booking.price === 'NA') {
            setIsTtError(true);
            return;
          }
          setIsFormDisabled(false);
          setInterEstablishmentID(getInterEstablishmentID(booking));
          if (formState.values.halfDay === DAY_TIMES.FULL_DAY) {
            setPrice(BookingHelper.checkIfHighSeason(allowanceHighSeasons, dateRange) ? booking.priceHighSeason * 2 : booking.price * 2);
          } else {
            setPrice(BookingHelper.checkIfHighSeason(allowanceHighSeasons, dateRange) ? booking.priceHighSeason : booking.price);
          }
        }
      });
    }
  }, [
    dateRange,
    formState.values.accommodation,
    formState.values.halfDay, bookingsAvailable,
    setPrice,
    setInterEstablishmentID, getInterEstablishmentID,
    setIsTtError, allowanceHighSeasons
  ]);

  function manageTimeZone(date) {
    if (typeof date.getMonth !== 'function') {
      return date;
    }

    return new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
  }

  function refreshAvailableDays(date) {
    setAvailableDays([]);
    let request = {};
    request.interEstablishmentsIds = allowance.interEstablishments.map(ie => ie.id);
    request.date = manageTimeZone(date === undefined ? new Date() : date);
    AllowanceService.getAvailabilitiesByHalfDay(request).then(action(result => {
      setAvailableDays(result.map(d => new Date(d)));
    }))
      .catch(error => {});
  }

  function changeCalendarView({ action, activeStartDate, value, view }) {
    if (view === "month") {
      refreshAvailableDays(activeStartDate);
    }
    else {
      setAvailableDays(undefined);
    }
  }

  function getPrice() {
    if (isAvailable === undefined) {
      return "";
    }

    if (!isAvailable) {
      return "Indisponible";
    }

    return advantageTypes[0].id === ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.code
      ? `Prix : ${ALLOWANCE_LEISURE_ADVANTAGE_TYPE.FREE.label}`
      : `Prix : ${formState.values.price}€`;
  }


  return (
    <Grid container direction="column" spacing={2}>
      <Grid container direction="row" spacing={2}>
        <Grid item sm={6} xs={6}>
          {enabledDays.length > 0 && (
            <CalendarContainer>
              <Calendar
                minDate={new Date()}
                tileDisabled={userAllowanceApplicationDetail ? getAllDay : getDisabledDays}
                value={dateRange}
                onChange={setDateRange}
                onActiveStartDateChange={changeCalendarView}
              />
            </CalendarContainer>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid container direction="column" spacing={2}>
            <Grid item sm={12} xs={12}>
              {userAllowanceApplicationDetail
                ? (
                  <div>
                    {`Demi-journée : ${halfDayLabel}`}
                  </div>
                )
                : (
                  <>
                    <InputLabel id="halfDay">Sélection de demi-journée</InputLabel>
                    <Select
                      color="secondary"
                      disabled={isFormDisabled || userAllowanceApplicationDetail}
                      fullWidth
                      labelId="halfDay"
                      native
                      variant="outlined"
                      {...select('halfDay')}
                    >
                      <option value={DAY_TIMES.MORNING}>Matin</option>
                      <option value={DAY_TIMES.EVENING}>Soir</option>
                      {/* <option value={DAY_TIMES.FULL_DAY}>Matin & Soir</option> */}
                    </Select>
                  </>
                )}
            </Grid>
            <Grid item sm={12} xs={12}>
              {userAllowanceApplicationDetail
                ? (
                  <div>
                    {`Type d'hébergement : ${formState.values.accommodation} personne(s)`}
                  </div>
                )
                : (
                  <>
                    <InputLabel id="accommodation">Type d&apos;hébergement</InputLabel>
                    <Select
                      color="secondary"
                      disabled={isFormDisabled || userAllowanceApplicationDetail}
                      fullWidth
                      labelId="accommodation"
                      native
                      variant="outlined"
                      {...select('accommodation')}
                    >
                      {accommodationTypes.map(accommodationType => <option key={`${dateRange}${accommodationType}`} value={accommodationType}>{`${accommodationType} personnes`}</option>)}
                    </Select>
                  </>
                )}
            </Grid>
            <Grid item sm={12} xs={12}>
              <PriceContainer>
                {getPrice()}
              </PriceContainer>
            </Grid>
            {isTtError && (
              <Grid item sm={12} xs={12}>
                <Typography color="error">
                  NA: Pas de prix défini pour votre tranche tarifaire, veuillez contacter l&apos;ADOSSPP
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BookingByHalfDay;