import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useStores } from 'hooks';
import { Typography } from '@material-ui/core';
import { translate } from 'utils';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { observer } from 'mobx-react-lite';

const StyledBackground = styled.div`
  position: relative;
  width: 600px;
  height: 350px;
  margin: 0 auto;
  background: url('/assets/images/bg/card-pdf.png') no-repeat center top;
  background-size: cover;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    width: 82px;
    height: 170px;
    transform: translateY(-95px);
    background: url('/assets/images/bg/header-triangle-large.png') no-repeat center;
    pointer-events: none;
  }
`;

const StyledCardPDFAccount = styled.aside`
  height: 100%;
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;

  h3 {
    text-transform: uppercase;
    font-weight: 600;
  }

  h4 {
    font-size: 2rem;
    font-style: italic;
  }
`;

const StyledInfos = styled.div`
  display: inline-block;
  margin: 1rem auto;
  padding: 1rem;
  min-width: 60%;
  max-width: 80%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);

  p:not(:first-child) strong {
    font-weight: 400;
  }

  p:first-child {
    margin-bottom: 0.5rem;
    font-size: 2.4rem;
  }

  p:nth-child(2) {
    font-size: 2rem;
  }
`;

const StyledLogo = styled.div`
  img {
    width: 60px;
  }

  p {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: var(--white);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: #e94249;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
  }
`;

const CardPDFAccount = observer(() => {
  const { userStore } = useStores();
  const { userConnected } = userStore;
  const {
    address, civility, login, memberCode, trancheTarifaire
  } = userConnected;

  const handleDownloadCard = useCallback(() => {
    const cardPDF = document.getElementById('cardPDF');

    html2canvas(cardPDF, {
      scrollY: -window.scrollY, width: 600, height: 350
    })
      .then(canvas => canvas.toDataURL())
      .then(image => {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF();
        pdf.addImage(image, 'PNG', 30, 10, 150, 87.5); // respect the ratio of the canvas
        pdf.save(`carte-adherent-${memberCode}.pdf`);
      });
  }, [memberCode]);

  return (
    <StyledBackground id="cardPDF" onClick={handleDownloadCard}>
      <StyledCardPDFAccount>
        <Typography variant="h3">
          {translate('pageUserProfile.person.card.title', { year: new Date().getFullYear() })}
        </Typography>
        <Typography variant="h4">
          {translate('pageUserProfile.person.card.subtitle')}
        </Typography>

        <StyledInfos>
          <Typography><strong>{memberCode}</strong></Typography>
          <Typography noWrap><strong>{`${civility || ''} ${login}`}</strong></Typography>
          <Typography>
            <strong>
              {`${translate('common.trancheTarifaire')} : `}
              {trancheTarifaire}
            </strong>
          </Typography>

          {address && address.address1 && (
            <>
              <Typography><strong>{`${address.address1} ${address.address2 || ''}`}</strong></Typography>
              <Typography><strong>{`${address.postalCode || ''} ${address.city || ''}`}</strong></Typography>
            </>
          )}
        </StyledInfos>

        <StyledLogo>
          <div><img alt="" src="/assets/images/logo-no-text.png" /></div>
          <Typography>{translate('pageUserProfile.person.card.logo')}</Typography>
        </StyledLogo>
      </StyledCardPDFAccount>
    </StyledBackground>

  );
});

export default CardPDFAccount;