import React, { lazy, Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PATHS } from 'utils/constants';
import { Footer, Header, SkeletonMain } from 'components';
import { Grid } from '@material-ui/core';
import { useStores } from 'hooks';
import GoogleAnalytics from 'components/GoogleAnalytics/GoogleAnalytics';
import { PageConfirmSubscription, PageSubscription, PageSubscriptionDescription } from 'pages';
import HeaderUnlogged from 'components/Header/HeaderUnlogged';

const PageConfirmSocial = lazy(() => import('pages/PageConfirmSocial'));
const PageConfirmLeisure = lazy(() => import('pages/PageConfirmLeisure'));
const PageConfirmPaybox = lazy(() => import('pages/PageConfirmPaybox'));
const PageAllowanceCategory = lazy(() => import('pages/PageAllowanceCategory'));
const PageAllowanceMain = lazy(() => import('pages/PageAllowanceMain'));
const PageContact = lazy(() => import('pages/PageContact'));
const PageMentions = lazy(() => import('pages/PageMentions'));
const PageHome = lazy(() => import('pages/PageHome'));
const PageConnect = lazy(() => import('pages/PageConnect'));

const AllowanceLeisureBooking = lazy(() => import('components/AllowanceLeisureBooking/AllowanceLeisureBooking'));
const AllowanceSocialDetail = lazy(() => import('components/AllowanceSocialDetail/AllowanceSocialDetail'));
const AllowanceLeisureDetail = lazy(() => import('components/AllowanceLeisureDetail/AllowanceLeisureDetail'));
const UserAllowanceApplication = lazy(() => import('components/UserAllowanceApplication/UserAllowanceApplication'));
const UserProfile = lazy(() => import('components/UserProfile/UserProfile'));

const withLayout = (BaseComponent, isTunnel = false, canGoBack = false) => props => (
  <Grid
    container
    direction="column"
    style={{ height: '100%', overflow: 'hidden' }}
    wrap="nowrap"
  >
    <Header canGoBack={canGoBack} isTunnel={isTunnel} />
    <main>
      <BaseComponent {...props} />
    </main>
    <Footer isTunnel={isTunnel} />
    <GoogleAnalytics />
  </Grid>
);

const withLayoutUnlogged = BaseComponent => props => (
  <Grid
    container
    direction="column"
    style={{ height: '100%', overflow: 'hidden' }}
    wrap="nowrap"
  >
    <HeaderUnlogged />
    <main>
      <BaseComponent {...props} />
    </main>
    <Footer isTunnel />
    <GoogleAnalytics />
  </Grid>
);

const RouteWithLayout = ({
  component, isTunnel, canGoBack, ...props
}) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;

  const handleRouteRender = useCallback(() => (
    isConnected
      ? withLayout(component, isTunnel, canGoBack)(props)
      : (
        <Redirect to={{
          pathname: PATHS.CONNECT,
          state: { from: props.location },
          search: props.location.search
        }}
        />
      )
  // eslint-disable-next-line
  ), [isConnected, component]);

  return (
    <Route {...props} render={handleRouteRender} />
  );
};

const UnLoggedRoute = ({
  component, ...props
}) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;

  const handleRouteRender = useCallback(() => (
    !isConnected
      ? withLayoutUnlogged(component)(props)
      : (
        <Redirect to={{
          pathname: PATHS.CONNECT,
          state: { from: props.location },
          search: props.location.search
        }}
        />
      )
  // eslint-disable-next-line
  ), [isConnected, component]);

  return (
    <Route {...props} render={handleRouteRender} />
  );
};

const Routes = () => (
  <Suspense fallback={SkeletonMain()}>
    <Switch>
      <RouteWithLayout canGoBack component={AllowanceLeisureBooking} exact isTunnel path={PATHS.ALLOWANCE_LEISURE_DETAIL_BOOKING} />

      <RouteWithLayout component={PageConfirmSocial} exact path={PATHS.ALLOWANCE_SOCIAL_DETAIL_CONFIRM} />
      <RouteWithLayout component={PageConfirmLeisure} exact isTunnel path={PATHS.ALLOWANCE_LEISURE_DETAIL_CONFIRM} />

      <RouteWithLayout component={PageConfirmPaybox} exact isTunnel path={PATHS.PAYBOX_CONFIRM} />

      <RouteWithLayout component={AllowanceSocialDetail} exact path={PATHS.ALLOWANCE_SOCIAL_DETAIL} />
      <RouteWithLayout component={AllowanceLeisureDetail} exact path={PATHS.ALLOWANCE_LEISURE_DETAIL} />

      <RouteWithLayout component={PageAllowanceCategory} exact path={PATHS.ALLOWANCE_SOCIAL_CATEGORY} />
      <RouteWithLayout component={PageAllowanceCategory} exact path={PATHS.ALLOWANCE_LEISURE_CATEGORY} />

      <RouteWithLayout component={PageAllowanceMain} exact path={PATHS.ALLOWANCE_SOCIAL_MAIN} />
      <RouteWithLayout component={PageAllowanceMain} exact path={PATHS.ALLOWANCE_LEISURE_MAIN} />

      <RouteWithLayout component={UserAllowanceApplication} exact path={PATHS.USER_ALLOWANCE_APPLICATION} />
      <RouteWithLayout component={UserProfile} exact path={PATHS.USER_PROFILE} />

      <RouteWithLayout component={PageContact} exact path={PATHS.CONTACT} />
      <RouteWithLayout component={PageMentions} exact path={PATHS.MENTIONS} />
      <RouteWithLayout component={PageHome} exact path={PATHS.HOME} />

      <UnLoggedRoute component={PageSubscription} exact path={PATHS.SUBSCRIPTION} />
      <UnLoggedRoute component={PageSubscriptionDescription} exact path={PATHS.SUBSCRIPTION_DESCRIPTION} />
      <UnLoggedRoute component={PageConfirmPaybox} path={PATHS.PAYBOX_CONFIRM_SUBSCRIPTION} />
      <UnLoggedRoute component={PageConfirmSubscription} exact path={PATHS.CONFIRM_SUBSCRIPTION} />

      <Route component={PageConnect} path={PATHS.CONNECT} />
      <Redirect to={PATHS.CONNECT} />
    </Switch>
  </Suspense>
);

export default Routes;