const hasAccessRight = (userConnected, requiredRoles = []) => {
  if (userConnected && userConnected.roles && userConnected.roles.length > 0) {
    return requiredRoles.some(role => userConnected.roles.includes(role));
  }

  return false;
};

const hasPrivilege = (userConnected, requiredPrivileges = []) => {
  if (userConnected && userConnected.privileges && userConnected.privileges.length > 0) {
    return requiredPrivileges.some(priv => userConnected.privileges.includes(priv));
  }

  return false;
};

export const UserHelper = {
  hasAccessRight,
  hasPrivilege
};