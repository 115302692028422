import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished'; // Docs : https://polished.js.org/docs/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons';

const StyledInfoIcon = styled.aside`
  position: relative;
  margin: 1rem auto 2rem 2.5rem;
  padding: 2rem 2rem 2rem 4rem;
  color: #000000;
  background-color: ${props => rgba(props.background, 0.2)};
  border-radius: 8px;

  > span {
    position: absolute;
    top: 50%;
    left: -25px;
    width: 50px;
    height: 50px;
    padding: 1rem;
    text-align: center;
    line-height: 30px;
    background-color: ${props => props.background};
    border-radius: 50%;
    transform: translateY(-25px);
  }

  svg {
    color: var(--white);
  }
`;

const InfoIcon = ({
  info, icon, iconSize, background
}) => (
  <StyledInfoIcon background={background}>
    <span>
      <FontAwesomeIcon icon={icon} size={iconSize} />
    </span>
    {info}
  </StyledInfoIcon>
);

InfoIcon.propTypes = {
  background: PropTypes.string,
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]).isRequired,
  icon: PropTypes.shape({}),
  iconSize: PropTypes.string
};

InfoIcon.defaultProps = {
  background: '#f5d31f',
  icon: faLightbulb,
  iconSize: '2x'
};

export default InfoIcon;