import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { SRLWrapper } from 'simple-react-lightbox';
import shortid from 'shortid';
import { ImageZoom } from 'components';

const options = {
  settings: {
    disablePanzoom: true,
    lightboxTransitionSpeed: 0.3
  },
  caption: {},
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    size: '60px'
  },
  thumbnails: {
    thumbnailsContainerPadding: '1rem',
    thumbnailsGap: '0 5px',
    thumbnailsOpacity: 0.6
  }
};

const ImagesContainer = styled.div`
  height: 400px;
  overflow: hidden;
  border-radius: 12px;

  > div{
    height: 100%;
  }

  @media (max-width: 768px) {
    max-height: none;
  }
`;

const CustomGrid100 = styled(Grid)`
  height: 100%;
`;

const CustomGrid50 = styled(Grid)`
  height: 50%;
`;

const renderTemplate = images => {
  const totalImages = images.length;

  switch (totalImages) {
  case 1:
  case 2:
  case 3:
  case 4:
    return (
      <CustomGrid100 container spacing={1}>
        {images.map(img => (
          <CustomGrid100 item key={shortid.generate()} sm={12 / totalImages} xs={12}>
            <ImageZoom image={img} />
          </CustomGrid100>
        ))}
      </CustomGrid100>
    );
  case 5:
    return (
      <CustomGrid100 container spacing={1}>
        <CustomGrid100 item sm={6} xs={12}>
          <ImageZoom firstImage image={images[0]} />
        </CustomGrid100>
        <CustomGrid100 item sm={6} xs={12}>
          <CustomGrid100 container direction="column" spacing={1}>
            <CustomGrid50 item>
              <CustomGrid100 container spacing={1}>
                <CustomGrid100 item xs={6}>
                  <ImageZoom image={images[1]} />
                </CustomGrid100>
                <CustomGrid100 item xs={6}>
                  <ImageZoom image={images[2]} />
                </CustomGrid100>
              </CustomGrid100>
            </CustomGrid50>
            <CustomGrid50 item>
              <CustomGrid100 container spacing={1}>
                <CustomGrid100 item xs={6}>
                  <ImageZoom image={images[3]} />
                </CustomGrid100>
                <CustomGrid100 item xs={6}>
                  <ImageZoom image={images[4]} />
                </CustomGrid100>
              </CustomGrid100>
            </CustomGrid50>
          </CustomGrid100>
        </CustomGrid100>
      </CustomGrid100>
    );
  default:
    return (
      <ImageZoom image={images[0]} />
    );
  }
};

const ImageTemplates = ({
  images
}) => (
  <ImagesContainer>
    <SRLWrapper options={options}>
      {renderTemplate(images)}
    </SRLWrapper>
  </ImagesContainer>
);

ImageTemplates.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string
  })).isRequired
};

export default ImageTemplates;