import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getProfile = () => RequestHelper.GET(API_ROUTES.USER_PROFILE());

const getSubscriptions = () => RequestHelper.GET(API_ROUTES.USER_SUBSCRIPTIONS);

const getSubscriptionsPrices = () => RequestHelper.GET(API_ROUTES.USER_SUBSCRIPTIONS_PRICES);

const getMembershipTariff = () => RequestHelper.GET(API_ROUTES.USER_MEMBERSHIP_TARIFF);

const getCivilities = () => RequestHelper.UNLOGGED_GET(API_ROUTES.CIVILITIES);

// POST
const login = user => RequestHelper.POST(API_ROUTES.LOGIN(), user);

const refreshToken = token => RequestHelper.POST(API_ROUTES.LOGIN_REFRESH(), { refreshToken: token });

const forgotPassword = username => RequestHelper.POST(API_ROUTES.FORGOT_PASSWORD(), { username });

const getNewPassword = newPasswordData => RequestHelper.POST(API_ROUTES.GET_NEW_PASSWORD(), newPasswordData);

const createSubscription = subscription => RequestHelper.POST(API_ROUTES.USER_SUBSCRIPTIONS_CREATE, subscription);

const renewalSubscription = subscriptionId => RequestHelper.POST(API_ROUTES.USER_SUBSCRIPTIONS_RENEWAL(subscriptionId));

const createMembership = () => RequestHelper.POST(API_ROUTES.USER_MEMBERSHIP_CREATE);

// PUT
const updateProfile = profileUpdated => RequestHelper.PUT(API_ROUTES.USER_PROFILE(), profileUpdated);
const updateSubscriptionAddress = subscription => RequestHelper.PUT(API_ROUTES.UPDATE_ADDRESS_SUBSCRIPTIONS, subscription);

const UserService = {
  getProfile,
  getSubscriptions,
  getSubscriptionsPrices,
  getCivilities,
  login,
  refreshToken,
  forgotPassword,
  updateProfile,
  updateSubscriptionAddress,
  getNewPassword,
  createSubscription,
  renewalSubscription,
  getMembershipTariff,
  createMembership
};

export default UserService;