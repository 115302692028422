const getCookie = name => {
  const allCookies = document.cookie.split(';');

  if (allCookies.length > 0) {
    const cookie = allCookies.find(c => c.trim().startsWith(name));

    if (cookie) {
      // We get a cookie like that :
      // refreshToken=peHjJxWhrw5xN9QIMP32SvDhurOgsYax9ZX0zwc/aeE=
      // so we get the string from the (name.length) + (1 for the '=')
      return cookie.trim().substring(name.length + 1);
    }

    return null;
  }

  return null;
};

const setCookie = (name, value, expires, maxAge = 3600) => {
  if (!expires) {
    const cookieMaxAge = `${name}=${value}; max-age=${maxAge}`;
    document.cookie = cookieMaxAge;
    return;
  }

  const cookieExpire = `${name}=${value}; expires=${expires}`;
  document.cookie = cookieExpire;
};

const deleteCookie = name => {
  const cookieDelete = `${name}=; path=/; expires=${new Date('1970-01-01').toUTCString()}; Max-Age=0`;
  document.cookie = cookieDelete;
};

export const CookieHelper = {
  getCookie,
  setCookie,
  deleteCookie
};