import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { translate } from 'utils';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons';

const StyledCardTitle = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem 0;
  border-bottom: 1px solid #eeeeee;
  background: var(--info-color);

  button {
    position: absolute;
    right: 1rem;
  }
`;

const SubscriptionStatusIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 0px;
  left: -140px;

  @keyframes jump {
    0% { transform: scale(1.25, 0.75); }
    50% { transform: translate(0, -80%); }
    55% { transform: translate(0, -80%) rotate(15deg); }
    60% { transform: translate(0, -80%) rotate(-15deg); }
    65% { transform: translate(0, -80%) rotate(15deg); }
    70% { transform: translate(0, -80%) rotate(-15deg); }
    100% { transform: scale(1.25, 0.75); }
  }

  &[data-status=true]{
    top: 15px;
    animation: jump 1.2s infinite;
  }
`;

const COLOR_BY_STATUS = {
  renewed: 'var(--black)',
  expired: 'var(--primary-color)',
  old: 'var(--primary-color)',
  closeToExpired: 'var(--tertiary-color)',
  unPayed: 'var(--tertiary-color)'
};

const CustomCardTitle = ({
  title, icon, button, subscriptionStatus, disabled = false
}) => (
  <StyledCardTitle>
    {(subscriptionStatus && subscriptionStatus !== 'ok' && !disabled)
      && (
        <SubscriptionStatusIcon
          aria-label={subscriptionStatus}
          className="mr1"
          color={COLOR_BY_STATUS[subscriptionStatus]}
          data-status={subscriptionStatus === 'expired' || subscriptionStatus === 'old'}
          icon={subscriptionStatus === 'renewed' ? faArrowsRotate : faClock}
          size="2x"
          title={translate(`pageUserProfile.subscriptions.${subscriptionStatus}`)}
        />
      )}
    <Typography
      align="center"
      component="h2"
      variant="h4"
    >
      <FontAwesomeIcon className="mr1" icon={icon} />
      {translate(title)}
    </Typography>

    {button}
  </StyledCardTitle>
);
CustomCardTitle.propTypes = {
  button: PropTypes.element,
  icon: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  subscriptionStatus: PropTypes.string
};

CustomCardTitle.defaultProps = {
  button: null,
  icon: faUser,
  subscriptionStatus: null
};

export default CustomCardTitle;