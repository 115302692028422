import { action, decorate, observable } from 'mobx';
import { AllowanceService } from 'services';
import { CategoryHelper } from 'utils';
import { PATHS, ROUTES, ALLOWANCE_CATEGORY_ICON_MAPPER } from 'utils/constants';

export class AllowanceStore {
  constructor() {
    this.isLoading = false;
    this.allowance = null;
    this.allowanceList = [];
    this.allowanceListFiltered = [];
    this.subMenuItems = {
      [PATHS.HOME]: null,
      [PATHS.ALLOWANCE_SOCIAL_MAIN]: [],
      [PATHS.ALLOWANCE_LEISURE_MAIN]: []
    };
    this.socialAllowanceCategories = null;
    this.leisureAllowanceCategories = null;
    this.subMenuItemsLeisure = [];
    this.subMenuItemsSocial = [];
    this.allowanceCategoryMapper = {};
    this.allowanceCategoryMapperReverse = {};
    this.allowanceCategoryMenuMapper = {};
    this.allowancePaymentMeans = null;
    this.allowancePaymentModes = null;
    this.allowanceModules = null;
    this.allowanceHighSeasons = [];
  }

  getAllowanceList() {
    this.isLoading = true;

    return AllowanceService.getAllowanceList().then(action(list => {
      this.allowanceList = list;
      this.allowanceListFiltered = [];
    }))
      .catch(action(error => {
        throw error;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  getAllowanceListByTypeAndCategory(typeId, categoryId) {
    this.isLoading = true;
    this.allowanceListFiltered = [];

    return AllowanceService.getAllowanceListByTypeAndCategory(typeId, categoryId).then(action(list => {
      this.allowanceList = list;
      this.allowanceListFiltered = list;
    }))
      .catch(action(error => {
        throw error;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  filterAllowanceList(filter) {
    this.allowanceListFiltered = [];
    if (filter) {
      this.allowanceListFiltered = this.allowanceList.filter(item => item.subCategory?.name === filter);
    } else {
      this.allowanceListFiltered = this.allowanceList;
    }
  }

  getAllowanceDetail(id) {
    this.isLoading = true;
    return AllowanceService.getAllowanceDetail(id).then(action(detail => {
      this.allowance = detail;
      this.allowance.limit = {
        type: 'Financial', linkedTo: 'home', number: 10, frequency: 'month'
      };
    }))
      .catch(action(error => {
        throw error;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  getSocialAllowanceCategories() {
    return AllowanceService.getAllowanceCategories(1).then(action(cats => {
      this.socialAllowanceCategories = cats;
      const formattedCategories = [];
      cats.forEach(cat => {
        const formattedName = CategoryHelper.getCategoryFormattedName(cat.name);
        const formattedCat = {
          label: cat.name,
          labelMobile: cat.name,
          icon: ALLOWANCE_CATEGORY_ICON_MAPPER[cat.id],
          url: ROUTES.ALLOWANCE_SOCIAL_CATEGORY(formattedName)
        };
        this.allowanceCategoryMapper[cat.id] = formattedName;
        this.allowanceCategoryMapperReverse[formattedName] = cat.id;
        formattedCategories.push(formattedCat);
      });
      this.subMenuItems[PATHS.ALLOWANCE_SOCIAL_MAIN] = formattedCategories;
    }))
      .catch(action(error => {
        throw error;
      }));
  }

  getLeisureAllowanceCategories() {
    return AllowanceService.getAllowanceCategories(2).then(action(cats => {
      this.leisureAllowanceCategories = cats;
      const formattedCategories = [];
      cats.forEach(cat => {
        const formattedName = CategoryHelper.getCategoryFormattedName(cat.name);
        const formattedCat = {
          label: cat.name,
          labelMobile: cat.name,
          icon: ALLOWANCE_CATEGORY_ICON_MAPPER[cat.id],
          url: ROUTES.ALLOWANCE_LEISURE_CATEGORY(formattedName)
        };
        this.allowanceCategoryMapper[cat.id] = formattedName;
        this.allowanceCategoryMapperReverse[formattedName] = cat.id;
        this.allowanceCategoryMenuMapper[formattedName] = cat.name;
        formattedCategories.push(formattedCat);
      });
      this.subMenuItems[PATHS.ALLOWANCE_LEISURE_MAIN] = formattedCategories;
    }))
      .catch(action(error => {
        throw error;
      }));
  }

  getAllowancePaymentMeans() {
    return AllowanceService.getAllowancePaymentMeans().then(action(pm => {
      this.allowancePaymentMeans = pm;
    }))
      .catch(action(error => {
        throw error;
      }));
  }

  getAllowanceHighSeasons() {
    return AllowanceService.getAllowanceHighSeasons().then(action(hs => {
      this.allowanceHighSeasons = hs;
    }))
      .catch(action(error => {
        throw error;
      }));
  }

  getAllowanceModules() {
    return AllowanceService.getAllowanceModules().then(action(mods => {
      this.allowanceModules = mods;
    }))
      .catch(action(error => {
        throw error;
      }));
  }

  getAllowancePaymentModes() {
    return AllowanceService.getAllowancePaymentModes().then(action(pm => {
      this.allowancePaymentModes = pm;
    }))
      .catch(action(error => {
        throw error;
      }));
  }

  getAllData() {
    return Promise.all([
      // this.getAllowancePaymentMeans(),
      this.getAllowancePaymentModes(),
      this.getAllowanceModules(),
      this.getSocialAllowanceCategories(),
      this.getLeisureAllowanceCategories(),
      this.getAllowanceHighSeasons()
    ])
      .catch(action(error => {
        throw error;
      }));
  }
}

decorate(AllowanceStore, {
  isLoading: observable,
  allowance: observable,
  allowanceList: observable,
  allowanceListFiltered: observable,
  allowancePaymentMeans: observable,
  allowanceModules: observable,
  allowancePaymentModes: observable,
  allowanceHighSeasons: observable,
  subMenuItems: observable,
  socialAllowanceCategories: observable,
  leisureAllowanceCategories: observable,
  subMenuItemsLeisure: observable,
  subMenuItemsSocial: observable,
  allowanceCategoryMapper: observable,
  allowanceCategoryMapperReverse: observable,
  allowanceCategoryMenuMapper: observable,
  getAllowanceList: action,
  getAllowanceListByTypeAndCategory: action,
  filterAllowanceList: action,
  getAllowanceDetail: action,
  getSocialAllowanceCategories: action,
  getLeisureAllowanceCategories: action,
  getAllowancePaymentMeans: action,
  getAllowanceHighSeasons: action,
  getAllowanceModules: action,
  getAllowancePaymentModes: action,
  getAllData: action
});

export const allowanceStore = new AllowanceStore();