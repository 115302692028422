import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import { translate } from 'utils';
import { useSnackbar } from 'notistack';
import { AllowanceApplicationService } from 'services';
import { useArray } from 'hooks';

const AllowanceLimitMessageContainer = styled.div`
  width: 100%;
  background-color: var(--primary-color);
  text-align: center;
  color: var(--white);
  padding: 1rem 6rem;
  font-weight: 600;
  font-size: 1.8rem;
`;

const AllowanceLimitMessage = ({
  allowance, amount, limit, quantity, setLimit
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [availableCeil, setAvailableCeil] = useState();
  const {
    add, value: errorMessages
  } = useArray([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    AllowanceApplicationService.getAllowanceApplicationCeil(allowance.id).then(resp => {
      setAvailableCeil(resp);
    })
      .catch(() => enqueueSnackbar(translate('errors.failedAllowanceApplicationCeil'), { variant: 'error' }));
  }, [allowance.id, enqueueSnackbar]);

  const addInErrorMessages = useCallback(value => {
    if (!errorMessages.includes(value)) {
      add(value);
    }
  }, [errorMessages, add]);

  useEffect(() => {
    if (!availableCeil) {
      return;
    }

    if (!availableCeil.hasCeil) {
      setLimit(false);
    } else {
      let hasLimit = false;
      if (amount && amount > availableCeil.amount) {
        addInErrorMessages('allowanceLimit.financial');
        hasLimit = true;
      }
      if (quantity && quantity > availableCeil.quantity) {
        addInErrorMessages('allowanceLimit.tickets');
        hasLimit = true;
      }
      if (!availableCeil.application) {
        addInErrorMessages('allowanceLimit.demands');
        hasLimit = true;
      }

      setLimit(hasLimit);
    }
  }, [availableCeil, amount, quantity, setLimit, addInErrorMessages]);

  return (
    limit ? (
      errorMessages.map(errorMessage => (
        <AllowanceLimitMessageContainer>
          {`${translate('allowanceLimit.message')} ${translate(errorMessage)}`}
        </AllowanceLimitMessageContainer>
      ))
    ) : <></>
  );
};

export default AllowanceLimitMessage;