import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  DialogActions, DialogContent, Button, Grid, TextField, Checkbox, FormControlLabel
} from '@material-ui/core';
import { DocumentHelper, FormHelper, translate } from 'utils';
import { useFormState } from 'react-use-form-state';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { FAMILY_RELATIONSHIP, FAMILY_RELATIONSHIP_MAPPER } from 'utils/constants';
import { ModalHeader } from './_ModalHeader';
import { FormAddress, FormInputFile } from '../Form';
import { Datepicker } from '../Datepicker';

const CURRENT_YEAR = new Date().getFullYear();
const YEAR_21_AGO = CURRENT_YEAR - 21;
const NUMBER_OF_DAYS_SINCE_21 = differenceInCalendarDays(new Date(), new Date().setFullYear(YEAR_21_AGO));

export const RightHolderModal = ({
  onClose, onConfirm, title, buttonLabel, defaultValues
}) => {
  const [familyRelationship, setFamilyRelationship] = useState(
    (defaultValues.familyRelationship && defaultValues.familyRelationship.id) || FAMILY_RELATIONSHIP_MAPPER.SPOUSE
  );
  const [birthdate, setBirthdate] = useState(
    defaultValues.birthdate || null
  );
  const [displayHandicap, setDisplayHandicap] = useState(true);
  const [handicapedFile, setHandicapedFile] = useState(defaultValues.handicapedFile);
  const [birthFile, setBirthFile] = useState(defaultValues.birthFile);
  const [weddingFile, setWeddingFile] = useState(defaultValues.weddingFile);

  const [formState, {
    text, tel, checkbox, raw
  }] = useFormState({
    ...defaultValues,
    ...defaultValues.address,
    country: FormHelper.getDefaultCountry(defaultValues && defaultValues.address)
  });

  useEffect(() => {
    if (familyRelationship === FAMILY_RELATIONSHIP_MAPPER.CHILDREN) {
      const isLessThan21YearsOld = differenceInCalendarDays(new Date(), new Date(birthdate)) <= NUMBER_OF_DAYS_SINCE_21;
      setDisplayHandicap(isLessThan21YearsOld);
    } else {
      setDisplayHandicap(true);
    }
  }, [birthdate, familyRelationship]);

  const handleChangeFamilyRelationship = useCallback((_, status) => {
    if (!status) return;
    setFamilyRelationship(status);
  }, [setFamilyRelationship]);

  const filterExistingDocuments = useCallback(document => {
    if (!document.id) {
      return DocumentHelper.getDocumentWithoutBase64(document);
    }
    return null;
  }, []);

  const handleSubmit = useCallback(e => {
    e.preventDefault();

    const rightHolder = {
      ...formState.values,
      address: FormHelper.getFormattedAddress(formState.values),
      birthdate,
      familyRelationshipId: familyRelationship,
      handicapedFile: handicapedFile && filterExistingDocuments(handicapedFile),
      birthFile: birthFile && filterExistingDocuments(birthFile),
      weddingFile: weddingFile && filterExistingDocuments(weddingFile)
    };

    onConfirm(rightHolder);

    return onClose();
  }, [onConfirm, onClose, formState.values, filterExistingDocuments,
    birthdate, familyRelationship, handicapedFile, birthFile, weddingFile]);

  const handleNameChange = (event, type) => {
    const value = event.target.value.toUpperCase();
    formState.setField(type, value);
  };

  return (
    <form style={{ minWidth: '560px' }} onSubmit={handleSubmit}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon className="mr1" icon={faUserPlus} />
        {translate(title)}
      </ModalHeader>

      <DialogContent style={{ minHeight: '660px' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <ToggleButtonGroup
              exclusive
              required
              value={familyRelationship}
              onChange={handleChangeFamilyRelationship}
            >
              {FAMILY_RELATIONSHIP.map((rel => (
                <ToggleButton key={rel.id} value={rel.id}>
                  {translate(`common.${rel.name}`)}
                </ToggleButton>
              )))}
            </ToggleButtonGroup>
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              id="lastName"
              label={translate('common.lastName')}
              name="lastName"
              required
              value={formState.values.lastName}
              onChange={event => handleNameChange(event, 'lastName')}
            />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              id="firstName"
              label={translate('common.firstName')}
              name="firstName"
              required
              value={formState.values.firstName}
              onChange={event => handleNameChange(event, 'firstName')}
            />
          </Grid>

          <Grid item>
            <Datepicker
              disableFuture
              label="common.birthdate"
              openTo="year"
              required
              value={birthdate}
              onChange={date => setBirthdate(date)}
            />
          </Grid>

          {displayHandicap && (
            <Grid alignItems="flex-start" container direction="column" item>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="isHandicaped"
                    {...checkbox('isHandicaped')}
                  />
                )}
                label={translate('forms.handicaped')}
              />

              {formState.values.isHandicaped && (
                <FormInputFile
                  document={handicapedFile}
                  handleFileAdded={docAdded => setHandicapedFile(docAdded)}
                  id="handicapedFile"
                  label="forms.handicapedFile"
                />
              )}
            </Grid>
          )}

          <Grid item>
            <FormAddress formState={formState} raw={raw} text={text} />
          </Grid>

          <Grid item>
            <TextField
              fullWidth
              id="phone"
              label={translate('common.phone')}
              name="phone"
              {...tel('phone')}
              inputProps={{
                maxLength: 12
              }}
            />
          </Grid>

          <Grid item>
            {familyRelationship === FAMILY_RELATIONSHIP_MAPPER.CHILDREN ? (
              <FormInputFile
                document={birthFile}
                handleFileAdded={docAdded => setBirthFile(docAdded)}
                id="birthFile"
                label="forms.birthFile"
                required={!birthFile}
              />
            ) : (
              <FormInputFile
                document={weddingFile}
                handleFileAdded={docAdded => setWeddingFile(docAdded)}
                id="weddingFile"
                label="forms.weddingFile"
                required={!weddingFile}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          aria-label={translate('button.cancel')}
          variant="text"
          onClick={onClose}
        >
          {translate('button.cancel')}
        </Button>
        <Button
          aria-label={translate(buttonLabel)}
          color="primary"
          type="submit"
        >
          {translate(buttonLabel)}
        </Button>
      </DialogActions>
    </form>
  );
};

RightHolderModal.propTypes = {
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string
};

RightHolderModal.defaultProps = {
  buttonLabel: 'button.create',
  title: 'pageUserProfile.rightHolder.addRightHolder'
};