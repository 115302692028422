/* ***
** Usage

const Component = () => {
  const hasPrivileges = usePrivileges({ privileges: ['EspaceAdhérentVoirListePrestationsSociales'] });
};

*/

import { useStores } from 'hooks';
import { UserHelper } from 'utils';

const usePrivileges = (privileges = []) => {
  const { userStore } = useStores();
  const { isConnected, userConnected } = userStore;

  return (isConnected && UserHelper.hasPrivilege(userConnected, privileges));
};

export default usePrivileges;