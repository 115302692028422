import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { translate } from 'utils';

const SkeletonNoAllowance = ({ error }) => (
  <Grid alignItems="center" container direction="column" justify="center">
    <Grid item>
      <Typography align="center" style={{ marginBottom: '4rem' }}>{translate(error)}</Typography>
    </Grid>
    <Grid item>
      <img alt={translate(error)} src="/assets/images/no-results.png" />
    </Grid>
  </Grid>
);

SkeletonNoAllowance.propTypes = {
  error: PropTypes.string
};

SkeletonNoAllowance.defaultProps = {
  error: 'errors.noAllowance'
};

export default SkeletonNoAllowance;