/* eslint-disable max-len */
import { ALLOWANCE_LEISURE_PAYMENT_TYPE } from 'utils/constants';
import { format } from 'date-fns';
import { DateHelper } from './DateHelper';

const getPriceByTariffRange = (grantAmounts, userTariffRange) => {
  let price = 'NA';
  let priceHighSeason = 'NA';
  grantAmounts.forEach(amount => {
    if (amount.tariffSectionCode === userTariffRange) {
      price = amount.grantCeiling;
      priceHighSeason = amount.grantCeilingHighSeason;
    }
  });
  return { price, priceHighSeason };
};

const formattedReservation = (interEstablishments, dateStart, dateSevenDays, allBookingList, userTariffRange, amountCalculatingMeans) => {
  interEstablishments.forEach(resa => {
    if (resa.rooms.length > 0) {
      const booking = {};
      booking.dateBegin = format(new Date(dateStart), 'MM/dd/yyyy');
      booking.dateEnd = format(new Date(dateSevenDays), 'MM/dd/yyyy');
      booking.capacity = resa.unitCapacity;
      booking.availability = undefined;
      booking.name = resa.name;
      booking.interEstablishmentId = resa.id;
      if (amountCalculatingMeans.id === ALLOWANCE_LEISURE_PAYMENT_TYPE.UNIQUE.code) {
        const price = resa.grantAmounts[0].grantCeiling;
        const priceHighSeason = resa.grantAmounts[0].grantCeilingHighSeason;
        if (price) {
          booking.price = price;
          booking.priceHighSeason = priceHighSeason;
        } else {
          booking.price = 10;
          booking.priceHighSeason = 15;
        }
      } else {
        const prices = getPriceByTariffRange(resa.grantAmounts, userTariffRange);
        booking.price = prices.price;
        booking.priceHighSeason = prices.priceHighSeason;
      }
      allBookingList.push(booking);
    }
  });
};

const getFormattedBookingByWeek = (allowance, userTariffRange) => {
  const { reservationPeriods, interEstablishments, amountCalculatingMeans } = allowance;
  const allBookingList = [];
  reservationPeriods.forEach(period => {
    const dateBegin = new Date(period.periodStart.split('T')[0]);
    while (dateBegin.getDay() !== 6) {
      dateBegin.setDate(dateBegin.getDate() + 1);
    }
    const dateEnd = new Date(period.periodEnd.split('T')[0]);
    let bool = true;
    let dateStart = dateBegin;
    while (bool) {
      const dateSevenDays = new Date(dateStart);
      dateSevenDays.setDate(dateSevenDays.getDate() + 7);
      if (dateSevenDays < dateEnd) {
        formattedReservation(interEstablishments, dateStart, dateSevenDays, allBookingList, userTariffRange, amountCalculatingMeans);
        dateStart = dateSevenDays;
      } else if (dateSevenDays.getTime() === dateEnd.getTime()) {
        formattedReservation(interEstablishments, dateStart, dateSevenDays, allBookingList, userTariffRange, amountCalculatingMeans);
        bool = false;
      } else {
        bool = false;
      }
    }
  });
  return allBookingList;
};

const getFormattedBookingByDay = (allowance, userTariffRange) => {
  const { reservationPeriods, interEstablishments, amountCalculatingMeans } = allowance;
  const allBookingList = [];
  reservationPeriods.forEach(period => {
    const dateBegin = new Date(period.periodStart);
    const dateEnd = new Date(period.periodEnd);
    formattedReservation(interEstablishments, dateBegin, dateEnd, allBookingList, userTariffRange, amountCalculatingMeans);
  });
  return allBookingList;
};

const checkIfTwoDatesEquals = (date1, date2) => (date1.getDate() === date2.getDate()
 && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear());

const checkIfHighSeason = (highSeasons, dateCheck) => {
  let result = false;
  highSeasons.forEach(hs => {
    const startDate = new Date(hs.startDate.split('T')[0]);
    const endDate = new Date(hs.endDate.split('T')[0]);
    if (DateHelper.checkIfBetweenTwoDates(startDate, endDate, dateCheck)) {
      result = true;
    }
  });
  return result;
};

export const BookingHelper = {
  getFormattedBookingByWeek,
  getFormattedBookingByDay,
  getPriceByTariffRange,
  checkIfTwoDatesEquals,
  checkIfHighSeason
};