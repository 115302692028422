import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationsEn from './en/translationsEn.json';
import translationsFr from './fr/translationsFr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false, // For development mode
    resources: {
      en: {
        translation: translationsEn
      },
      fr: {
        translation: translationsFr
      }
    },
    lng: 'fr',
    fallbackLng: 'fr',
    keySeparator: '.', // to have multiple level of translations

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;