import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Card, CardContent, Grid
} from '@material-ui/core';
import { faClipboardUser, faFileDownload, faIdCard } from '@fortawesome/pro-regular-svg-icons';
import { TabContainer } from 'components/UserProfile/UserProfile';
import {
  CardPDFAccount, CustomCardTitle, Desktop, PayboxPayment, BannerMessage, InfoLine
} from 'components';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStores, useAccessRights } from 'hooks';
import { ADOSSPP_ROLES, PAYMENT_TYPE } from 'utils/constants';
import styled from 'styled-components';
import { UserService } from 'services';

const BannerMessageContainer = styled(Grid)`
  margin-bottom: 20px;
`;

const Person = observer(() => {
  const { userStore } = useStores();
  const { userConnected } = userStore;
  const [tariff, setTariff] = useState(undefined);
  const hasAccessRights = useAccessRights([
    ADOSSPP_ROLES.ACTIVE,
    ADOSSPP_ROLES.FORMER,
    ADOSSPP_ROLES.COVERED,
    ADOSSPP_ROLES.COVERED_WITHOUT_RIGHTS,
    ADOSSPP_ROLES.BENEFACTOR,
    ADOSSPP_ROLES.ORPHAN,
    ADOSSPP_ROLES.EMPLOYEE,
    ADOSSPP_ROLES.WIDOWER
  ]);

  useEffect(() => {
    if (!userConnected.isContributionUpToDate) {
      UserService.getMembershipTariff().then(resp => {
        const firstTariff = resp[0];
        setTariff(firstTariff);
      });
    }
  }, [userConnected.isContributionUpToDate]);

  const handleDownloadCardPDF = useCallback(() => document.getElementById('cardPDF').click(), []);

  function createMembership() {
    return UserService.createMembership().then(resp => {
      const encodedMembershipId = encodeURI(resp);
      return {
        itemId: encodedMembershipId,
        price: tariff.montant
      };
    })
      .catch(() => undefined);
  }

  return (
    <TabContainer>
      <Grid container spacing={2}>
        {hasAccessRights && userConnected.isContributionUpToDate && (
          <Grid item xs={12}>
            <Card>
              <CustomCardTitle
                button={(
                  <Desktop>
                    <Button
                      aria-label={translate('button.download')}
                      color="primary"
                      startIcon={<FontAwesomeIcon icon={faFileDownload} />}
                      variant="text"
                      onClick={handleDownloadCardPDF}
                    >
                      {translate('button.download')}
                    </Button>
                  </Desktop>
                )}
                icon={faIdCard}
                title="userMenu.personalCard"
              />
              <CardContent style={{ overflowX: 'auto' }}>
                <CardPDFAccount />
              </CardContent>
            </Card>
          </Grid>
        )}

        {hasAccessRights && !userConnected.isContributionUpToDate && (
          <BannerMessageContainer item sm={12}>
            <BannerMessage>
              {translate('pageUserProfile.membership.noMemberCard')}
            </BannerMessage>
          </BannerMessageContainer>
        )}

        {hasAccessRights && !userConnected.isContributionUpToDate && tariff !== undefined && (
          <Grid container direction="column" spacing={2}>
            <Grid item sm={6} xs={12}>
              <Card>
                <CustomCardTitle
                  icon={faClipboardUser}
                  title="pageUserProfile.membership.renew"
                />
                <CardContent>
                  <InfoLine
                    info={tariff.libelle}
                    label="pageUserProfile.membership.tariff"
                  />
                  <InfoLine
                    info={`${tariff.montant} €`}
                    label="pageUserProfile.membership.amount"
                  />
                  <Grid alignItems="center" container direction="column" spacing={2}>
                    <Grid item>
                      <PayboxPayment
                        buttonLabel="button.settleCard"
                        getPaymentInformations={createMembership}
                        typeId={PAYMENT_TYPE.READHESION.code}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Grid>
    </TabContainer>
  );
});

export default Person;