/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  sectionTitle: {
    fontWeight: 'bold',
    color: 'var(--black)',
    margin: '2rem 0',
    padding: ' 0.5rem 0',
    paddingLeft: '10px',
    borderLeft: '8px solid var(--tertiary-color)'
  }
}));

const SectionTitle = ({ label, icon }) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" className={classes.sectionTitle} container spacing={1}>
      <Grid item>
        <FontAwesomeIcon icon={icon} />
      </Grid>
      <Grid item xs={11}>
        {label}
      </Grid>
    </Grid>
  );
};

export default SectionTitle;