/* eslint-disable indent */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { formatISO, endOfDay } from 'date-fns';
import { isMisterflyTicket, translate } from 'utils';
import {
  DoubleBooking, ExternOption, Ticketing, InternOption
} from 'components/AllowanceLeisureBooking/Module';
import {
  ALLOWANCE_LEISURE_NATURE, ALLOWANCE_LEISURE_TARIFF_TYPE, DAY_TIMES, PATHS, ROUTES
} from 'utils/constants';
import styled from 'styled-components';
import { useStores, useArray, useAccessRights } from 'hooks';
import { useFormState } from 'react-use-form-state';
import { Button, Grid } from '@material-ui/core';
import { AllowanceApplicationService } from 'services';
import { toJS } from 'mobx';
import { AllowanceHelper } from 'utils/helpers';
import {
  StatusDisplay, Wrapper, SkeletonAllowanceDetail, SkeletonNoAllowance
} from 'components';
import { AllowanceLimitMessage } from 'components/_commons';
import { DateHelper } from 'utils/helpers/DateHelper';

const AllowanceLeisureBookingContainer = styled(Grid)`
  padding-left: 20px;
`;

const AllowanceLeisureBooking = observer(() => {
  const { pathname, state } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { allowanceStore } = useStores();
  const { allowance, isLoading } = allowanceStore;
  const category = pathname.split('/')[2];
  const [dateRange, setDateRange] = useState();
  const [submit, setSubmit] = useState(false);
  const [status, setStatus] = useState('ongoing');
  const hasAccessRights = useAccessRights();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTicketing, setIsTicketing] = useState(false);

  const [userAllowanceApplicationDetail, setUserAllowanceApplicationDetail] = useState(null);

  const [formState, {
    number, text, checkbox, select
  }] = useFormState({
    cardNumber: '',
    expirationDate: '',
    cardHolder: '',
    price: 0,
    ticketsNumber: 1,
    isRightHolders: false,
    halfDay: DAY_TIMES.MORNING,
    withdrawHQ: false,
    interEstablishmentAllowanceId: -1
  });

  const {
    add, value: selectedRightHolders, setValue: setSelectedRightHolders
  } = useArray([]);
  const [limit, setLimit] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    allowanceStore.getAllowanceDetail(id)
      .catch(() => enqueueSnackbar(translate('errors.failedAllowanceSocialDetail'), { variant: 'error' }));

    // If we come from the allowanceApplication list, get the allowanceApplication detail to prefill the form
    if (state && state.allowanceApplicationId) {
      AllowanceApplicationService.getAllowanceApplicationDetail(state.allowanceApplicationId)
        .then((resp => {
          const allowanceApplicationDetail = { ...resp };
          allowanceApplicationDetail.endDate = DateHelper.removeUTC(resp.endDate);
          allowanceApplicationDetail.startDate = DateHelper.removeUTC(resp.startDate);
          setUserAllowanceApplicationDetail(allowanceApplicationDetail);
        }))
        .catch(() => enqueueSnackbar(translate('errors.failedAllowanceApplicationDetail'), { variant: 'error' }));
    }
  }, [allowanceStore, id, enqueueSnackbar, state, history, category]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // If we come from the user allowanceApplication list, we must prefill the form fields
    if (userAllowanceApplicationDetail && allowance) {
      // Set the application status
      setStatus(AllowanceHelper.getAllowanceStatus(userAllowanceApplicationDetail.status));
      // Set the addionalData
      if (userAllowanceApplicationDetail.memberComment) {
        formState.setField('memberComment', userAllowanceApplicationDetail.memberComment);
      }
      if (userAllowanceApplicationDetail.quantity) {
        formState.setField('ticketsNumber', userAllowanceApplicationDetail.quantity);
      }
      if (userAllowanceApplicationDetail.orderAmount) {
        formState.setField('price', userAllowanceApplicationDetail.orderAmount);
      }

      if (allowance.nature?.id === ALLOWANCE_LEISURE_NATURE.INTERNAL.code) {
        formState.setField('accommodation', userAllowanceApplicationDetail.interEstablishmentAllowance?.unitCapacity);
        formState.setField('interEstablishmentAllowanceId', userAllowanceApplicationDetail.interEstablishmentAllowanceId);
        const startDate = new Date(userAllowanceApplicationDetail.startDate);
        const endDate = new Date(userAllowanceApplicationDetail.endDate);
        if (allowance.tariffType.id === ALLOWANCE_LEISURE_TARIFF_TYPE.BY_HALF_DAY.code) {
          setDateRange(startDate);
        } else {
          setDateRange([startDate, endDate]);
        }
      }

      if (userAllowanceApplicationDetail.rightHolders && userAllowanceApplicationDetail.rightHolders.length > 0) {
        formState.setField('isRightHolders', true);
        setSelectedRightHolders(userAllowanceApplicationDetail.rightHolders.map(rH => rH.id));
      }
    }
    // eslint-disable-next-line
  }, [userAllowanceApplicationDetail, allowance]);

  useEffect(() => {
    if (allowance && allowance.nature && allowance.nature.id === ALLOWANCE_LEISURE_NATURE.TICKETS.code) {
      const markupPrice = formState.values.withdrawHQ ? 0 : allowance.markupPrice;
      const tmpPrice = formState.values.ticketsNumber * allowance.ticketPrice + markupPrice;
      formState.setField('price', tmpPrice);
    }
    // eslint-disable-next-line
  }, [allowance, formState.values.withdrawHQ, formState.values.ticketsNumber]);

  // For now it's mocked because the next step doesn't work
  const canSubmit = useCallback(() => {
    switch (allowance && allowance.nature && allowance.nature.id) {
      case ALLOWANCE_LEISURE_NATURE.INTERNAL.code:
        setSubmit(formState.values.certifyData && formState.values.certifyPersonalData && !!dateRange);
        break;
      case ALLOWANCE_LEISURE_NATURE.EXTERNAL_DOUBLE.code:
        setSubmit(formState.values.certifyData && formState.values.certifyPersonalData);
        break;
      case ALLOWANCE_LEISURE_NATURE.EXTERNAL_SINGLE.code:
        setSubmit(false);
        break;
      case ALLOWANCE_LEISURE_NATURE.TICKETS.code:
        setIsTicketing(true)
        setSubmit(true);
        break;
      default:
        setSubmit(false);
        break;
    }
  }, [allowance, formState, dateRange, setIsTicketing]);

  useEffect(() => {
    canSubmit();
  }, [formState.values, canSubmit]);

  const handleChangeTicketsNumber = useCallback(event => {
    const ticNum = Number(event.target.value);
    formState.setField('ticketsNumber', ticNum);
  }, [formState]);

  const handleSubmit = useCallback(e => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    e.preventDefault();

    const allowanceForm = {
      ...toJS(allowance),
      ...formState.values,
      allowanceId: allowance.id,
      orderAmount: parseFloat(formState.values.price)
    };

    if (formState.values.isRightHolders) {
      const selectedRightHoldersId = [];
      selectedRightHolders.forEach(rH => {
        selectedRightHoldersId.push({ id: rH });
      });
      allowanceForm.rightHolders = selectedRightHoldersId;
    }

    if (dateRange) {
      if (dateRange.length > 1) {
        allowanceForm.startDate = formatISO(new Date(dateRange[0]));
        allowanceForm.endDate = formatISO(new Date(dateRange[1]));
      } else if (formState.values.halfDay === DAY_TIMES.MORNING) {
        allowanceForm.startDate = formatISO(new Date(dateRange));
        const endDate = new Date(dateRange);
        endDate.setHours(endDate.getHours() + 12);
        allowanceForm.endDate = formatISO(endDate);
      } else if (formState.values.halfDay === DAY_TIMES.EVENING) {
        const startDate = new Date(dateRange);
        startDate.setHours(startDate.getHours() + 12);
        allowanceForm.startDate = formatISO(startDate);
        const endDate = endOfDay(new Date(dateRange));
        allowanceForm.endDate = formatISO(endDate);
      } else {
        allowanceForm.startDate = formatISO(new Date(dateRange));
        const endDate = endOfDay(new Date(dateRange));
        allowanceForm.endDate = formatISO(endDate);
      }
    }

    allowanceForm.attachments = null;

    if (allowance.nature?.id !== ALLOWANCE_LEISURE_NATURE.INTERNAL.code) {
      allowanceForm.interEstablishmentAllowanceId = null;
    }

    if (formState.values.ticketsNumber) {
      allowanceForm.quantity = formState.values.ticketsNumber;
    }

    if (userAllowanceApplicationDetail) {
      AllowanceApplicationService.updateAllowanceApplication(userAllowanceApplicationDetail.id, allowanceForm).then(() => {
        setIsSubmitting(false);
        enqueueSnackbar(translate('confirms.bookingDone'), { variant: 'success' });
        history.push({
          pathname: ROUTES.ALLOWANCE_LEISURE_DETAIL_CONFIRM(category, id),
          state: { ticketing: isTicketing }
        });
      }).catch(() => enqueueSnackbar(translate('errors.failedUpdateAllowance'), { variant: 'error' }));
    } else {
      AllowanceApplicationService.createAllowanceApplication(allowanceForm).then(() => {
        setIsSubmitting(false);
        enqueueSnackbar(translate('confirms.bookingDone'), { variant: 'success' });
        history.push({
          pathname: ROUTES.ALLOWANCE_LEISURE_DETAIL_CONFIRM(category, id),
          state: { ticketing: isTicketing }
        });
      }).catch(() => enqueueSnackbar(translate('errors.failedCreateAllowance'), { variant: 'error' }));
    }
  }, [category, history, allowance, formState.values, enqueueSnackbar, isTicketing,
    selectedRightHolders, id, userAllowanceApplicationDetail, dateRange, isSubmitting]);

  if (isLoading) {
    return (
      <Wrapper>
        <SkeletonAllowanceDetail isLeisure />
      </Wrapper>
    );
  }

  if (!allowance) {
    return (
      <Wrapper>
        <SkeletonNoAllowance />
      </Wrapper>
    );
  }

  const isAllowanceReserved = allowance.activityCondition && !hasAccessRights;
  const isFormDisabled = (status !== 'ongoing') || isAllowanceReserved;

  const renderSwitchNatureID = natureId => {
    switch (natureId) {
      case ALLOWANCE_LEISURE_NATURE.INTERNAL.code:
        return (
          <InternOption
            add={add}
            allowance={allowance}
            checkbox={checkbox}
            dateRange={dateRange}
            formState={formState}
            isFormDisabled={isFormDisabled}
            number={number}
            select={select}
            selectedRightHolders={selectedRightHolders}
            setDateRange={setDateRange}
            setSelectedRightHolders={setSelectedRightHolders}
            userAllowanceApplicationDetail={userAllowanceApplicationDetail}
          />
        );
      case ALLOWANCE_LEISURE_NATURE.EXTERNAL_DOUBLE.code:
        return (
          <DoubleBooking
            add={add}
            allowance={allowance}
            checkbox={checkbox}
            formState={formState}
            isFormDisabled={isFormDisabled}
            selectedRightHolders={selectedRightHolders}
            setSelectedRightHolders={setSelectedRightHolders}
            text={text}
            userAllowanceApplicationDetail={userAllowanceApplicationDetail}
          />
        );
      case ALLOWANCE_LEISURE_NATURE.EXTERNAL_SINGLE.code:
        return (
          <ExternOption allowance={allowance} />
        );
      case ALLOWANCE_LEISURE_NATURE.TICKETS.code:
        return (
          <Ticketing
            allowance={allowance}
            disabled={!!userAllowanceApplicationDetail}
            formState={formState}
            limit={limit}
            number={number}
            text={text}
            userAllowanceApplicationDetail={userAllowanceApplicationDetail}
            onChange={handleChangeTicketsNumber}
          />
        );
      default:
        return (<></>);
    }
  };

  return (
    <AllowanceLeisureBookingContainer container direction="column" spacing={2}>
      {!userAllowanceApplicationDetail && (
        <>
          <br />
          <AllowanceLimitMessage
            allowance={allowance}
            amount={formState.values.price}
            limit={limit}
            quantity={formState.values.ticketsNumber}
            setLimit={setLimit}
          />
        </>
      )}
      <br />
      <Grid item>
        {userAllowanceApplicationDetail && (
          <StatusDisplay
            allowanceNature={allowance.nature}
            category={category}
            formState={formState}
            isDone={status === 'done'}
            userAllowanceApplicationDetail={userAllowanceApplicationDetail}
          />
        )}
      </Grid>
      <Grid item>
        {allowance.nature && renderSwitchNatureID(allowance.nature.id)}
      </Grid>
      {(!isFormDisabled && allowance.nature) && (
        <Grid item>
          <Button color="primary" disabled={!submit || isFormDisabled || limit} onClick={handleSubmit}>
            {translate('button.validate')}
          </Button>
        </Grid>
      )}
      {isMisterflyTicket({ allowanceName: allowance.name }) && (
        <Grid item>
          <Button
            color="primary"
            onClick={() => history.push(PATHS.USER_ALLOWANCE_APPLICATION)}
          >
            {translate('common.back')}
          </Button>
        </Grid>
      )}
    </AllowanceLeisureBookingContainer>
  );
});

export default AllowanceLeisureBooking;