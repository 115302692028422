import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MENU_ITEMS } from 'utils/constants';
import { Grid } from '@material-ui/core';
import { Desktop } from 'components';
import { translate } from 'utils';
import { usePrivileges } from 'hooks';

const IconContainer = styled.p`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  margin: 0 1.5rem 0 0;
  padding: 0.5rem;
  background: var(--secondary-color);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    border: 3px solid var(--tertiary-color);
    transform: rotateZ(45deg);
    z-index: -1;
    transition: all var(--transitionTime);
  }

  svg {
    color: var(--white);
  }

  @media(max-width: 768px) {
    display: none;
  }
`;

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 2rem 3rem;
  color: var(--grey-darker);
  text-transform: uppercase;

  div {
    position: relative;
  }

  &.active {
    text-decoration: none;

    div:after {
      content: '';
      position: absolute;
      bottom: -13px;
      left: 0;
      right: 0;
      width: 100%;
      height: 12px;
      background: url('/assets/images/bg/menu-border-selected.png') no-repeat center;
    }
  }

  &:hover {
    ${IconContainer} {
      &:after {
        transform: rotateZ(135deg);
      }
    }
  }

  span {
    color: var(--primary-color);
    font-family: 'ArimoBold';
    font-size: 2rem;

    @media(max-width: 768px) {
      padding: 0rem;
      font-size: 1.6rem;
    }
  }

  @media(max-width: 768px) {
    padding: 0 1rem;
    font-size: 1.3rem;
  }
`;

const HeaderMenu = () => {
  const home = MENU_ITEMS[0];
  const allowanceSocial = MENU_ITEMS[1];
  const allowanceLeisure = MENU_ITEMS[2];

  return (
    <nav>
      <Grid alignItems="center" container justify="space-between" wrap="nowrap">
        <Desktop>
          <Grid item style={{ marginRight: '2rem' }}>
            <NavLink activeClassName="active" aria-label={translate('menu.home')} to={home.url}>
              <FontAwesomeIcon icon={home.icon} size="lg" />
            </NavLink>
          </Grid>
        </Desktop>

        {usePrivileges(allowanceSocial.privileges) && (
          <Grid item>
            <StyledLink activeClassName="active" to={allowanceSocial.url}>
              <IconContainer>
                <FontAwesomeIcon icon={allowanceSocial.icon} size="lg" />
              </IconContainer>
              <Grid container direction="column">
                Prestations
                {' '}
                <span>sociales</span>
              </Grid>
            </StyledLink>
          </Grid>
        )}

        { usePrivileges(allowanceLeisure.privileges) && allowanceLeisure && (
          <Grid item>
            <StyledLink activeClassName="active" to={allowanceLeisure.url}>
              <IconContainer>
                <FontAwesomeIcon icon={allowanceLeisure.icon} size="lg" />
              </IconContainer>
              <Grid container direction="column">
                Prestations
                {' '}
                <span>loisirs</span>
              </Grid>
            </StyledLink>
          </Grid>
        )}

      </Grid>
    </nav>
  );
};

export default HeaderMenu;