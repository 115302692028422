import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getAllowanceApplicationList = () => RequestHelper.GET(API_ROUTES.USER_ALLOWANCE_APPLICATION_REWORK());

const getAllowanceApplicationDetail = id => RequestHelper.GET(API_ROUTES.USER_ALLOWANCE_APPLICATION_DETAIL(id));

const getAllowanceApplicationTypes = () => RequestHelper.GET(API_ROUTES.USER_ALLOWANCE_APPLICATION_TYPES());

const getAllowanceApplicationCeil = allowanceId => RequestHelper.GET(API_ROUTES.USER_ALLOWANCE_APPLICATION_CEIL(allowanceId));

const getAllowanceApplicationCsfcRight = () => RequestHelper.GET(API_ROUTES.USER_ALLOWANCE_APPLICATION_CSFC_RIGHT());

// POST
const createAllowanceApplication = allowance => RequestHelper.POST(API_ROUTES.USER_ALLOWANCE_APPLICATION(), allowance);

const updateAllowanceApplicationStatus = data => RequestHelper.POST(API_ROUTES.USER_ALLOWANCE_APPLICATION_UPDATE_STATUS(), data);

// PUT
const updateAllowanceApplication = (id, allowance) => (
  RequestHelper.PUT(API_ROUTES.USER_ALLOWANCE_APPLICATION_DETAIL(id), allowance)
);

// PATCH
const updateAllowanceApplicationPatch = (id, allowance) => (
  RequestHelper.PATCH(API_ROUTES.USER_ALLOWANCE_APPLICATION_DETAIL(id), allowance)
);

// DELETE
const deleteAllowanceApplication = id => (
  RequestHelper.DELETE(API_ROUTES.USER_ALLOWANCE_APPLICATION_DETAIL(id))
);

const AllowanceApplicationService = {
  getAllowanceApplicationList,
  getAllowanceApplicationDetail,
  getAllowanceApplicationTypes,
  getAllowanceApplicationCeil,
  getAllowanceApplicationCsfcRight,
  createAllowanceApplication,
  updateAllowanceApplicationStatus,
  updateAllowanceApplication,
  updateAllowanceApplicationPatch,
  deleteAllowanceApplication
};

export default AllowanceApplicationService;