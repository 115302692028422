import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Wrapper } from 'components';
import {
  Card, CardContent, Grid
} from '@material-ui/core';

const SkeletonUserProfile = () => (
  <Wrapper>
    <Skeleton height={130} style={{ margin: '1rem auto' }} variant="circle" width={130} />
    <Skeleton height={40} style={{ margin: '2rem auto' }} width={200} />
    <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
    <Skeleton height={100} style={{ marginBottom: '2rem' }} variant="rect" />
    <Skeleton height={150} variant="rect" />
  </Wrapper>
);

export const SkeletonUserMenu = () => (
  <>
    <Skeleton height={90} style={{ margin: '1rem auto' }} variant="circle" width={90} />
    <Skeleton height={50} style={{ margin: '0.5rem 0' }} variant="rect" width={300} />
    <Skeleton height={50} style={{ margin: '0.5rem 0' }} variant="rect" width={300} />
    <Skeleton height={50} style={{ margin: '0.5rem 0' }} variant="rect" width={300} />
    <Skeleton height={50} style={{ margin: '0.5rem 0' }} variant="rect" width={300} />
  </>
);

export const SkeletonUserProfileCard = () => (
  <Wrapper>
    <Grid container>
      <Grid item sm={6} xs={12}>
        <Card>
          <Skeleton height={50} variant="rect" />
          <CardContent>
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
            <Skeleton height={40} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Wrapper>
);

export default SkeletonUserProfile;