export const newsFixture = ({
  id = 1,
  title = '',
  date = new Date(),
  resume = '',
  content = '',
  image = ''
} = {}) => ({
  id,
  title,
  date,
  resume,
  content,
  image
});

export const newsListFixture = [
  newsFixture({
    id: 1,
    title: 'Adosspp infos de novembre',
    resume: "La dernière newsletter a été diffusée dans vos boîtes mails ce vendredi 6 novembre. Vous y trouverez toute l'actualité de l'association ainsi que des informations utiles.",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    image: '/assets/images/fixtures/newsletter.png'
  }),
  newsFixture({
    id: 2,
    title: "Fermeture de l'adosspp",
    resume: "Suites aux annonces gouvernementales, les empires de l'ADOSSPP sont fermées au public jusqu'à nouvel ordre.",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    image: '/assets/images/fixtures/covid.png'
  }),
  newsFixture({
    id: 3,
    title: 'Prêt à taux zéro avec le crédit mutuel',
    resume: "Nous vous proposons une nouvelle offre de prêt bancaire, en partenariat avec le Credit mutuel d'Ile de France.",
    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    image: '/assets/images/fixtures/credit.jpg'
  })
];