/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button, CircularProgress, Grid, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import { GladyService } from 'services';
import { useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';
import { useMediaQuery } from 'react-responsive';

const GladyContainer = styled.div`
  color: var(--black);
  padding: 3rem;
  background: url('/assets/images/bg/banner-glady.png') no-repeat center;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
`;

const Glady = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    GladyService.getConnectionUrl().then(() => {
      setError(false);
    }).catch(() => {
      GladyService.addBeneficiary().then(resp => {
        if (resp.data === true) {
          setError(false);
        } else {
          setError(true);
          enqueueSnackbar(translate('glady.error'), { variant: 'error' });
        }
      }).catch(() => {
        setError(true);
        enqueueSnackbar(translate('glady.error'), { variant: 'error' });
      });
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (url !== null) {
      window.open(url, '_self');
    }
  }, [url]);

  return (
    <GladyContainer>
      <Grid container direction="column" spacing={1}>
        <Grid item style={{ borderRadius: '10px', position: 'relative', left: '4px' }} xs>
          <Typography
            style={{
              fontFamily: 'Arimo',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '20px',
              textAlign: useMediaQuery({ minWidth: 565 }) ? 'right' : '',
              color: '#FFFFFF',
              letterSpacing: '1px',
              marginLeft: 'auto',
              marginRight: 0,
              width: useMediaQuery({ minWidth: 565 }) ? '450px' : 'auto'
            }}
          >
            {useMediaQuery({ minWidth: 565 }) ? translate('glady.description') : translate('glady.title')}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: useMediaQuery({ minWidth: 565 }) ? 'right' : '' }} xs>
          {(() => {
            if (!error) {
              return (
                <Button
                  style={{ color: '#1D71B8' }}
                  type="submit"
                  onClick={() => {
                    GladyService.getConnectionUrl().then(resp => {
                      setError(false);
                      setUrl(`${resp.data}`);
                    });
                  }}
                >
                  {translate('glady.button')}
                </Button>
              );
            }
            return (
              error
                ? <Alert severity="error">{translate('glady.error')}</Alert>
                : <CircularProgress color="secondary" />
            );
          })()}
        </Grid>
      </Grid>
    </GladyContainer>
  );
};

export default Glady;