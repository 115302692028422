/* eslint-disable no-param-reassign */
// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA
import { getLCP, getFID, getCLS } from 'web-vitals';

export const report = () => {
  getCLS(console.log);
  getFID(console.log);
  getLCP(console.log);
};