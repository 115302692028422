import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const getSubscriptionsPrices = () => RequestHelper.UNLOGGED_GET(API_ROUTES.USER_SUBSCRIPTIONS_PRICES);

const getNumberRevue = () => RequestHelper.UNLOGGED_GET(API_ROUTES.NUMBER_REVUE);

const createSubscriber = subscriber => RequestHelper.UNLOGGED_POST(API_ROUTES.USER_SUBSCRIBER_CREATE, subscriber);

const isSubscriberExists = subscriber => RequestHelper.UNLOGGED_POST(API_ROUTES.USER_SUBSCRIBER_EXISTS, subscriber);

const SubscriptionService = {
  getSubscriptionsPrices,
  getNumberRevue,
  createSubscriber,
  isSubscriberExists
};

export default SubscriptionService;