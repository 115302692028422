import React, { useState } from 'react';
import {
  TextField, InputAdornment, IconButton
} from '@material-ui/core';

import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Password = ({
  label, name, formState, ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      error={formState.errors[name] && formState.errors[name] !== null}
      fullWidth
      id={name}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setShowPassword(currentState => !currentState)}>
              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
            </IconButton>
          </InputAdornment>
        )
      }}
      label={label}
      name={name}
      required
      {...props}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

export default Password;