import React, { useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {
  Wrapper, Breadcrumbs, PageTitle, ConfirmRedirect
} from 'components';
import { observer } from 'mobx-react-lite';
import { PATHS, ROUTES } from 'utils/constants';
import { translate } from 'utils';
import { Grid, Typography } from '@material-ui/core';
import { useStores } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-regular-svg-icons';

const PageConfirmSocial = observer(() => {
  const { pathname } = useLocation();
  const { allowanceStore } = useStores();
  const { allowance, allowanceCategoryMenuMapper } = allowanceStore;

  const category = pathname.split('/')[2];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!allowance) {
    return (
      <Redirect to={PATHS.HOME} />
    );
  }

  return (
    <Wrapper>
      <Breadcrumbs>
        <Link to={PATHS.HOME}>
          <FontAwesomeIcon icon={faHome} />
        </Link>
        <Link to={PATHS.ALLOWANCE_SOCIAL_MAIN}>
          {translate('menu.allowanceSocial')}
        </Link>
        <Link to={ROUTES.ALLOWANCE_SOCIAL_CATEGORY(category)}>
          {allowanceCategoryMenuMapper[category]}
        </Link>
      </Breadcrumbs>

      <PageTitle noTranslate title={allowance.name} />

      <Grid alignItems="center" container direction="column" spacing={4}>
        <Grid item xs>
          <img alt={allowance.name} src="/assets/images/confirmation-social.png" />
        </Grid>

        <Grid item xs>
          <Typography align="center" className="bold" component="h2" variant="h5">
            {translate('forms.allowanceSend')}
          </Typography>
          <Typography component="legend">{translate('forms.emailConfirm')}</Typography>
        </Grid>

        <ConfirmRedirect category={0} />
      </Grid>
    </Wrapper>
  );
});

export default PageConfirmSocial;