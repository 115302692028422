import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker as MatDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { translate } from 'utils';

export const Datepicker = ({
  dataCy, label, labelData, onError, ...config
}) => (
  <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
    <MatDatePicker
      autoOk
      cancelLabel={translate('button.cancel')}
      data-cy={dataCy}
      format="dd/MM/yyyy"
      fullWidth
      id={label}
      invalidDateMessage={translate('errors.invalidDate')}
      label={translate(label, labelData)}
      maxDateMessage={translate('warnings.date.maxDate')}
      minDateMessage={translate('warnings.date.minDate')}
      onError={error => onError(error)}
      {...config}
    />
  </MuiPickersUtilsProvider>
);

Datepicker.propTypes = {
  dataCy: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  labelData: PropTypes.shape({}),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func
};

Datepicker.defaultProps = {
  dataCy: '',
  disableFuture: false,
  disablePast: false,
  error: false,
  label: 'common.date',
  labelData: null,
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01'),
  name: '',
  onChange: () => {},
  onError: () => {},
  required: false
};

export const DateTimepicker = ({
  dataCy, label, onChange, ...config
}) => (
  <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
    <KeyboardDateTimePicker
      autoOk
      cancelLabel={translate('button.cancel')}
      clearable
      clearLabel={translate('button.delete')}
      data-cy={dataCy}
      format="dd/MM/yyyy HH:mm"
      id={label}
      invalidDateMessage={translate('errors.invalidDate')}
      KeyboardButtonProps={{
        'aria-label': translate(label)
      }}
      label={translate(label)}
      maxDateMessage={translate('warnings.date.maxDate')}
      minDateMessage={translate('warnings.date.minDate')}
      onChange={date => onChange(date)}
      {...config}
    />
  </MuiPickersUtilsProvider>
);

DateTimepicker.propTypes = {
  ampm: PropTypes.bool,
  dataCy: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func
};

DateTimepicker.defaultProps = {
  ampm: false,
  dataCy: '',
  disableFuture: false,
  disablePast: false,
  error: false,
  label: 'common.date',
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01'),
  name: '',
  onChange: () => {},
  required: false
};